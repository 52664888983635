import { FontAwesome, Ionicons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import firebase from 'firebase';
import 'firebase/firestore';
import { Card } from 'react-native-paper';
import React from 'react';
import { Modal, ActivityIndicator, Alert, Animated, Dimensions, FlatList, Image, Platform, SafeAreaView, ScrollView, TouchableOpacity, View, Text } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { TextInput } from 'react-native-paper';
import { bindActionCreators } from 'redux';
import { updateProfile, updateStore } from '../actions/stores';
//import PhoneInput from 'react-native-phone-input';
//import TextTicker from 'react-native-text-ticker';
import { connect } from 'react-redux';
import { CLoading, CNumericCounter } from '../components/StyledText';
import Colors from '../constants/Colors';
import Cstyles from '../constants/Cstyles';
import Moment from 'moment';
import { getLocationAddress, sendPushNotif, getDeliveryDistance } from '../helper';
const screenWidth = Dimensions.get("window").width;
const { width: viewportWidth, height: viewportHeight } = Dimensions.get('window');

class Cart extends React.Component {

    constructor(args) {
        super(args);

        this.unsubscribe = null;
        let { width } = Dimensions.get("window");
        this.state = {

            isLoading: true,
            scrWidth: ((width * 0.90) / 2),
            width,
            currency: '',
            total: 0,
            totalPayable: 0,
            totalPayableWithDelivery: 0,
            discount: 0,
            deleting: false,
            emptyCart: false,
            applyPromo: false,
            animatedValue: new Animated.Value(0),
            Coupon: '',
            applyingCoupon: false,
            appliedCoupon: false,
            errors: {},
            customer: '',
            customerName: '',
            placingOrder: false,
            placedOrder: false,
            OrderPlaced: false,
            profileStatus: true,
            DName: '',
            DAddress: '',
            DCity: '',
            DStat: '',
            DPostal: '',
            DCountry: '',
            DPhone: '',
            gradStart: Colors.gradStart,
            gradEnd: Colors.gradEnd,
            PlacedAt: '0000',
            PymntStatus: false,
            PymntMthd: 'Cash',
            PaymentRef: null,
            transactionRef: '',
            modalVisible: false,
            deliverySlot: '',
            deliverySlotList: []
        };
    }

    _addToCart(item, variant, discountPrice, sellingPrice, currQty, qty, index) {

        let that = this;
        this.setState({ addingToCart: true, addedToCart: false });
        var batch = firebase.firestore().batch();
        let itemsList = this.state.itemsList
        var addCart = null;
        var addCart2 = null;
        var updStock = null;


        if (currQty == 1 && qty < 0) {
            console.log('delete')
            this._removeItem(item, variant, discountPrice, sellingPrice, currQty)
        } else {
            if (currQty > 0) {
                console.log(index)
                // .collection(this.state.ItemId).doc(this.state.variantIndex)
                //firebase.firestore().collection('mystore').doc(that.state.currentUser.uid).collection('cart').doc(that.state.ItemId).doc(that.state.variantIndex).update({qty:firebase.firestore.FieldValue.increment(1)})
                //.then(()=>{that.setState({addingToCart:false, addedToCart:true})});
                addCart = firebase.firestore().collection('buyer').doc(this.props.profile_data.profile_data.uid).collection('stores').doc(this.state.storeData.id).collection('cart').doc(item + '/variants/' + variant.toString());
                batch.update(addCart, { qty: firebase.firestore.FieldValue.increment(qty) });
                itemsList[index].Qty = itemsList[index].Qty + (qty)
            }
            else {
                //firebase.firestore().collection('mystore').doc(that.state.currentUser.uid).collection('cart').doc(that.state.ItemId).doc(that.state.variantIndex).set({qty:1})
                //.then(()=>{that.setState({addingToCart:false, addedToCart:true})});
                addCart = firebase.firestore().collection('buyer').doc(this.props.profile_data.profile_data.uid).collection('stores').doc(this.state.storeData.id).collection('cart').doc(item + '/variants/' + variant.toString());
                batch.set(addCart, { qty: qty });
                itemsList[index].Qty = itemsList[index].Qty + (qty)
            }
            if (variant == 0) {
                updStock = firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').doc(item);
            } else {
                updStock = firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').doc(item).collection("variants").doc(variant.toString());
            }

            batch.update(updStock, { Stock: firebase.firestore.FieldValue.increment(-1 * qty), StockInCart: firebase.firestore.FieldValue.increment(qty) });
            batch.commit().then(function () {
                that.setState({ addingToCart: false, addedToCart: true, Stock: that.state.Stock - (1 * qty), origStock: that.state.Stock - (1 * qty) });
                let price = discountPrice > 0 ? discountPrice : sellingPrice;
                let total = that.state.total + (price * qty)

                that.setState({ itemsList, total, totalPayable: total, Coupon: '', discount: 0 }, () => that._calculateDeliveryCharge());
            });
        }

    }

    _removeItem(item, variant, discountPrice, sellingPrice, qty) {
        if (!this.state.deleting) {


            this.setState({ deleting: true });
            let filterList = this.state.itemsList;
            var batch = firebase.firestore().batch();
            var delCartVariant = null;
            let delCart = null;
            filterList = filterList.filter(data => (data.id == item));

            delCartVariant = firebase.firestore().collection('buyer').doc(this.props.profile_data.profile_data.uid).collection('stores').doc(this.state.storeData.id).collection('cart').doc(item).collection('variants').doc(variant);


            var updStock = null;
            if (variant != 0) {
                updStock = firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').doc(item).collection("variants").doc(variant);
            } else {
                updStock = firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').doc(item);
            }
            batch.update(updStock, { Stock: firebase.firestore.FieldValue.increment(qty), StockInCart: firebase.firestore.FieldValue.increment((qty * -1)) });
            batch.delete(delCartVariant);
            if (filterList.length == 1) {
                delCart = firebase.firestore().collection('buyer').doc(this.props.profile_data.profile_data.uid).collection('stores').doc(this.state.storeData.id).collection('cart').doc(item);
                batch.delete(delCart);
            }
            batch.commit().then(() => {
                this.setState({ deleting: false });
                let tempList = this.state.itemsList;
                tempList = tempList.filter(data => !(data.id == item && data.variant == variant));

                let price = discountPrice > 0 ? discountPrice : sellingPrice;
                if (tempList.length == 0) {
                    this.setState({ emptyCart: true });
                    let profile_data = this.props.profile_data.profile_data
                    profile_data.cartCount = 0
                    this.props.updateProfile(profile_data)
                }
                this.setState({ total: this.state.total - (price * qty) });
                this.setState({ itemsList: tempList, totalPayable: this.state.total, Coupon: '', discount: 0 }, () => this._calculateDeliveryCharge());

            });

        }

    }



    state = {
        currentUser: null,
        image: null,
        name: "Update Profile",
        itemsList: [1, 2],
        scrWidth: 100,
        animatedValue: new Animated.Value(0),
    };
    animationValue = this.state.animatedValue.interpolate(
        {
            inputRange: [0, 1],
            outputRange: [-59, 0]
        });

    _close() {
        this.props.navigation.goBack(null);
    }


    async componentDidMount() {
        console.log('Cart');
        this.props.navigation.setParams({ close: this._close.bind(this) });
        let StoreData = this.props.store.store;
        this._buildDeliverySolts()
        let profile_data = this.props.profile_data.profile_data;
        console.log(profile_data)
        this.props.navigation.setOptions({
            headerStyle: {
                backgroundColor: StoreData.gradStart, //Set Header color
            },
            headerTintColor: 'white'
        })
        this.setState({
            storeData: StoreData, gradStart: StoreData.gradStart ? StoreData.gradStart : this.state.gradStart, gradEnd: StoreData.gradEnd ? StoreData.gradEnd : this.state.gradEnd, deliveryNote: StoreData.deliveryNote,
            deliveryCharges: StoreData.deliveryCharges,
            deliveryChargeAmount: StoreData.deliveryChargeAmount,
            deliveryChargeType: StoreData.deliveryChargeType,
            maxDeliveryCharge: StoreData.maxDeliveryCharge,
            exemptDeliveryCharge: StoreData.exemptDeliveryCharge,
            freeDeliveryAmount: StoreData.freeDeliveryAmount
        });
        if (StoreData.gradStart) {
            this.props.navigation.setParams({
                gradStart: StoreData.gradStart
            });
        }
        if (StoreData.gradEnd) {
            this.props.navigation.setParams({
                gradEnd: StoreData.gradEnd
            });

        }
        this.didFocusListener = this.props.navigation.addListener(
            'focus',
            () => {
                this.setState({
                    isLoading: true,
                    OrderPlaced: false
                });


                this.fetchData(StoreData.id);
            },
        );
        //this.fetchData(StoreData.id);
        if (profile_data && (profile_data.loginMail || profile_data.loginPhone)) {
            //Authenticated and profile update
            this.setState({ profileStatus: true })
            this.DNameRef = this.updateRef.bind(this, 'DName');
            this.DAddressRef = this.updateRef.bind(this, 'DAddress');
            this.DCityRef = this.updateRef.bind(this, 'DCity');
            this.DStatRef = this.updateRef.bind(this, 'DStat');
            this.DCountryRef = this.updateRef.bind(this, 'DCountry');
            this.DPostalRef = this.updateRef.bind(this, 'DPostal');
            this.DPhoneRef = this.updateRef.bind(this, 'DPhone');
            this.CouponRef = this.updateRef.bind(this, 'Coupon');
            this.onFocus = this.onFocus.bind(this);

            if (StoreData.location && StoreData.restrictOrders) {
                if (StoreData.currentLocation) {
                    let result = await getLocationAddress(StoreData.currentLocation)
                    console.log('location address found')
                    this.setState({
                        DCity: result[0].city ? result[0].city : '',
                        DName: profile_data.name,
                        DStat: result[0].region ? result[0].region : '',
                        DAddress: result[0].street ? result[0].street : '',
                        DCountry: result[0].isoCountryCode ? result[0].isoCountryCode : '',
                        DPostal: result[0].postalCode ? result[0].postalCode : '',
                        locationAddress: (result[0].street ? result[0].street : '') + '\n' + (result[0].city ? result[0].city : '') + (result[0].postalCode ? '-' + result[0].postalCode : null),
                        //DPhone:this.state.profile_data.loginPhone?this.state.profile_data.loginPhone:this.state.profile_data.phone

                    })


                } else {
                    alert(
                        'Location Required. You cannot place orders on this Store as they require your location to fulfil the order. Go to permissions and enable location access to shop with the Store')

                }

            }
            this.setState({
                page: null,
                name: profile_data.name,
                profile_data,
                currency: profile_data.Currency,
            });

        } else {
            this.setState({
                isLoading: false,
                profileStatus: false
            });
        }


    }


    fetchData(id) {
        console.log('fetch')
        this.setState({ isLoading: true })
        let profile_data = this.props.profile_data.profile_data
        let profileStatus = false
        if (profile_data && (profile_data.loginMail || profile_data.loginPhone)) {
            profileStatus = true;
        }
        if (profileStatus) {
            this.setState({ profileStatus: true, profile_data })
            this.items = firebase.firestore().collection('buyer').doc(profile_data.uid).collection('stores').doc(id).collection('cart');
            this.items.get().then((querySnapshot) => this.onCollectionUpdate(querySnapshot))
            //.catch((e)=>console.error('Test Error'));


        } else {
            this.setState({ isLoading: false })
        }

    }

    _buildDeliverySolts() {
        if (this.props.store.store.deliverySlot) {
            let deliverySlotListTemp = this.props.store.store.deliverySlotList.filter(data => (data.status == true))
            let deliverySlotList = []
            deliverySlotListTemp.forEach(slot => {
                if (slot.day == 0 && slot.startTime <= parseInt(Moment().format('H'))) {
                    //Same Day and hour has passed
                } else {
                    deliverySlotList.push(Moment().add('days', slot.day).format("DD-MMM-YYYY").toString() + ' | ' + slot.startTime + ':00 Hrs - ' + slot.endTime + ':00 Hrs')
                }

            });
            this.setState({ deliverySlotList, modalVisible: true })
        }


    }



    onCollectionUpdate = (async (querySnapshot) => {
        var itemsList = [];

        var promises = [];
        var Finalpromises = [];
        let total = 0, totalPayable = 0;
        this.setState({ total, totalPayable, emptyCart: false });
        let profile_data = this.props.profile_data.profile_data
        if (querySnapshot.docs.length > 0) {
            profile_data.cartCount = querySnapshot.docs.length

        } else {
            profile_data.cartCount = 0
        }
        this.props.updateProfile(profile_data)

        Promise.all(querySnapshot.docs.map(async doc => {

            promises.push(firebase.firestore().collection('buyer').doc(this.props.profile_data.profile_data.uid).collection('stores').doc(this.state.storeData.id).collection('cart').doc(doc.id).collection('variants').get()
                .then((cartVarDoc) => {
                    cartVarDoc.docs.map((cartVar) => {
                        if (cartVar.id != 0) {
                            Finalpromises.push(firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').doc(doc.id).collection('variants').doc(cartVar.id).get()
                                .then((variantDetailsDoc) => {
                                    itemsList.push({
                                        id: doc.id,
                                        ItemName: doc.data().Name,
                                        variant: cartVar.id,
                                        Qty: cartVar.data().qty,
                                        Color: variantDetailsDoc.data().Color,
                                        Size: variantDetailsDoc.data().Size,
                                        Packing: variantDetailsDoc.data().Packing,
                                        Material: variantDetailsDoc.data().Material,
                                        Other: variantDetailsDoc.data().Other,
                                        Image: variantDetailsDoc.data().Image,
                                        SellingPrice: variantDetailsDoc.data().SellingPrice,
                                        DiscountPrice: variantDetailsDoc.data().DiscountPrice ? variantDetailsDoc.data().DiscountPrice : 0,
                                        MRP: variantDetailsDoc.data().MRP,
                                    });

                                    let price = variantDetailsDoc.data().DiscountPrice > 0 ? variantDetailsDoc.data().DiscountPrice : variantDetailsDoc.data().SellingPrice;
                                    total += (price * cartVar.data().qty);
                                    totalPayable = totalPayable + (price * cartVar.data().qty)
                                    console.log('totalpayable ' + totalPayable)
                                    //this.setState({ total: this.state.total + (price * cartVar.data().qty), totalPayable: this.state.total + (price * cartVar.data().qty) }, () => this._calculateDeliveryCharge());

                                })

                            );
                        } else {
                            Finalpromises.push(firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').doc(doc.id).get()
                                .then((itemDoc) => {
                                    itemsList.push({
                                        id: doc.id,
                                        ItemName: doc.data().Name,
                                        variant: cartVar.id,
                                        Qty: cartVar.data().qty,
                                        Color: false,
                                        Size: false,
                                        Packing: false,
                                        Material: false,
                                        Other: false,
                                        Image: itemDoc.data().ItemImageURL,
                                        SellingPrice: itemDoc.data().SellingPrice ? itemDoc.data().SellingPrice : itemDoc.data().MRP,
                                        DiscountPrice: itemDoc.data().DiscountPrice ? itemDoc.data().DiscountPrice : 0,
                                        MRP: itemDoc.data().MRP,
                                    });
                                    let price = itemDoc.data().DiscountPrice > 0 ? (itemDoc.data().DiscountPrice) : (itemDoc.data().SellingPrice ? (itemDoc.data().SellingPrice) : (itemDoc.data().MRP));
                                    total += (price * cartVar.data().qty);
                                    totalPayable = totalPayable + (price * cartVar.data().qty)
                                    console.log('totalpayable ' + totalPayable)
                                    //this.setState({ total: this.state.total + (price * cartVar.data().qty), totalPayable: this.state.total + (price * cartVar.data().qty) }, () => this._calculateDeliveryCharge());

                                })

                            );
                        }

                    });

                })
            );


        })).then((results) => {


            Promise.all(promises).then(() => {
                Promise.all(Finalpromises).then(() => {
                    if (itemsList.length == 0) {
                        this.setState({ emptyCart: true, total: 0, totalPayable: 0, totalPayableWithDelivery: 0 });
                    }
                    this.setState({
                        total,
                        totalPayable,
                        itemsList,
                        isLoading: false,
                        Coupon: '',
                        discount: 0
                    }, () => this._calculateDeliveryCharge());

                });
            });
        });
    });


    render() {
        let { Coupon } = this.state;


        if (this.state.isLoading) {

            return (<CLoading gradStart={this.state.gradStart} gradEnd={this.state.gradEnd}></CLoading>);
        }
        else {
            if (!this.state.profileStatus) {

                return (<View style={{ flex: 1, backgroundColor: '#ffffff', justifyContent: 'center' }}>


                    <LinearGradient
                        colors={[this.state.gradStart, this.state.gradEnd]}
                        style={{ flex: 1 }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                        style={styles.buttonContainer3}
                    >
                        <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }} onPress={() => { this.props.navigation.navigate('Profile') }}>
                            <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Update Profile to continue</Text>

                        </TouchableOpacity>
                    </LinearGradient>

                </View>);


            } else {
                switch (this.state.page) {

                    case "Confirm Order":
                        errors = this.state.errors;
                        return (
                            <ScrollView style={{ flex: 1 }}>


                                <Card style={{ marginLeft: 5, marginRight: 5, padding: 10 }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <View>
                                            <Text style={{ fontWeight: 'bold', color: 'black', fontSize: 16 }}>{this.state.storeData.Currency + this.state.totalPayableWithDelivery.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Text>
                                            <TouchableOpacity style={{ padding: 5 }} onPress={() => this._applyPromoToggle()}>
                                                <Text style={{ color: this.state.gradEnd }}>{this.state.applyPromo ? 'Hide Details' : 'Show Details/ Apply Coupon'}</Text>
                                            </TouchableOpacity>

                                        </View>

                                    </View>

                                    {this.state.applyPromo ?
                                        <View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <View style={{ width: 200 }}>
                                                    <TextInput
                                                        mode='outlined'
                                                        label='Promo Coupon'
                                                        ref={this.CouponRef}
                                                        value={Coupon}
                                                        error={errors.Coupon}
                                                        onFocus={() => this.onFocus()}
                                                        style={{ width: 200 }}
                                                        autoCapitalize='characters'
                                                        tintColor={this.state.gradStart}
                                                        onChangeText={(text) => this.updateTextInput(text, 'Coupon')}
                                                    />
                                                </View>
                                                {this.state.applyingCoupon ? <ActivityIndicator size='small' color={this.state.gradStart} style={{ marginLeft: 10, marginTop: 20 }} /> : null}
                                                {this.state.applyingCoupon ? null :
                                                    <TouchableOpacity style={{ padding: 5 }} onPress={() => this._applyCoupon()}><FontAwesome name="check-circle" size={30} color={'green'} style={{ marginLeft: 10, marginTop: 20 }} /></TouchableOpacity>
                                                }
                                            </View>
                                            <View style={{ marginTop: 10 }}>
                                                <Text style={{ fontWeight: 'bold', color: 'grey' }}>Price Details</Text>
                                                <View style={{ borderBottomColor: 'grey', borderBottomWidth: 1 }} />
                                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                                                    <Text>Price ({this.state.itemsList.length} items)</Text>
                                                    <Text>{this.state.storeData.Currency + (this.state.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                                </View>
                                                {this.state.discount > 0 ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                                    <Text>Discount</Text>
                                                    <Text>{this.state.storeData.Currency + this.state.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                                </View>
                                                    : null}
                                                {this.state.deliveryCharge > 0 ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                                    <Text>Delivery Charge</Text>
                                                    <Text>{this.state.storeData.Currency + this.state.deliveryCharge.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                                </View>
                                                    : null}
                                                {this.state.deliveryCharge > 0 && this.state.exemptDeliveryCharge && (parseFloat(this.state.totalPayable) < parseFloat(this.state.freeDeliveryAmount)) ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                                    <Text style={{ color: this.state.gradStart }}>{"Shop for " + this.state.storeData.Currency + (parseFloat(this.state.freeDeliveryAmount) - parseFloat(this.state.totalPayable)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " more to get a free delivery"}</Text>
                                                </View>
                                                    : null}

                                            </View>
                                        </View>
                                        : null}

                                </Card>
                                {this.props.store.store.deliverySlot ?
                                    <TouchableOpacity onPress={() => this.setState({ modalVisible: true })} style={{ marginTop: 10, marginBottom: 10, paddingVertical: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <FontAwesome name={'clock-o'} color={this.state.gradStart} size={20} />
                                        <Text style={{ marginHorizontal: 10 }}>{this.state.deliverySlot ? this.state.deliverySlot : (this.state.storeData.pickupOnly ? 'Select a PickUp Slot' : 'Select a Delivery Slot')}</Text>
                                        <FontAwesome name={'chevron-down'} color={'grey'} size={20} />
                                    </TouchableOpacity>
                                    : null
                                }

                                {this.state.modalVisible ?
                                    <Modal animationType="slide"
                                        style={{ position: 'absolute', zIndex: 100, width: viewportWidth, height: viewportHeight, borderWidth: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', alignItems: 'center', justifyContent: 'center' }}
                                        transparent
                                        visible={this.state.modalVisible}>
                                        <View style={{ flex: 1, justifyContent: 'center' }}>
                                            <Card elevation={10} style={{ justifyContent: 'center', alignItems: 'center', marginHorizontal: 20, backgroundColor: 'white' }}>
                                                <Text style={{ textAlign: 'center', marginVertical: 5, color: this.state.gradStart }}>Select a slot from the below</Text>
                                                <View style={{ width: viewportWidth - 60, height: viewportHeight * 0.5 }}>
                                                    <FlatList

                                                        data={this.state.deliverySlotList}
                                                        keyExtractor={(item, index) => index.toString()}
                                                        renderItem={({ item, index }) => (
                                                            <Card style={{ marginBottom: 5, padding: 5, marginHorizontal: 10 }}>
                                                                <TouchableOpacity style={{ flex: 1 }} onPress={() => {
                                                                    console.log('Modal dismiss')
                                                                    this.setState({ deliverySlot: item, modalVisible: false })
                                                                }}>

                                                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                                        <Text style={{ marginleft: 10 }}>{item}</Text>
                                                                    </View>
                                                                </TouchableOpacity>
                                                            </Card>

                                                        )}>

                                                    </FlatList>

                                                </View>

                                            </Card>
                                        </View>
                                    </Modal>

                                    :
                                    null}

                                <View style={{ flex: 1, alignItems: 'center' }}>
                                    <View style={{ borderColor: 'orange', backgroundColor: '#ffffff', borderWidth: 2, borderRadius: 10, padding: 20, width: screenWidth * 0.8, maxWidth:400}}>
                                        <Text style={{ fontSize: 25, marginBottom: 10 }}>Confirm your Order</Text>
                                        <View>
                                            {this.state.storeData.pickupOnly ?
                                                <View style={{ marginBottom: 20 }}>
                                                    <Text style={{ color: this.state.gradStart }}>This Store does not deliver currently.</Text>
                                                    <Text style={{ color: this.state.gradStart }}>You need to pick up from the Store</Text>
                                                </View>
                                                :
                                                <View>
                                                    <Text style={{ fontSize: 16, fontWeight: 'bold', color: this.state.gradStart }}>Deliver to</Text>

                                                    <Text>{this.state.profile_data.name}</Text>
                                                    {this.state.storeData.restrictOrders ?
                                                        <View>
                                                            <Text>{this.state.locationAddress}</Text>
                                                            <Text>{this.state.profile_data.loginPhone ? 'Ph:' + this.state.profile_data.loginPhone : this.state.profile_data.phone ? 'Ph:' + '+' + this.state.profile_data.countrycode + this.state.profile_data.phone : ''}</Text>
                                                            <Text style={{ color: this.state.gradStart }}>Your current location will be shared</Text>
                                                            <Text style={{ color: this.state.gradStart }}> with the Store for delivery</Text>
                                                        </View> :
                                                        <View>
                                                            <Text>{this.state.profile_data.address}</Text>
                                                            <Text>{(this.state.profile_data.city) + (this.state.profile_data.stat ? ', ' + this.state.profile_data.stat : '')}</Text>
                                                            <Text>{this.state.profile_data.postal + ' ' + this.state.profile_data.country}</Text>
                                                            <Text>{this.state.profile_data.loginPhone ? 'Ph:' + this.state.profile_data.loginPhone : this.state.profile_data.phone ? 'Ph:' + '+' + this.state.profile_data.countrycode + this.state.profile_data.phone : ''}</Text>

                                                        </View>}

                                                    <TouchableOpacity style={{ marginTop: 20, marginBottom: 20, alignSelf: 'stretch' }} onPress={() => this.setState({ page: 'UpdateAddress', DPhone: this.state.profile_data.loginPhone })}>
                                                        <Text style={{ fontSize: 16, color: 'orange', textAlign: 'center', textDecorationLine: 'underline' }}>{this.state.storeData.restrictOrders ? 'Add additional address information' : 'Use a different address'}</Text>
                                                    </TouchableOpacity>
                                                </View>
                                            }


                                        </View>


                                        {this._renderPay()
                                        }

                                    </View>
                                </View>
                            </ScrollView>
                        );
                    case "UpdateAddress":
                        let { DAddress, DCity, DStat, DPhone, DCountry, DPostal, DName, errors } = this.state;
                        return (
                            <KeyboardAwareScrollView style={{ flex: 1 }}>
                                <SafeAreaView style={{ flex: 1 }}>


                                    <Card style={{ marginLeft: 5, marginRight: 5, padding: 10 }}>
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <View>
                                                <Text style={{ fontWeight: 'bold', color: 'black', fontSize: 16 }}>{this.state.storeData.Currency + this.state.totalPayableWithDelivery.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Text>
                                                <TouchableOpacity style={{ padding: 5 }} onPress={() => this._applyPromoToggle()}>
                                                    <Text style={{ color: this.state.gradEnd }}>{this.state.applyPromo ? 'Hide Details' : 'Show Details/ Apply Coupon'}</Text>
                                                </TouchableOpacity>

                                            </View>

                                        </View>

                                        {this.state.applyPromo ?
                                            <View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <View style={{ width: 200 }}>
                                                        <TextInput
                                                            mode='outlined'
                                                            label='Coupon'
                                                            ref={this.CouponRef}
                                                            value={Coupon}
                                                            error={errors.Coupon}
                                                            onFocus={() => this.onFocus()}
                                                            style={{ width: 200 }}
                                                            autoCapitalize='characters'
                                                            tintColor={this.state.gradStart}
                                                            onChangeText={(text) => this.updateTextInput(text, 'Coupon')}
                                                        />
                                                    </View>
                                                    {this.state.applyingCoupon ? <ActivityIndicator size='small' color={this.state.gradStart} style={{ marginLeft: 10, marginTop: 20 }} /> : null}
                                                    {this.state.applyingCoupon ? null :
                                                        <TouchableOpacity style={{ padding: 5 }} onPress={() => this._applyCoupon()}><Ionicons name="ios-checkmark" size={40} color={'green'} style={{ marginLeft: 10, marginTop: 20 }} /></TouchableOpacity>
                                                    }
                                                </View>
                                                <View style={{ marginTop: 10 }}>
                                                    <Text style={{ fontWeight: 'bold', color: 'grey' }}>Price Details</Text>
                                                    <View style={{ borderBottomColor: 'grey', borderBottomWidth: 1 }} />
                                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                                                        <Text>Price ({this.state.itemsList.length} items)</Text>
                                                        <Text>{this.state.storeData.Currency + (this.state.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                                    </View>
                                                    {this.state.discount > 0 ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                                        <Text>Discount</Text>
                                                        <Text>{this.state.storeData.Currency + this.state.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                                    </View>
                                                        : null}
                                                    {this.state.deliveryCharge > 0 ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                                        <Text>Delivery Charge</Text>
                                                        <Text>{this.state.storeData.Currency + this.state.deliveryCharge.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                                    </View>
                                                        : null}
                                                    {this.state.deliveryCharge > 0 && this.state.exemptDeliveryCharge && (parseFloat(this.state.totalPayable) < parseFloat(this.state.freeDeliveryAmount)) ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                                        <Text style={{ color: this.state.gradStart }}>{"Shop for " + this.state.storeData.Currency + (parseFloat(this.state.freeDeliveryAmount) - parseFloat(this.state.totalPayable)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " more to get a free delivery"}</Text>
                                                    </View>
                                                        : null}

                                                </View>
                                            </View>
                                            : null}

                                    </Card>
                                    <TouchableOpacity onPress={() => this.setState({ modalVisible: true })} style={{ marginTop: 10, marginBottom: 10, paddingVertical: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                        <FontAwesome name={'clock-o'} color={this.state.gradStart} size={20} />
                                        <Text style={{ marginHorizontal: 10 }}>{this.state.deliverySlot ? this.state.deliverySlot : (this.state.storeData.pickupOnly ? 'Select a PickUp Slot' : 'Select a Delivery Slot')}</Text>
                                        <FontAwesome name={'chevron-down'} color={'grey'} size={20} />
                                    </TouchableOpacity>
                                    {this.state.modalVisible ?
                                        <Modal animationType="slide"
                                            style={{ position: 'absolute', zIndex: 100, width: viewportWidth, height: viewportHeight, borderWidth: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', alignItems: 'center', justifyContent: 'center' }}
                                            transparent
                                            visible={this.state.modalVisible}>
                                            <View style={{ flex: 1, justifyContent: 'center' }}>
                                                <Card elevation={10} style={{ justifyContent: 'center', alignItems: 'center', marginHorizontal: 20, backgroundColor: 'white' }}>
                                                    <Text style={{ textAlign: 'center', marginVertical: 5, color: this.state.gradStart }}>Select a slot from the below</Text>
                                                    <View style={{ width: viewportWidth - 60, height: viewportHeight * 0.5 }}>
                                                        <FlatList

                                                            data={this.state.deliverySlotList}
                                                            keyExtractor={(item, index) => index.toString()}
                                                            renderItem={({ item, index }) => (
                                                                <Card style={{ marginBottom: 5, padding: 5, marginHorizontal: 10 }}>
                                                                    <TouchableOpacity style={{ flex: 1 }} onPress={() => {
                                                                        console.log('Modal dismiss')
                                                                        this.setState({ deliverySlot: item, modalVisible: false })
                                                                    }}>

                                                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                                            <Text style={{ marginleft: 10 }}>{item}</Text>
                                                                        </View>
                                                                    </TouchableOpacity>
                                                                </Card>

                                                            )}>

                                                        </FlatList>

                                                    </View>

                                                </Card>
                                            </View>
                                        </Modal>

                                        :
                                        null}

                                    <View style={{ flex: 1, alignItems: 'center' }}>
                                        <View style={{ borderColor: 'orange', backgroundColor: '#ffffff', borderWidth: 2, borderRadius: 10, padding: 20 }}>
                                            <Text style={{ fontSize: 25, marginBottom: 10 }}>Confirm your Order</Text>
                                            <View>

                                                <Text style={{ fontSize: 16, fontWeight: 'bold', color: this.state.gradStart }}>Deliver to</Text>

                                                <View style={{ width: this.state.width * 0.75 }}>
                                                    <TextInput
                                                        mode='outlined'
                                                        label='Name'
                                                        ref={this.DNameRef}
                                                        value={DName}
                                                        error={errors.DName}
                                                        onFocus={this.onFocus}
                                                        onChangeText={(text) => this.updateTextInput(text, 'DName')}
                                                    />
                                                </View>
                                                <View style={{ width: this.state.width * 0.75 }}>
                                                    <TextInput
                                                        mode='outlined'
                                                        label='Address'
                                                        ref={this.DAddressRef}
                                                        value={DAddress}
                                                        error={errors.DAddress}
                                                        onFocus={this.onFocus}
                                                        onChangeText={(text) => this.updateTextInput(text, 'DAddress')}
                                                    />
                                                </View>
                                                <View style={{ width: this.state.width * 0.75, alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <View style={{ width: this.state.width * 0.35 }}>
                                                        <TextInput
                                                            mode='outlined'
                                                            label='City'
                                                            ref={this.DCityRef}
                                                            disabled={this.state.storeData.restrictOrders && this.state.DCity}
                                                            value={DCity}
                                                            error={errors.DCity}
                                                            onFocus={this.onFocus}
                                                            onChangeText={(text) => this.updateTextInput(text, 'DCity')}
                                                        />
                                                    </View>
                                                    <View style={{ width: this.state.width * 0.35 }}>
                                                        <TextInput
                                                            mode='outlined'
                                                            label='State'
                                                            ref={this.DStatRef}
                                                            disabled={this.state.storeData.restrictOrders && this.state.DStat}
                                                            value={DStat}
                                                            error={errors.DStat}
                                                            onFocus={this.onFocus}
                                                            onChangeText={(text) => this.updateTextInput(text, 'DStat')}
                                                        />
                                                    </View>
                                                </View>
                                                <View style={{ width: this.state.width * 0.75, alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <View style={{ width: this.state.width * 0.35 }}>
                                                        <TextInput
                                                            mode='outlined'
                                                            label='Postal'
                                                            ref={this.DPostalRef}
                                                            disabled={this.state.storeData.restrictOrders && this.state.DPostal}
                                                            value={DPostal}
                                                            error={errors.DPostal}
                                                            onFocus={this.onFocus}
                                                            onChangeText={(text) => this.updateTextInput(text, 'DPostal')}
                                                        />
                                                    </View>
                                                    <View style={{ width: this.state.width * 0.35 }}>
                                                        <TextInput
                                                            mode='outlined'
                                                            label='Country'
                                                            ref={this.DCountryRef}
                                                            disabled={this.state.storeData.restrictOrders && this.state.DCountry}
                                                            value={DCountry}
                                                            error={errors.DCountry}
                                                            onFocus={this.onFocus}
                                                            onChangeText={(text) => this.updateTextInput(text, 'DCountry')}
                                                        />
                                                    </View>
                                                </View>
                                                <TextInput style={[styles.inputs, { marginTop: 10, marginBottom: 20 }]}
                                                    textStyle={{ color: this.state.gradStart, fontSize: 18 }}
                                                    textProps={{ placeholder: "Phone Number" }}
                                                    onChangePhoneNumber={(text) => this.updateTextInput(text, 'DPhone')}
                                                    value={DPhone}
                                                    disabled={this.state.disabled}
                                                    initialCountry={this.state.profile_data.cca ? this.state.profile_data.cca : 'in'}
                                                    ref={(ref) => { this.DPhoneRef = ref; }} />


                                            </View>
                                            {this._renderPay()}
                                        </View>
                                    </View>

                                </SafeAreaView>
                            </KeyboardAwareScrollView>

                        );
                    default:
                        errors = this.state.errors;
                        return (
                            <KeyboardAwareScrollView contentContainerStyle={styles.container} >
                                <View style={{ flex: 1, backgroundColor: '#ffffff' }}>

                                    {this.state.emptyCart ?
                                        this.state.OrderPlaced ?
                                            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <Image source={require('../assets/images/completed.gif')} style={{ width: 150, height: 150, alignSelf: "center", marginTop: 30 }} />
                                                <Text style={{ color: this.state.gradEnd, fontSize: 30, marginLeft: 10, marginRight: 10, textAlign: 'center' }}>Order Placed!</Text>
                                                <Text>You can track your order from the Orders tab below</Text>

                                            </View>
                                            : <View>
                                                <Image source={require('../assets/images/emptycart.gif')} style={{ alignSelf: "center", marginTop: 100 }} />
                                                <Text style={{ textAlign: 'center', color: this.state.gradStart, margin: 20, fontSize: 20 }}>Your cart is Empty 😟</Text>
                                                <LinearGradient
                                                    colors={[this.state.gradStart, this.state.gradEnd]}
                                                    start={{ x: 0, y: 0 }}
                                                    end={{ x: 1, y: 0 }}
                                                    style={styles.buttonContainer3}
                                                >
                                                    <TouchableOpacity style={{ flex: 1, alignSelf: 'stretch', alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }} onPress={() => this.props.navigation.navigate('Home')}>
                                                        <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Order Now</Text>
                                                    </TouchableOpacity>
                                                </LinearGradient>
                                            </View>
                                        : null}
                                    {this.state.deliveryNote ?
                                        <View style={{ alignSelf: 'stretch', justifyContent: 'center', flexDirection: 'row', marginLeft: 10 }}>
                                            {/* web disabled
                                            <Ionicons name={Platform.OS === "ios" ? "ios-information-circle" : "md-information-circle"} size={20} color={this.state.gradEnd} style={{ marginLeft: 10, marginTop: 5, marginRight: 5 }} />
                                            
                      <TextTicker
                        style={{ fontSize: 20, color: this.state.gradStart }}
                        duration={10000}
                        loop
                        bounce
                        repeatSpacer={50}
                        marqueeDelay={1000}
                      >
                        {this.state.deliveryNote}
                      </TextTicker> */}
                                        </View> : null}

                                    <FlatList
                                        data={this.state.itemsList}
                                        keyExtractor={(item, index) => index.toString()}
                                        onScroll={() => this.setState({ applyPromo: false })}
                                        extraData={this.state}
                                        renderItem={({ item, index }) => (
                                            <View style={styles.containerGridFull}>

                                                <Card elevation={5} style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'column', marginBottom: 5 }} >
                                                    <TouchableOpacity style={{ flex: 1, alignSelf: 'stretch' }}
                                                        onPress={() => { this.props.navigation.navigate('ItemDetailsScreen', { ItemId: item.id, ItemName: item.ItemName, gradStart: this.state.gradStart, gradEnd: this.state.gradEnd, StoreData: this.state.storeData }) }}>
                                                        <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row' }}>
                                                            {this._maybeRenderImage(item.Image)}
                                                            <View style={{ flex: 1, marginLeft: 5 }}>

                                                                <Text style={{ fontWeight: 'bold', color: 'black', fontSize: 16 }} numberOfLines={1} ellipsizeMode='tail'>{item.ItemName}</Text>
                                                                <View style={{ flexDirection: 'row' }}>
                                                                    {item.Size ? <Text style={{ marginLeft: 10 }}>{item.Size}</Text> : null}
                                                                    {item.Color ? <Text style={{ marginLeft: 10 }}>{item.Color}</Text> : null}
                                                                    {item.Material ? <Text style={{ marginLeft: 10 }}>{item.Material}</Text> : null}
                                                                    {item.Packing ? <Text style={{ marginLeft: 10 }}>{item.Packing}</Text> : null}
                                                                    {item.Other ? <Text style={{ marginLeft: 10 }}>{item.Other}</Text> : null}
                                                                </View>
                                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                                    <Text style={[Cstyles.sellingPrice], { color: this.state.gradStart, fontSize: 20 }}>{item.DiscountPrice > 0 ? this.state.storeData.Currency + (item.DiscountPrice * item.Qty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}{item.DiscountPrice <= 0 ? this.state.storeData.Currency + (item.SellingPrice * item.Qty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>

                                                                    {item.DiscountPrice > 0 ?
                                                                        <Text style={Cstyles.mrp}>{item.MRP > item.DiscountPrice ? this.state.storeData.Currency + (item.MRP * item.Qty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>
                                                                        : <Text style={Cstyles.mrp}>{item.MRP > item.SellingPrice ? this.state.storeData.Currency + (item.MRP * item.Qty).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>
                                                                    }
                                                                    {item.DiscountPrice > 0 ?
                                                                        <Text style={Cstyles.off}>{item.MRP > item.DiscountPrice ? (((item.MRP - item.DiscountPrice) / item.MRP) * 100).toFixed(0).toString() + '% off' : null}</Text>
                                                                        : <Text style={Cstyles.off}>{item.MRP > item.SellingPrice ? (((item.MRP - item.SellingPrice) / item.MRP) * 100).toFixed(0).toString() + '% off' : null}</Text>
                                                                    }

                                                                </View>
                                                                <View style={{ flex: 1, flexDirection: 'row', marginTop: 5, justifyContent: 'space-between' }}>
                                                                    <CNumericCounter
                                                                        size={'large'}
                                                                        theme={'light'}
                                                                        style={{ alignSelf: 'center' }}
                                                                        value={item.Qty}
                                                                        onDecrease={() => this._addToCart(item.id, item.variant, item.DiscountPrice, item.SellingPrice, item.Qty, -1, index)}
                                                                        onIncrease={() => this._addToCart(item.id, item.variant, item.DiscountPrice, item.SellingPrice, item.Qty, 1, index)}
                                                                    />
                                                                    <TouchableOpacity style={{ padding: 5 }} onPress={() => this._removeItem(item.id, item.variant, item.DiscountPrice, item.SellingPrice, item.Qty)}>
                                                                        <Ionicons name="md-trash" size={20} color={'red'} style={{ marginRight: 10 }} ></Ionicons>
                                                                    </TouchableOpacity>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </TouchableOpacity>
                                                </Card>

                                            </View>

                                        )}
                                    />
                                    {!this.state.emptyCart ?
                                        <Card elevation={5} style={{ marginLeft: 5, marginRight: 5, padding: 10, marginBottom: 5 }}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                <View>
                                                    <Text style={{ fontWeight: 'bold', color: 'black', fontSize: 16 }}>{this.state.storeData.Currency + this.state.totalPayableWithDelivery.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Text>
                                                    <TouchableOpacity style={{ padding: 5 }} onPress={() => this._applyPromoToggle()}>
                                                        <Text style={{ color: this.state.gradEnd }}>{this.state.applyPromo ? 'Hide Details' : 'Show Details/ Apply Coupon'}</Text>
                                                    </TouchableOpacity>

                                                </View>

                                                <LinearGradient
                                                    colors={[this.state.gradStart, this.state.gradEnd]}
                                                    style={{ flex: 1 }}
                                                    start={{ x: 0, y: 0 }}
                                                    end={{ x: 1, y: 0 }}
                                                    style={styles.buttonContainer2}
                                                >
                                                    <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }} onPress={() => this._confirmOrder()}>
                                                        <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Place Order</Text>
                                                    </TouchableOpacity>
                                                </LinearGradient>
                                            </View>

                                            {this.state.applyPromo ?
                                                <View>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <View style={{ width: 200 }}>
                                                            <TextInput
                                                                mode='outlined'
                                                                label='Coupon'
                                                                ref={this.CouponRef}
                                                                value={Coupon}
                                                                error={errors.Coupon}
                                                                onFocus={() => this.onFocus()}
                                                                style={{ width: 200 }}
                                                                autoCapitalize='characters'
                                                                tintColor={this.state.gradStart}
                                                                onChangeText={(text) => this.updateTextInput(text, 'Coupon')}
                                                            />
                                                        </View>
                                                        {this.state.applyingCoupon ? <ActivityIndicator size='small' color={this.state.gradStart} style={{ marginLeft: 10, marginTop: 20 }} /> : null}
                                                        {this.state.applyingCoupon ? null :
                                                            <TouchableOpacity style={{ padding: 5 }} onPress={() => this._applyCoupon()}><FontAwesome name="check-circle" size={30} color={'green'} style={{ marginLeft: 10, marginTop: 20 }} /></TouchableOpacity>
                                                        }
                                                    </View>
                                                    <View style={{ marginTop: 10 }}>
                                                        <Text style={{ fontWeight: 'bold', color: 'grey' }}>Price Details</Text>
                                                        <View style={{ borderBottomColor: 'grey', borderBottomWidth: 1 }} />
                                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                                                            <Text>Price ({this.state.itemsList.length} items)</Text>
                                                            <Text>{this.state.storeData.Currency + (this.state.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</Text>
                                                        </View>
                                                        {this.state.discount > 0 ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                                            <Text>Discount</Text>
                                                            <Text>{this.state.storeData.Currency + this.state.discount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                                        </View>
                                                            : null}
                                                        {this.state.deliveryCharge > 0 ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                                            <Text>Delivery Charge</Text>
                                                            <Text>{this.state.storeData.Currency + this.state.deliveryCharge.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                                        </View>
                                                            : null}
                                                        {this.state.deliveryCharge > 0 && this.state.exemptDeliveryCharge && (parseFloat(this.state.totalPayable) < parseFloat(this.state.freeDeliveryAmount)) ? <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                                                            <Text style={{ color: this.state.gradStart }}>{"Shop for " + this.state.storeData.Currency + (parseFloat(this.state.freeDeliveryAmount) - parseFloat(this.state.totalPayable)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " more to get a free delivery"}</Text>
                                                        </View>
                                                            : null}

                                                    </View>
                                                </View>
                                                : null}

                                        </Card>

                                        : null}
                                </View>
                            </KeyboardAwareScrollView>
                        );
                }

            }

        }
    }



    _calculateDeliveryCharge() {
        if (this.state.deliveryCharges && !this.state.storeData.pickupOnly) {
            if (this.state.exemptDeliveryCharge) {
                if (this.state.totalPayable >= this.state.freeDeliveryAmount) {
                    this.setState({ deliveryCharge: 0, totalPayableWithDelivery: this.state.totalPayable })
                } else {//Exempt but amount not reached
                    this._calculateFinalDeliveryCharge()
                }
            } else {//No exempt
                this._calculateFinalDeliveryCharge()
            }
        } else {
            this.setState({ deliveryCharge: 0, totalPayableWithDelivery: this.state.totalPayable })
        }
    }

    _calculateFinalDeliveryCharge() {
        let StoreData = this.state.storeData;

        if (this.state.deliveryChargeType) {
            //Distance based Charge
            var dis = 0;
            if (StoreData.location) {
                var dis = getDeliveryDistance(StoreData.location, StoreData.currentLocation)

            }


            let deliveryCharge = 0;
            if (this.state.maxDeliveryCharge > 0) {
                deliveryCharge = Number(((dis / 1000) * this.state.deliveryChargeAmount) > this.state.maxDeliveryCharge ? this.state.maxDeliveryCharge : ((dis / 1000) * this.state.deliveryChargeAmount)).toFixed(0);
            } else {
                deliveryCharge = Number((dis / 1000) * this.state.deliveryChargeAmount).toFixed(0)
            }

            this.setState({ deliveryCharge, totalPayableWithDelivery: parseFloat(this.state.totalPayable) + parseFloat(deliveryCharge) })

        } else {
            //Flat charge
            this.setState({ deliveryCharge: this.state.deliveryChargeAmount, totalPayableWithDelivery: parseFloat(this.state.totalPayable) + parseFloat(this.state.deliveryChargeAmount) })

        }


    }

    _renderPay() {
        return (<View style={{ alignSelf: 'stretch' }}>
            <LinearGradient
                colors={['#8bc34a', '#aed581']}
                style={{ flex: 1 }}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                style={[styles.buttonContainer2, { marginTop: 10 }]}
            >
                <TouchableOpacity style={{ height: 40, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, alignSelf: 'stretch' }} onPress={() =>
                    this.props.navigation.navigate('PaymentScreen', { payAmount: this.state.totalPayableWithDelivery, currency: this.state.storeData.Currency, gradStart: this.state.gradStart, gradEnd: this.state.gradEnd, onPaymentComplete: this._placeOrder })
                }>
                    <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>Proceed to Checkout</Text>
                </TouchableOpacity>
            </LinearGradient>
            <LinearGradient
                colors={['grey', 'grey']}
                style={{ flex: 1 }}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
                style={[styles.buttonContainer2, { marginTop: 20 }]}
            >
                <TouchableOpacity style={{ height: 40, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, alignSelf: 'stretch' }} onPress={() => this.setState({ page: null })}>
                    <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Go Back</Text>
                </TouchableOpacity>

            </LinearGradient>
        </View>

        )
    }

    validateRequiredField() {
        let errors = {};
        const state = this.state;
        let errorsExist = false;

        ['DName', 'DAddress', 'DCity', 'DStat', 'DPostal', 'DCountry', 'DPhone']
            .forEach((name) => {
                let value = state[name];

                if (!value) {
                    errors[name] = 'Should not be empty';
                    errorsExist = true;
                    if ('DPhone' == name && !value) {
                        alert('Please enter a phone number for the delivery purposes');
                    }
                }
            });

        this.setState({ errors });
        this.setState({ errorsExist });
        return (errorsExist);

    }

    onFocus() {
        let errors = {} = this.state.errors;
        for (let name in errors) {
            let ref = this[name];

            if (ref && ref.isFocused()) {
                delete errors[name];
            }
        }

        this.setState({ errors });
    }

    updateRef(name, ref) {
        this[name] = ref;
    }

    _applyPromoToggle() {
        this.setState({ applyPromo: !this.state.applyPromo });
    }

    _applyCoupon() {
        let errors = {};
        this.setState({ applyingCoupon: true, appliedCoupon: false, discount: 0, totalPayable: this.state.total, errors: [] }, () => this._calculateDeliveryCharge());
        if (this.state.Coupon) {
            firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('promotions').doc(this.state.Coupon).get()
                .then((promoDoc) => {
                    if (promoDoc.exists && promoDoc.data().promoStatus) {
                        if (this.state.total >= parseFloat(promoDoc.data().minOrder)) {
                            if (promoDoc.data().promoType == 'C') {
                                this.setState({ totalPayable: this.state.total - parseFloat(promoDoc.data().discValue), discount: parseFloat(promoDoc.data().discValue), applyingCoupon: false, appliedCoupon: true }, () => this._calculateDeliveryCharge());

                            } else if (promoDoc.data().promoType == 'P') {
                                let disc = ((parseFloat(promoDoc.data().discValue) / 100) * this.state.total);
                                if (disc > parseFloat(promoDoc.data().maxDisc)) {
                                    disc = parseFloat(promoDoc.data().maxDisc);
                                }
                                this.setState({ totalPayable: this.state.total - disc, discount: disc, applyingCoupon: false, appliedCoupon: true }, () => this._calculateDeliveryCharge());

                            }
                        } else {
                            errors['Coupon'] = 'Min Order should be ' + this.state.storeData.Currency + promoDoc.data().minOrder;
                            this.setState({ errors, applyingCoupon: false });
                        }
                    } else {
                        errors['Coupon'] = 'Invalid Code';
                        this.setState({ errors, applyingCoupon: false });
                    }
                });
        } else {
            errors['Coupon'] = 'Enter a Code';
            this.setState({ errors, applyingCoupon: false });
        }



    }

    updateTextInput = (text, field) => {
        const state = this.state
        state[field] = text;
        this.setState(state);
        if (field == 'Coupon') {
            this.setState({ appliedCoupon: false, discount: 0, totalPayable: this.state.total, errors: {} }, () => this._calculateDeliveryCharge());
        }
    }


    _maybeRenderImage = (image) => {
        if (!image) {
            return;
        }

        return (

            <View
                style={{
                    borderRadius: 4,
                    marginTop: 2,
                    overflow: 'hidden',
                }}>
                <Image source={{ uri: image }} style={{ width: 100, height: 100, borderRadius: 4 }} />
            </View>

        );
    }


    _confirmOrder() {


        this.state.animatedValue.setValue(0);
        this.setState({ page: 'Confirm Order', applyPromo: true }, () => {
            Animated.timing(
                this.state.animatedValue,
                {
                    toValue: 1,
                    duration: 500,
                    useNativeDriver: true
                }
            ).start(() => {


            })
        });
    }

    _placeOrder = async (payRef, payMethod, payStatus) => {
        let promise = new Promise((res, rej) => {


            this.setState({ placingOrder: true, isLoading: true });
            var batch = firebase.firestore().batch();
            let that = this;
            var Items = [];


            firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('orders').add({
                CustomerId: this.state.customer,
                CustomerUID: that.props.profile_data.profile_data.uid,
                CustomerName: that.props.profile_data.profile_data.name,
                Status: 'Pending',
                PlacedAt: firebase.firestore.FieldValue.serverTimestamp(),
                Count: this.state.itemsList.length,
                AmountPayable: this.state.totalPayableWithDelivery,
                DeliveryCharge: this.state.deliveryCharge,
                TotalPayableWithoutDelivery: this.state.totalPayable,
                Amount: this.state.total,
                Discount: this.state.discount,
                Coupon: this.state.Coupon ? this.state.Coupon : '',
                PymntMthd: payMethod == 'RazorPay' || payMethod == 'CashFree' ? 'Online' : 'Cash',
                PymntStatus: payStatus,
                PaymentDttm: payStatus ? firebase.firestore.FieldValue.serverTimestamp() : null,
                PaymentRef: payRef,
                Completed: false,
                pendingAcceptance: this.state.storeData.autoOrders ? false : true,
                Accepted: this.state.storeData.autoOrders ? true : false,
                DName: this.state.DName ? this.state.DName : (this.state.profile_data.name ? this.state.profile_data.name : ''),
                DAddress: this.state.DAddress ? this.state.DAddress : this.state.profile_data.address ? this.state.profile_data.address : '',
                DCity: this.state.DCity ? this.state.DCity : this.state.profile_data.city ? this.state.profile_data.city : '',
                DStat: this.state.DStat ? this.state.DStat : this.state.profile_data.stat ? this.state.profile_data.stat : '',
                DPostal: this.state.DPostal ? this.state.DPostal : this.state.profile_data.postal ? this.state.profile_data.postal : '',
                DCountry: this.state.DCountry ? this.state.DCountry : this.state.profile_data.country ? this.state.profile_data.country : '',
                DPhone: (this.state.DPhone && this.DPhoneRef) ? this.state.DPhone : (this.state.profile_data.loginPhone ? this.state.profile_data.loginPhone : this.state.profile_data.phone ? '+' + this.state.profile_data.countrycode + this.state.profile_data.phone : ''),
                location: this.state.storeData.pickupOnly ? null : (this.state.storeData.restrictOrders ? this.state.storeData.currentLocation ? this.state.storeData.currentLocation : null : null),
                pickUp: this.state.storeData.pickupOnly,
                deliverySlot: this.state.deliverySlot
            }).then(function (docRef) {

                let count = 1;
                Promise.all(that.state.itemsList.map((item) => {

                    if (!Items.some(el => el.id === item.id)) {
                        Items.push({ id: item.id })
                    }

                    batch.set(firebase.firestore().collection('mystore').doc(that.state.storeData.id).collection('orders').doc(docRef.id).collection('items').doc(count.toString()), {
                        ItemName: item.ItemName,
                        ItemId: item.id,
                        VariantId: item.variant,
                        Qty: item.Qty,
                        Color: item.Color,
                        Size: item.Size,
                        Material: item.Material,
                        Packing: item.Packing,
                        Other: item.Other,
                        SellingPrice: item.SellingPrice,
                        DiscountPrice: item.DiscountPrice,
                        MRP: item.MRP,
                    });
                    var updStock = null;
                    if (item.variant != 0) {
                        updStock = firebase.firestore().collection('mystore').doc(that.state.storeData.id).collection('items').doc(item.id).collection("variants").doc(item.variant);
                    } else {
                        updStock = firebase.firestore().collection('mystore').doc(that.state.storeData.id).collection('items').doc(item.id);
                    }

                    batch.update(updStock, { StockInCart: firebase.firestore.FieldValue.increment((item.Qty * -1)) });
                    count++;
                    var delCart = firebase.firestore().collection('buyer').doc(that.props.profile_data.profile_data.uid).collection('stores').doc(that.state.storeData.id).collection('cart').doc(item.id).collection('variants').doc(item.variant);
                    batch.delete(delCart);
                })).then(() => {
                    Items.map((itm) => {
                        var delItem = firebase.firestore().collection('buyer').doc(that.props.profile_data.profile_data.uid).collection('stores').doc(that.state.storeData.id).collection('cart').doc(itm.id);
                        batch.delete(delItem);
                    });

                    batch.commit().then(function () {

                        let profile_data = that.props.profile_data.profile_data

                        profile_data.cartCount = 0
                        that.props.updateProfile(profile_data)
                        sendPushNotif(that.state.storeData.pushToken, 'New Order', 'Received a new Order from ' + that.state.name, docRef.id, that.props.profile_data.profile_data.uid, 'New Order');
                        firebase.firestore().collection('mystore').doc(that.state.storeData.id).collection('orders').doc(docRef.id).get().then((orderDoc) => {
                            that.setState({ placingOrder: false, OrderPlaced: true, isLoading: false, itemsList: [], page: null, PlacedAt: orderDoc.data().PlacedAt.toMillis() });
                            res(orderDoc.data().PlacedAt.toMillis().toString().slice(-4))
                        });
                    }).catch(() => {
                        res(false)
                    });
                }).catch(() => {
                    res(false)
                });
            }).catch(() => {
                res(false)
            });

        })
        let result = await promise;
        return result

    }
}

const styles = {
    container: {
        flex: 1,
        backgroundColor: '#ffffff'
    },
    containerGridFull: {
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flex: 1,
        flexDirection: 'row',
        backgroundColor: "transparent",
        marginLeft: 5

    },
    buttonContainer2: {
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
    }, buttonContainer3: {
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        borderRadius: 5,
        height: 45,
        width: 300
    }
};


const mapStateToProps = (state) => {
    const { store, profile_data } = state
    return { store, profile_data }
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        updateStore, updateProfile
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Cart);