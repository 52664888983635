/* eslint-env browser */

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    try {
      navigator.serviceWorker
        .register('/expo-service-worker.js', { scope: '/' })
        .register('SW_PUBLIC_URL/firebase-messaging-sw.js', { scope: 'SW_PUBLIC_SCOPE' })
        .then(function (info) {
          // console.info('Registered service-worker', info);
        })
        .catch(function (error) {
          console.info('Failed to register service-worker', error);
        });
    } catch (e) {
      console.info('Failed to register service-worker', e);
    }
  });
}
