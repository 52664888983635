import { Ionicons, FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import firebase from 'firebase';
import 'firebase/firestore';
import Moment from 'moment';
import React from 'react';
import { FlatList, Dimensions, Image, KeyboardAvoidingView, Platform, StyleSheet, TouchableOpacity, View, Text } from 'react-native';
//import DatePicker from 'react-native-datepicker';
import { Card } from 'react-native-paper';
import RNPickerSelect from 'react-native-picker-select';
import { connect } from 'react-redux';
import { CLoading } from '../components/StyledText';
import Colors from '../constants/Colors';



const ViewTypes = {
  FULL: 0,
  HALF_LEFT: 1,
  HALF_RIGHT: 2
};

let containerCount = 0;

class CellContainer extends React.Component {
  constructor(args) {
    super(args);
    this._containerId = containerCount++;

  }
  render() {
    return <View {...this.props}>{this.props.children}{/*<Text>Cell Id: {this._containerId}</Text>*/}</View>;
  }
}

class Orders extends React.Component {

  constructor(args) {
    super(args);

    this.unsubscribe = null;


    let { width, height } = Dimensions.get("window");

    this.state = {
      isLoading: true,
      scrWidth: ((width * 0.90) / 2),
      width: width,
      currency: '',
      search: '',
      resultSnapshot: null,
      selectionMode: true,
      checked: true,
      total: 0,
      totalPayable: 0,
      discount: 0,
      deleting: false,
      emptyCart: false,
      applyPromo: false,
      Coupon: '',
      applyingCoupon: false,
      appliedCoupon: false,
      errors: {},
      customer: '',
      customerName: '',
      placingOrder: false,
      placedOrder: false,
      display: 'All',
      dateOption: 'Quarter',
      startDate: '',
      EndDate: '',
      custStart: '',
      custEnd: '',
      profileStatus: true,
      gradStart: Colors.gradStart,
      gradEnd: Colors.gradEnd

    };
  }

  _onDisplayChanged(val) {
    this.setState({ display: val }, () => this.fetchData(this.state.storeData.id));

  }


  _onItemSelected(orderId, CustomerName, PlacedAt, PymntStatus, OrderStatus, CustomerId, orderDetails) {
    this.props.navigation.navigate('OrderDetails', { orderId, CustomerName, PlacedAt, PymntStatus, OrderStatus, CustomerId, gradStart: this.state.gradStart, gradEnd: this.state.gradEnd, orderDetails });
  }

  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    return {
      title: 'Orders',
      headerBackground: (
        <LinearGradient
          colors={[params.gradStart ? params.gradStart : Colors.gradStart, params.gradEnd ? params.gradEnd : Colors.gradEnd]}
          style={{ flex: 1 }}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
        />
      ),
      headerRight: (
        <View style={styles.iconContainer}>
          <TouchableOpacity style={{ padding: 10, marginRight: 10 }}
            onPress={navigation.getParam('help')}>
            <Ionicons name={Platform.OS === "ios" ? "ios-help-circle" : "md-help-circle"} size={20} color={'white'}
            /></TouchableOpacity>

        </View>
      ),
      headerLeft: (
        <View style={styles.iconContainer}>
          <TouchableOpacity style={{ padding: 10, marginRight: 10 }}
            onPress={navigation.getParam('close')}>
            <Ionicons name={Platform.OS === "ios" ? "ios-arrow-back" : "md-arrow-back"} size={20} color={'white'}
            /></TouchableOpacity>

        </View>
      ),
      headerTitleStyle: { color: '#fff' },
    };
  };



  state = {
    Dialogvisible: false,
    NewCategoryDescription: '',
    NewCategory: '',
    image: null,
    name: "Test",
    ordersList: [1, 2],
    scrWidth: 100,
  };

  _help() {
    Linking.openURL("https://youtu.be/TuXbvqygaZg");

  }

  _close() {
    this.props.navigation.goBack(null);
  }
  async componentDidMount() {
    console.log('Orders');
    this.props.navigation.setParams({ help: this._help.bind(this) });
    this.props.navigation.setParams({ close: this._close.bind(this) });
    let StoreData = this.props.store.store;
    let profile_data = this.props.profile_data.profile_data;
    this.props.navigation.setOptions({
      headerStyle: {
        backgroundColor: StoreData.gradStart, //Set Header color
      },
      headerTintColor: 'white'
    })
    this.setState({ profile_data, storeData: StoreData, gradStart: StoreData.gradStart ? StoreData.gradStart : this.state.gradStart, gradEnd: StoreData.gradEnd ? StoreData.gradEnd : this.state.gradEnd });
    if (StoreData.gradStart) {
      this.props.navigation.setParams({
        gradStart: StoreData.gradStart
      });
    }
    if (StoreData.gradEnd) {
      this.props.navigation.setParams({
        gradEnd: StoreData.gradEnd
      });
    }

    Moment.locale('en');
    if (profile_data && profile_data.uid) {
      this.setState({
        page: 2,
        name: profile_data.name,
        currency: profile_data.Currency,
        profile_data
      });


    } else {
      this.setState({
        isLoading: true,
        profileStatus: false
      });
    }
    this.didFocusListener = this.props.navigation.addListener(
      'focus',
      () => {
        this.setState({
          isLoading: true
        });
        this.fetchData(StoreData.id);
      },
    );
    //this.fetchData(StoreData.id);
  }

  fetchData(id) {
    console.log('fetch')
    let profile_data = this.props.profile_data.profile_data
    let profileStatus = false
    if (profile_data && (profile_data.loginMail || profile_data.loginPhone)) {
      profileStatus = true;
    }
    if (profileStatus) {
      this.setState({ profileStatus })
      let start = '';
      let end = '';
      switch (this.state.dateOption) {

        case 'Week':
          this.setState({ EndDate: Moment(Date.now()).format('DD-MMM-YYYY'), startDate: Moment(Date.now()).subtract(7, 'day').format('DD-MMM-YYYY') });
          start = Moment(Date.now()).subtract(7, 'day').toDate();
          end = Moment(Date.now()).add(1, 'minutes').toDate();
          break;
        case 'Today':
          this.setState({ EndDate: Moment(Date.now()).format('DD-MMM-YYYY'), startDate: Moment(Date.now()).format('DD-MMM-YYYY') });
          start = Moment(Date.now()).startOf('day').toDate();
          end = Moment(Date.now()).add(1, 'minutes').toDate();
          break;
        case 'Month':
          this.setState({ EndDate: Moment(Date.now()).format('DD-MMM-YYYY'), startDate: Moment(Date.now()).subtract(1, 'month').format('DD-MMM-YYYY') });
          start = Moment(Date.now()).subtract(1, 'month').toDate();
          end = Moment(Date.now()).add(1, 'minutes').toDate();
          break;
        case 'Quarter':
          this.setState({ EndDate: Moment(Date.now()).format('DD-MMM-YYYY'), startDate: Moment(Date.now()).subtract(3, 'month').format('DD-MMM-YYYY') });
          start = Moment(Date.now()).subtract(3, 'month').toDate();
          end = Moment(Date.now()).add(1, 'minutes').toDate();
          break;
        case 'Year':
          this.setState({ EndDate: Moment(Date.now()).format('DD-MMM-YYYY'), startDate: Moment(Date.now()).subtract(1, 'year').format('DD-MMM-YYYY') });
          start = Moment(Date.now()).subtract(1, 'year').toDate();
          end = Moment(Date.now()).add(1, 'minutes').toDate();
          break;
        case 'Custom':
          start = this.state.custStart ? this.state.custStart : Moment(this.state.startDate, 'DD-MMM-YYYY').toDate();
          end = this.state.custEnd ? this.state.custEnd : Moment(this.state.EndDate, 'DD-MMM-YYYY').toDate();
          break;

      }

      //this.setState({ isLoading: true });
      if (this.state.display == 'Open') {
        //this.setState({ isLoading: false });
        this.items = firebase.firestore().collection('mystore').doc(id).collection('orders').where('CustomerUID', '==', profile_data.uid).where('PlacedAt', '>=', start).where('PlacedAt', '<=', end).where('Completed', '==', false).orderBy('PlacedAt', 'desc');
        this.unsubscribe = this.items.onSnapshot(this.onCollectionUpdate);
      } else {
        this.items = firebase.firestore().collection('mystore').doc(id).collection('orders').where('CustomerUID', '==', profile_data.uid).where('PlacedAt', '>=', start).where('PlacedAt', '<=', end).orderBy('PlacedAt', 'desc');
        this.unsubscribe = this.items.onSnapshot(this.onCollectionUpdate);
      }
    } else {
      this.setState({ isLoading: true, profileStatus })
    }

  }

  componentWillUnmount() {
    this.unsubscribe ? this.unsubscribe() : null;
  }

  onCollectionUpdate = (async (querySnapshot) => {
    var ordersList = [];
    querySnapshot.docs.map(async doc => {
      let StatusColor = Colors.red;
      let displayTimeAgo = '';
      switch (doc.data().Status) {
        case 'Pending':
          StatusColor = Colors.grey;
          break;
        case 'Complete':
          StatusColor = Colors.green;
          break;
        case 'Cancelled':
          StatusColor = Colors.red;
          break;
        default:
          StatusColor = Colors.orange;
      }
      let timeAgo = (Date.now() - doc.data().PlacedAt.toMillis()) / 1000;
      if (timeAgo > 60) {
        if ((timeAgo / 60) > 60) {
          if (((timeAgo / 60) / 60) > 24) {
            displayTimeAgo = (((timeAgo / 60) / 60) / 24).toFixed(0) + ' days ago'
          } else {
            displayTimeAgo = ((timeAgo / 60) / 60).toFixed(0) + ' hrs ago'
          }
        } else {
          displayTimeAgo = Number((timeAgo / 60).toFixed(0)) + ' min ago'
        }
      } else {
        if (timeAgo < 1) {
          displayTimeAgo = 'Just now'
        } else {
          displayTimeAgo = timeAgo.toFixed(0) + ' sec ago'
        }
      }
      ordersList.push({
        id: doc.id,
        Status: doc.data().Status,
        pickUp: doc.data().pickUp,
        StatusColor,
        displayTimeAgo,
        Amount: doc.data().Amount,
        PymntMthd: doc.data().PymntMthd,
        PymntStatus: doc.data().PymntStatus,
        Count: doc.data().Count,
        AmountPayable: doc.data().AmountPayable,
        PlacedAt: doc.data().PlacedAt.toMillis(),
        CustomerId: doc.data().CustomerId,
        CustomerName: doc.data().CustomerName,
        DispDttm: doc.data().DispDttm,
        ShipService: doc.data().ShipService,
        ShipTracking: doc.data().ShipTracking,
        pendingAcceptance: doc.data().pendingAcceptance == undefined ? false : doc.data().pendingAcceptance,
        Accepted: doc.data().pendingAcceptance == undefined ? true : doc.data().Accepted == undefined ? false : doc.data().Accepted,
        deliverySlot: doc.data().deliverySlot == undefined ? '' : doc.data().deliverySlot
      });
    });
    if (ordersList.length == 0) {
      this.setState({ emptyCart: true });
    } else {
      this.setState({ emptyCart: false });
    }
    this.setState({
      ordersList,
      isLoading: false,
    });
  });


  render() {
    let { errors = {} } = this.state;
    let { Coupon } = this.state;
    if (this.state.isLoading) {
      if (this.state.profileStatus) {
        return (<CLoading gradStart={this.state.gradStart} gradEnd={this.state.gradEnd}></CLoading>);
      } else {
        return (<View style={{ flex: 1, backgroundColor: '#ffffff', justifyContent: 'center' }}>


          <LinearGradient
            colors={[this.state.gradStart, this.state.gradEnd]}
            style={{ flex: 1 }}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={styles.buttonContainer2}
          >
            <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }} onPress={() => { this.props.navigation.navigate('Profile') }}>
              <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Update Profile to continue</Text>

            </TouchableOpacity>
          </LinearGradient>

        </View>);
      }

    } else {
      return (
        <KeyboardAvoidingView style={styles.container} behavior="padding" enabled>
          <Card style={{ elevation: 5, alignSelf: "stretch", flexDirection: 'column', marginTop: 5, padding: 5 }}>
            <View style={{ flexDirection: "row" }}>

              <View style={{ width: Platform.OS === 'ios' ? 100 : 140, height: 15, marginTop: Platform.OS === 'ios' || Platform.OS === 'web' ? 10 : -6 }}>
                <RNPickerSelect
                  onValueChange={(value) => this._updateDisplayOption(value)}
                  value={this.state.dateOption}
                  items={[
                    { label: 'This Week', value: 'Week' },
                    { label: "Today", value: "Today" },
                    { label: "This Month", value: "Month" },
                    { label: "This Quarter", value: "Quarter" },
                    { label: "This Year", value: "Year" },
                    { label: "Custom", value: "Custom" },
                  ]}
                />
              </View>

              <View style={{ borderRightColor: 'grey', borderRightWidth: 1, height: 15, marginRight: 5, marginTop: 10 }} />
              <Ionicons name="md-calendar" size={20} color={'grey'} style={{ marginTop: 5 }} ></Ionicons>
              {/* Web Disabled
              <DatePicker
                style={{
                  width: 100, marginTop: -3, marginLeft: -10, transform: [
                    { scaleX: 0.75 },
                    { scaleY: 0.75 },
                  ]
                }}
                date={this.state.startDate}
                mode="date"
                placeholder="select date"
                format="DD-MMM-YYYY"
                minDate="01-Jan-2015"
                maxDate="01-Jan-2030"
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                showIcon={false}
                customStyles={{ dateInput: { borderWidth: 0 } }}
                onDateChange={(date) => { this.setState({ startDate: date, custStart: Moment(date, 'DD-MMM-YYYY').toDate(), dateOption: 'Custom' }); this.fetchData() }}
              />
              <Text style={{ fontSize: 10, marginTop: 10 }}>to</Text>
              <DatePicker
                style={{
                  width: 100, marginTop: -3, transform: [
                    { scaleX: 0.75 },
                    { scaleY: 0.75 },
                  ]
                }}
                date={this.state.EndDate}
                mode="date"
                placeholder="select date"
                format="DD-MMM-YYYY"
                minDate="01-Jan-2015"
                maxDate="01-Jan-2030"
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                showIcon={false}
                customStyles={{ dateInput: { borderWidth: 0 } }}
                onDateChange={(date) => { this.setState({ EndDate: date, custEnd: Moment(date, 'DD-MMM-YYYY').toDate(), dateOption: 'Custom' }); this.fetchData() }}
              /> */}


            </View>
            <View style={{ alignSelf: "stretch", flexDirection: "row", justifyContent: "space-around", minHeight: 1, minWidth: 1 }}>
              <TouchableOpacity style={{ justifyContent: "center", padding: 10 }} onPress={() => this._onDisplayChanged("All")}><Text style={{ fontWeight: 'bold', color: this.state.display == 'All' ? this.state.gradStart : 'grey' }}>All</Text></TouchableOpacity>
              <TouchableOpacity style={{ justifyContent: "center", padding: 10 }} onPress={() => this._onDisplayChanged("Open")}><Text style={{ fontWeight: 'bold', color: this.state.display == 'Open' ? this.state.gradStart : 'grey' }}>Open</Text></TouchableOpacity>

            </View>
          </Card>
          {this.state.emptyCart ?
            <View style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}><Image source={require('../assets/images/happygif.gif')} style={{ alignSelf: "center", resizeMode: 'contain', width: 200, height: 250 }} />
              <Text style={{ textAlign: 'center', fontSize: 20, color: this.state.gradStart, margin: 20 }}>{this.state.display == 'Open' ? 'No Open orders for the selected period' : 'No Orders for the selected period. Try selecting a different period'}</Text>
              {this.state.display == 'Open' ?
                <LinearGradient
                  colors={[this.state.gradStart, this.state.gradEnd]}
                  style={{ flex: 1 }}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                  style={styles.buttonContainer2}
                >
                  <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }} onPress={() => this.setState({ display: 'All' })}>
                    <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Check All Orders</Text>
                  </TouchableOpacity>
                </LinearGradient>
                : null}
            </View>
            :
            <FlatList
              data={this.state.ordersList}
              renderItem={({ item }) => (

                <Card style={{ flex: 1, alignSelf: 'stretch', marginTop: 5, marginHorizontal: 5, elevation: 5 }} >
                  <TouchableOpacity style={{ flex: 1, alignSelf: 'stretch' }} onPress={() => this._onItemSelected(item.id, item.CustomerName, item.PlacedAt, item.PymntStatus, item.Status, item.CustomerId, item)}>
                    <View style={{ flexDirection: 'row', flex: 1, alignSelf: 'stretch' }}>
                      <View style={{ backgroundColor: item.StatusColor, width: 10, height: 90 }}></View>
                      <View style={{ flex: 1, flexDirection: 'row' }}>

                        <View style={{ flex: 1, marginLeft: 5 }}>
                          <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between' }}>
                            {item.deliverySlot ?
                              <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesome name={'clock-o'} color={this.state.gradStart} size={16} />
                                <Text style={{ marginLeft: 5 }}>{item.deliverySlot}</Text>
                              </View>
                              : <View></View>}

                            <Text style={{ color: Colors.grey, marginRight: 5, marginleft: 5 }}>{item.displayTimeAgo}</Text>
                          </View>
                          <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <View style={{ flexDirection: 'row' }}>
                              <Text style={{ fontSize: 10, fontWeight: 'bold', color: this.state.gradStart, marginTop: 15 }}>Order Id: </Text>
                              <Text style={{ fontSize: 25, fontWeight: 'bold', color: this.state.gradStart }}>{item.PlacedAt.toString().slice(-4)}</Text>
                            </View>
                            <Text style={{ marginRight: 5, color: item.PymntStatus ? Colors.green : Colors.red }}>{item.PymntStatus ? 'Paid' : 'Pending Payment'}</Text>
                          </View>

                          <View style={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text>{this.state.storeData.Currency + Number(item.AmountPayable).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                            {item.Status == 'Cancelled' ?
                              <Text style={{ color: 'red' }}>Cancelled</Text> :
                              item.pendingAcceptance ? <Text style={{ color: 'orange' }}>Awaiting accceptance</Text> :
                                item.Accepted ? (item.Status == 'Dispatched' || item.Status == 'Delivered' || item.Status == 'Complete') ? <Text style={{ color: 'green' }}>{(item.Status == 'Dispatched' && item.pickUp) ? 'Ready for Pick up' : item.Status}</Text> : <Text style={{ color: 'green' }}>Accepted</Text> : <Text style={{ color: 'red' }}>Declined</Text>}
                            <Text style={{ marginRight: 5 }}>{item.Count + ' items'}</Text>
                          </View>
                          <View style={{ flexDirection: 'row' }}>
                          </View>

                        </View>
                      </View>
                    </View>
                  </TouchableOpacity>
                </Card>
              )}
              keyExtractor={item => item.id}
            />
          }



        </KeyboardAvoidingView>
      );
    }

  }

  _updateDisplayOption(option) {
    this.setState({ dateOption: option }, () => this.fetchData(this.state.storeData.id));



  }










  get gradient() {
    return (
      <LinearGradient
        colors={[this.state.gradStart, this.state.gradEnd]}
        startPoint={{ x: 1, y: 0 }}
        endPoint={{ x: 0, y: 1 }}
        style={styles.gradient}
      />
    );
  }






  _maybeRenderImage = (image) => {
    //let { image } = this.state;
    if (!image) {
      return;
    }

    return (

      <View
        style={{
          borderRadius: 4,
          marginTop: 2,
          shadowColor: 'rgba(0,0,0,1)',
          shadowOpacity: 0.2,
          shadowOffset: { width: 4, height: 4 },
          shadowRadius: 5,
          overflow: 'hidden',
        }}>
        <Image source={{ uri: image }} style={{ width: 95, height: 95, borderRadius: 4 }} />
      </View>

    );
  }

}

const styles = {
  container: {
    alignItems: "stretch",
    flex: 1,
    minWidth: 1,
    minHeight: 1,
    backgroundColor: '#ffffff'

  },
  containerGridLeft: {
    justifyContent: "space-around",
    alignItems: "center",
    flex: 1,
    backgroundColor: "#ffffff",
    marginTop: 5,
    marginLeft: 5

  },
  containerGridFull: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1,
    flexDirection: 'row',
    backgroundColor: "transparent",
    marginLeft: 5

  }, subtitle: {
    marginTop: 5,
    backgroundColor: 'transparent',
    color: 'grey',
    fontSize: 13,
    fontStyle: 'italic',
    textAlign: 'left'
  }, sellingPrice: {
    backgroundColor: 'transparent',
    color: Colors.gradStart,
    fontSize: 20,
    fontWeight: 'bold'
  },
  mrp: {
    marginLeft: 5,
    marginTop: 7,
    backgroundColor: 'transparent',
    color: 'grey',
    fontSize: 13,
    fontStyle: 'italic',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid'
  },
  off: {
    marginLeft: 5,
    marginTop: 7,
    backgroundColor: 'transparent',
    color: 'green',
    fontSize: 13,
    fontStyle: 'italic',
  }, buttonContainer2: {
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 5,
    height: 45,
    width: 300
  },
  costPrice: {
    marginLeft: 5,
    marginTop: 7,
    backgroundColor: 'transparent',
    color: 'grey',
    fontSize: 13,
  }, gradient: {
    ...StyleSheet.absoluteFillObject
  },
  containerGridRight: {
    justifyContent: "space-around",
    alignItems: "center",
    flex: 1,
    backgroundColor: "#ffffff",
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5
  }, buttonContainer: {
    height: 45,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
    width: 300,
    borderRadius: 30,
    backgroundColor: 'transparent'
  },
};




const mapStateToProps = (state) => {
  const { store, profile_data } = state
  return { store, profile_data }
};

export default connect(mapStateToProps)(Orders);