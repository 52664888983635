import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Location from 'expo-location';
import * as Permissions from 'expo-permissions';
import firebase from 'firebase';
import 'firebase/firestore';
import { getDistance } from 'geolib';
import { Platform, Share } from 'react-native';

export async function getCurrentLocation() {
    const { status, permissions } = await Permissions.askAsync(Permissions.LOCATION);
    if (status === 'granted' || permissions.location.foregroundGranted == true) {
        let location = await Location.getCurrentPositionAsync({}).catch((error) => {
            return false
        });
        if (location) {
            return { latitude: location.coords.latitude, longitude: location.coords.longitude }
        } else {
            return false;
        }


    } else {
        return false
    }
}

export async function getLocationAddress(location) {
    console.log(location)
    Location.setGoogleApiKey('AIzaSyBFXFdsvWcK2la7Pl6ysZIMqNVOzoILka4')
    let result = await Location.reverseGeocodeAsync({ latitude: location.latitude, longitude: location.longitude }).catch((e) => {
        alert(e)
        return false
    })
    console.log(result)
    return result
}

export function getDeliveryDistance(fromLocation, toLocation) {
    // Returns distance in meters
    var dis = getDistance(

        { latitude: fromLocation.latitude, longitude: fromLocation.longitude }, { latitude: toLocation.latitude, longitude: toLocation.longitude }
    );
    return dis
}

export async function registerForPushNotificationsAsync() {
    if (Platform.OS != 'web') {
        let Notifications = require('expo-notifications')
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);


        // only asks if permissions have not already been determined, because
        // iOS won't necessarily prompt the user a second time.
        // On Android, permissions are granted on app installation, so
        // `askAsync` will never prompt the user

        // Stop here if the user did not grant permissions
        if (status !== 'granted') {
            //alert('No notification permissions!');
            return false;
        }

        // Get the token that identifies this device
        let experienceId = undefined;
        // if (!Constants.manifest) {
        //   // Absence of the manifest means we're in bare workflow
        //   experienceId = '@nandhamkumar/storemystore';
        // }
        let token = await Notifications.getExpoPushTokenAsync({
            experienceId,
        });

        // POST the token to your backend server from where you can retrieve it to send push notifications.
        return token.data
    } else {
        return null
    }

}

export function getStripeCheckoutHtml(sessionId, sellerKey, stripeAccount) {
    return (`<html>

<head>
  <title>Store My Store</title>
  <script src="https://js.stripe.com/v3/"></script>
</head>

<body>
  <!-- <button id="checkout-button">Checkout</button>
   -->
  <script type="text/javascript">
    // Create an instance of the Stripe object with your publishable API key
    var stripe = Stripe('` + sellerKey + `',{
        stripeAccount: '`+ stripeAccount + `'
      });
    // var checkoutButton = document.getElementById('checkout-button');

    window.addEventListener('load', function () {
      // Create a new Checkout Session using the server-side endpoint you
      // created in step 3.
      stripe.redirectToCheckout({ sessionId: '` + sessionId + `' })
        .then(function (result) {
          // If 'redirectToCheckout' fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using 'error.message'.
          if (result.error) {
            alert(result.error.message);
          }
        })
        .catch(function (error) {
          console.error('Error:', error);
        });
    });
  </script>
</body>

</html>`)
}

export function getCashFreeCheckoutHtml(storeData, signature, payReq, orderDocId, paymentSplits) {
    console.log(signature)
    console.log(`<html>
  <head>
  <title>Write example</title>
  <script>
    function initPay() {

    }
  </script>
</head>
  <body onload=>
  <script>
  window.addEventListener('load', function (e) {
              document.getElementById("redirectForm").submit();
          
          })
          </script>
  <form id="redirectForm" method="post" action="https://www.cashfree.com/checkout/post/submit">
      <input type="hidden" name="appId" value="113248cd68cdaa89dbb25c9e36842311"/>
      <input type="hidden" name="orderId" value="` + orderDocId + `"/>
      <input type="hidden" name="orderAmount" value="` + payReq.amount + `"/>
      <input type="hidden" name="orderCurrency" value="` + payReq.currency + `"/>
      <input type="hidden" name="orderNote" value="` + payReq.storeUid + '|' + payReq.custUid + `"/>
      <input type="hidden" name="customerName" value="` + payReq.customerName + `"/>
      <input type="hidden" name="customerEmail" value="` + payReq.customerEmail + `"/>
      <input type="hidden" name="customerPhone" value="` + payReq.customerPhone + `"/>
      <input type="hidden" name="returnUrl" value="https://us-central1-vyapar-myhomestore.cloudfunctions.net/cashFreeResponse?doc=` + orderDocId + `"/>
      <input type="hidden" name="notifyUrl" value=""/>
      <input type="hidden" name="signature" value="` + signature + `"/>
      <input type="hidden" name="paymentSplits" value="` + paymentSplits + `"/>
    </form>
    <script>
    window.addEventListener('load', function (e) {
                document.getElementById("redirectForm").submit();
            
            })
            </script>
    </body>
  <html>
  `)
    return (
        `<html>
    <head>
  <title>Write example</title>
  <script>
    function initPay() {
      document.getElementById("redirectForm").submit();
    }
  </script>
</head>
    <body onload="initPay()">
    
    <form id="redirectForm" method="post" action="https://www.cashfree.com/checkout/post/submit">
        <input type="hidden" name="appId" value="113248cd68cdaa89dbb25c9e36842311"/>
        <input type="hidden" name="orderId" value="` + orderDocId + `"/>
        <input type="hidden" name="orderAmount" value="` + payReq.amount + `"/>
        <input type="hidden" name="orderCurrency" value="` + payReq.currency + `"/>
        <input type="hidden" name="orderNote" value="` + payReq.storeUid + '|' + payReq.custUid + `"/>
        <input type="hidden" name="customerName" value="` + payReq.customerName + `"/>
        <input type="hidden" name="customerEmail" value="` + payReq.customerEmail + `"/>
        <input type="hidden" name="customerPhone" value="` + payReq.customerPhone + `"/>
        <input type="hidden" name="returnUrl" value="https://us-central1-vyapar-myhomestore.cloudfunctions.net/cashFreeResponse?doc=` + orderDocId + `"/>
        <input type="hidden" name="notifyUrl" value=""/>
        <input type="hidden" name="signature" value="` + signature + `"/>
        <input type="hidden" name="paymentSplits" value="` + paymentSplits + `"/>
        <input type="submit" value="Pay">
      </form>
      </body>
    <html>
    `
    )
}

export function getRazorPayCheckoutHtml(storeData, orderId, custInfo, orderDocId) {
    console.log(custInfo)
    return (
        `<script src="https://checkout.razorpay.com/v1/checkout.js"></script>
        <script>
        var options = {
            "key": "rzp_live_NG4VMPnqdE2YbR", // Enter the Key ID generated from the Dashboard
            //"amount": "5000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            //"currency": "INR",
            "name": "` + storeData.storeName + `",
            "description": "` + "Store My Store" + `",
            "image": "` + (storeData.image ? storeData.image : 'https://firebasestorage.googleapis.com/v0/b/vyapar-myhomestore.appspot.com/o/icon.png?alt=media&token=760ef738-bd12-46c6-81b0-70f542170d1f') + `",
            "order_id": "` + orderId + `", //This is a sample Order ID. Pass the 'id' obtained in the response of Step 1
            "callback_url": "https://us-central1-vyapar-myhomestore.cloudfunctions.net/razorPayCallback` + (Platform.OS == 'web' ? 'Web' : '') + `?doc=` + orderDocId + `",
            redirect: true,
            "prefill": {
                "name": "` + custInfo.name + `",
                "email": "` + custInfo.email + `",
                "contact": "` + custInfo.phone + `"
            },
            "notes": {
                "orderId": "` + orderId + `"
            },
           /* "theme": {
                "color": "#3399cc"
            }*/
        };
        var rzp1 = new Razorpay(options);
        window.addEventListener('load', function (e) {
            rzp1.open();
            e.preventDefault();
        
        })
        </script>`
    )
}

export function getRazorPayCheckoutHtmlWeb(storeData, orderId, custInfo, orderDocId) {
    console.log(custInfo)
    return (
        `<script src="https://checkout.razorpay.com/v1/checkout.js"></script>
        <script>
        var options = {
            "key": "rzp_live_NG4VMPnqdE2YbR", // Enter the Key ID generated from the Dashboard
            //"amount": "5000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            //"currency": "INR",
            "name": "` + storeData.storeName + `",
            "description": "` + "Store My Store" + `",
            "image": "` + (storeData.image ? storeData.image : 'https://firebasestorage.googleapis.com/v0/b/vyapar-myhomestore.appspot.com/o/icon.png?alt=media&token=760ef738-bd12-46c6-81b0-70f542170d1f') + `",
            "order_id": "` + orderId + `", //This is a sample Order ID. Pass the 'id' obtained in the response of Step 1
            "handler": function (response){
              parent.postMessage(response,'*');
              // alert(response.razorpay_payment_id);
              // alert(response.razorpay_order_id);
              // alert(response.razorpay_signature)
          },
            "prefill": {
                "name": "` + custInfo.name + `",
                "email": "` + custInfo.email + `",
                "contact": "` + custInfo.phone + `"
            },
            "notes": {
                "orderId": "` + orderId + `"
            },
           /* "theme": {
                "color": "#3399cc"
            }*/
        };
        var rzp1 = new Razorpay(options);
        rzp1.on('payment.failed', function (response){
          parent.postMessage(response,'*');
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
  });
        window.addEventListener('load', function (e) {
            rzp1.open();
            e.preventDefault();
        
        })
        </script>`
    )
}

export function updatePushToken(token, uid) {
    if (token) {
        firebase.firestore().collection('buyerProfile').doc(uid).update({ pushToken: token }).then(() => {
            AsyncStorage.setItem('pushToken', token);
        }).catch((error) => {

        });
    }
}


export async function sendPushNotif(to, title, message, id, uid, type) {
    if (to)
        fetch('https://exp.host/--/api/v2/push/send', {
            mode: 'no-cors',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "to": to,
                "title": title,
                "body": message,
                "data": {
                    type: type,
                    id: id,
                    uid: uid
                }
            })
        }).then((response) => response.json())
            .then((data) => {
                console.warn('Success:', data);
            }).catch((e) => {
                console.warn('Failed Push notification:', e);
            });
}


export async function shareText(message) {

    try {
        const result = await Share.share({
            message: message,
        });

        if (result.action === Share.sharedAction) {
            if (result.activityType) {

            } else { }
        } else if (result.action === Share.dismissedAction) {
            // dismissed
        }
    } catch (error) {
        alert(error.message);
    }

}

export async function getStoreInfo(storeId, location) {
    let LocationRequired = false;
    let promise = new Promise((res, rej) => {
        firebase.firestore().collection('profile').doc(storeId).get().then((doc) => {
            if (doc.exists) {
                if (doc.data().makePublic) { //Show only if the Store is public

                    let storeData = {
                        id: doc.id,
                        address: doc.data().address ? doc.data().address : '',
                        city: doc.data().city ? doc.data().city : '',
                        country: doc.data().country ? doc.data().country : '',
                        stat: doc.data().stat ? doc.data().stat : '',
                        postal: doc.data().postal ? doc.data().postal : '',
                        gradEnd: doc.data().gradEnd ? doc.data().gradEnd : '',
                        gradStart: doc.data().gradStart ? doc.data().gradStart : '',
                        image: doc.data().image ? doc.data().image : '',
                        descr: doc.data().descr ? doc.data().descr : '',
                        storeName: doc.data().storeName ? doc.data().storeName : '',
                        phone: doc.data().phone ? doc.data().phone : '',
                        mail: doc.data().mail ? doc.data().mail : '',
                        Currency: doc.data().Currency,
                        CurrencyISO: doc.data().CurrencyISO,
                        loginPhone: doc.data().loginPhone,
                        loginMail: doc.data().loginMail,
                        cca: doc.data().cca,
                        countrycode: doc.data().countrycode,
                        takeOrder: doc.data().takeOrder,
                        OosOrders: doc.data().OosOrders,
                        autoOrders: doc.data().autoOrders == undefined ? true : doc.data().autoOrders,
                        restrictOrders: doc.data().restrictOrders == undefined ? false : doc.data().restrictOrders,
                        orderDistance: doc.data().orderDistance ? doc.data().orderDistance : 0,
                        pickupOnly: doc.data().pickupOnly == undefined ? false : doc.data().pickupOnly,
                        makePublic: doc.data().makePublic,
                        shareAddress: doc.data().shareAddress,
                        sharePhone: doc.data().sharePhone,
                        shareEmail: doc.data().shareEmail,
                        pushToken: doc.data().pushToken,
                        location: doc.data().location ? doc.data().location : null,
                        serviceable: doc.data().restrictOrders == undefined ? true : !doc.data().restrictOrders,
                        currentLocation: location,
                        deliveryCharges: doc.data().deliveryCharges == undefined ? false : doc.data().deliveryCharges,
                        deliveryChargeAmount: doc.data().deliveryChargeAmount ? doc.data().deliveryChargeAmount : 0,
                        deliveryChargeType: doc.data().deliveryChargeType == undefined ? false : doc.data().deliveryChargeType,
                        maxDeliveryCharge: doc.data().maxDeliveryCharge ? doc.data().maxDeliveryCharge : 0,
                        exemptDeliveryCharge: doc.data().exemptDeliveryCharge == undefined ? false : doc.data().exemptDeliveryCharge,
                        freeDeliveryAmount: doc.data().freeDeliveryAmount ? doc.data().freeDeliveryAmount : 0,
                        deliveryNote: doc.data().deliveryNote ? doc.data().deliveryNote : '',
                        storeLink: doc.data().storeLink,
                        storeLinkToShare: doc.data().storeLinkToShare,
                        defaultCountryCode: doc.data().defaultCountryCode == undefined ? '' : doc.data().defaultCountryCode,
                        restrictCountryCode: doc.data().restrictCountryCode == undefined ? false : doc.data().restrictCountryCode,
                        carousel: doc.data().carousel == undefined ? [] : doc.data().carousel,
                        deliverySlot: doc.data().deliverySlot == undefined ? false : doc.data().deliverySlot,
                        deliverySlotList: doc.data().deliverySlotList == undefined ? [] : doc.data().deliverySlotList,
                    }
                    res(storeData)

                }
            } else {
                res(false)
            }
        }).catch((e) => {
            res(false)
        })
    })
    let result = await promise;
    return result
}

