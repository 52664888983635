import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import { LinearGradient } from 'expo-linear-gradient';
import firebase from 'firebase';
import React from 'react';
import { ActivityIndicator, Image, ImageBackground, ScrollView, StyleSheet, Switch, TouchableOpacity, View, Text } from 'react-native';
import { Avatar, TextInput } from 'react-native-paper';
//import PhoneInput from 'react-native-phone-input';
import { PoweredBy } from '../components/StyledText';
import Colors from '../constants/Colors';
import { registerForPushNotificationsAsync } from '../helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateProfile, updateStore } from '../actions/stores';
import { __handlePersistedRegistrationInfoAsync } from 'expo-notifications/build/DevicePushTokenAutoRegistration.fx';
export class ProfileScreen extends React.Component {
  state = {
    currentUser: null,
    phoneNumber: '',
    otp: null,
    name: '',
    descr: '',
    address: '',
    city: '',
    stat: '',
    country: '',
    postal: '',
    phone: '',
    mail: '',
    sharePhone: true,
    shareEmail: true,
    errors: {},
    disabled: false,
    uploading: false,
    Dialogvisible: false,
    ColorDialogvisible: false,
    Currency: '₹',
    image: '',
    countrycode: '',
    notExists: false,
    gradStart: Colors.gradStart,
    gradEnd: Colors.gradEnd,
    ColorSelector: 1,
    cca: 'in',
    pushToken: '',
    showRequestOtp: false,
    showConfirmOtp: false
  };
  
  static navigationOptions = {
    header: null,
  };


  async componentDidMount() {
    console.log('Profile');
    registerForPushNotificationsAsync().then((token) => {
      this.setState({ pushToken: token });

    })
    let StoreData = this.props.store.store;
    console.log(StoreData.defaultCountryCode)
    this.setState({ defaultCountryCode: StoreData.defaultCountryCode, restrictCountryCode: StoreData.restrictCountryCode })
    if (StoreData.defaultCountryCode && !StoreData.restrictCountryCode) {
      this.setState({ phoneNumber: StoreData.defaultCountryCode })
    }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ currentUser: user, logonStatus: true });

        const fb_fs_profile = firebase.firestore().collection('buyerProfile').doc(user.uid);
        fb_fs_profile.get().then(snapshot => {
          if (snapshot.exists) {
            const { descr, address, city, stat, phone, country, postal, Currency, image, name, gradStart, gradEnd, mail, sharePhone, shareEmail, cca, countrycode } = snapshot.data();
            this.setState({
              descr, address, city, stat, phone, country, postal, Currency, image, name, mail, sharePhone, shareEmail, cca, countrycode, gradStart: gradStart ? gradStart : this.state.gradStart, gradEnd: gradEnd ? gradEnd : this.state.gradEnd
            });
            let profile_data = snapshot.data()
            profile_data.uid = snapshot.id;
            this.props.updateProfile(profile_data)

          } else {
            AsyncStorage.getItem('appleName').then(value => {
              if (value == null) {
                console.warn("No name")
              } else {
                console.warn(value)
                this.setState({ name: value })
              }
            })
            this.setState({ disabled: false, notExists: true });
          }

        });

      } else {
        this.setState({ logonStatus: false })
        this._renderRecaptcha()
        this.nameRef = this.updateRef.bind(this, 'name');
        this.addressRef = this.updateRef.bind(this, 'address');
        this.cityRef = this.updateRef.bind(this, 'city');
        this.statRef = this.updateRef.bind(this, 'stat');
        this.countryRef = this.updateRef.bind(this, 'country');
        this.postalRef = this.updateRef.bind(this, 'postal');
        //this.phoneRef = this.updateRef.bind(this, 'phone');
        this.mailRef = this.updateRef.bind(this, 'mail');
      }
    })
  }

  _renderRecaptcha() {
    let that = this;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
      'size': 'normal',
      'callback': function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
        console.log('Verified')
        that.setState({ showRequestOtp: true })
      },
      'expired-callback': function () {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        console.log('Expired')
        that.setState({ showRequestOtp: false })
      },
      'Timeout': function () {
        // Response expired. Ask user to solve reCAPTCHA again.
        // ...
        console.log('Expired')
        that.setState({ showRequestOtp: false })
      }
    });
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    })

  }

  _maybeRenderUploadingOverlay = () => {
    if (this.state.uploading) {
      return (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: 'rgba(0,0,0,0.4)',
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}>
          <ActivityIndicator color="#fff" animating size="large" />
          <Text color='white'>Uploading Image...</Text>
        </View>
      );
    }
  }

  render() {

    let { logonStatus, address, city, stat, phone, country, postal, image, name, mail, sharePhone, shareEmail, phoneNumber, otp, verificationId, defaultCountryCode, restrictCountryCode } = this.state;
    let { errors = {} } = this.state;
    if (logonStatus) {
      return (
        <ImageBackground source={require('../assets/images/bg.jpg')} style={{ width: '100%', height: '100%' }}>
          <ScrollView style={styles.container}>
            <View style={styles.body}>

              <View style={styles.bodyContent}>
                <Text style={{ color: 'white', fontSize: 30, marginLeft: 10, marginRight: 10, textAlign: 'center' }}>Your Profile</Text>

                <Text style={styles.info}>{this.state.currentUser ? this.state.currentUser.phoneNumber ? this.state.currentUser.phoneNumber : this.state.currentUser.email : ""}</Text>
                <View style={styles.inputContainer}>
                  <TextInput
                    label='Name'
                    placeholder='Name'
                    ref={this.nameRef}
                    value={name}
                    error={errors.name}
                    editable={!this.state.disabled}
                    style={[styles.inputs]}
                    onChangeText={(text) => this.updateTextInput(text, 'name')}
                  />
                </View>
                <View style={styles.inputContainer}>
                  <TextInput
                    placeholder='Address'
                    ref={this.addressRef}
                    value={address}
                    error={errors.address}
                    editable={!this.state.disabled}
                    style={[styles.inputs]}
                    onChangeText={(text) => this.updateTextInput(text, 'address')}
                  />
                </View>
                <View style={styles.inputContainer}>
                  <TextInput
                    placeholder='City'
                    ref={this.cityRef}
                    value={city}
                    error={errors.city}
                    editable={!this.state.disabled}
                    style={[styles.inputs]}
                    onChangeText={(text) => this.updateTextInput(text, 'city')}
                  />
                </View>
                <View style={styles.inputContainer}>
                  <TextInput
                    placeholder='State'
                    ref={this.statRef}
                    value={stat}
                    error={errors.stat}
                    editable={!this.state.disabled}
                    style={[styles.inputs]}
                    onChangeText={(text) => this.updateTextInput(text, 'stat')}
                  />
                </View>
                <View style={styles.inputContainer}>
                  <TextInput
                    placeholder='Country'
                    ref={this.countryRef}
                    value={country}
                    error={errors.country}
                    editable={!this.state.disabled}
                    style={[styles.inputs]}
                    onChangeText={(text) => this.updateTextInput(text, 'country')}
                  />
                </View>
                <View style={styles.inputContainer}>
                  <TextInput
                    placeholder='Postal'
                    ref={this.postalRef}
                    value={postal}
                    error={errors.postal}
                    editable={!this.state.disabled}
                    style={[styles.inputs]}
                    onChangeText={(text) => this.updateTextInput(text, 'postal')}
                  />
                </View>


                {this.state.currentUser && this.state.currentUser.phoneNumber ? null :
                  <View style={styles.inputContainer}>
                    <TextInput style={[styles.inputs]}
                      textStyle={{ color: this.state.gradStart, fontSize: 18 }}
                      textProps={{ placeholder: "Phone Number" }}
                      onChangePhoneNumber={(text) => this.updateTextInput(text, 'phone')}
                      value={phone}
                      disabled={this.state.disabled}
                      initialCountry={this.state.cca}
                      ref={(ref) => { this.phoneRef = ref; }} />
                  </View>
                }
                {this.state.currentUser && this.state.currentUser.email ? null :
                  <View style={styles.inputContainer}>
                    <TextInput
                      placeholder='Email'
                      ref={this.mailRef}
                      value={mail}
                      error={errors.mail}
                      keyboardType='email-address'
                      editable={!this.state.disabled}
                      style={[styles.inputs]}
                      onChangeText={(text) => this.updateTextInput(text, 'mail')}
                    />
                  </View>
                }

                <View style={{ marginTop: 10, borderTopColor: 'grey', borderTopWidth: 2 }}></View>
                {/* <View style={{ marginTop: 20 }}>
                  <Text style={{ fontSize: 20, fontWeight: 'bold', color: 'white' }}>Privacy Control</Text>
                  <Text style={{ fontStyle: 'italic', color: 'white' }}>Control what your sellers can see when you connect with them. When you place an order, your info is shared to them irrespective of what you choose here</Text>


                  <View style={{ marginTop: 15, color: 'white', alignSelf: 'stretch', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text style={{ fontSize: 16, color: 'white' }}>Share phone number</Text>
                    <Switch
                      onTintColor={Colors.gradStart}
                      trackColor={{ true: 'green', false: 'red' }}
                      thumbColor={'white'}
                      value={sharePhone}
                      disabled={this.state.disabled}
                      onValueChange={v => {
                        this.setState({ sharePhone: v });
                      }}
                    />
                  </View>

                  <View style={{ marginTop: 15, marginBottom: 10, color: 'white', alignSelf: 'stretch', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <Text style={{ fontSize: 16, color: 'white' }}>Share email address</Text>
                    <Switch
                      onTintColor={Colors.gradStart}
                      trackColor={{ true: 'green', false: 'red' }}
                      thumbColor={'white'}
                      value={shareEmail}
                      disabled={this.state.disabled}
                      onValueChange={v => {
                        this.setState({ shareEmail: v });
                      }}
                    />
                  </View>
                </View> */}


                <LinearGradient
                  colors={[Colors.gradStart, Colors.gradEnd]}
                  style={{ flex: 1 }}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                  style={styles.buttonContainer}
                >
                  <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }} onPress={() => this._updateAddress()}>
                    <Text style={{ color: 'white' }}>{this.state.disabled ? 'Edit Profile' : 'Save & Continue'}</Text>
                  </TouchableOpacity>
                </LinearGradient>

                <LinearGradient
                  colors={[Colors.red, Colors.red]}
                  style={{ flex: 1 }}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 0 }}
                  style={styles.buttonContainer}
                >
                  <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }} onPress={() => {
                    AsyncStorage.removeItem('pushToken').then(() => {
                      this.props.updateProfile({})
                      firebase.auth().signOut();
                    });
                  }}>
                    <Text style={{ color: 'white' }}>Sign Out</Text>
                  </TouchableOpacity>
                </LinearGradient>
              </View>
            </View>

            <PoweredBy />
          </ScrollView>

        </ImageBackground>
      )
    } else {
      return (
        <ImageBackground source={require('../assets/images/bg.jpg')} style={{ width: '100%', height: '100%' }}>
          <ScrollView style={styles.container}>
            <View style={styles.body}>

              <View style={styles.bodyContent}>
                <Text style={{ color: 'white', fontSize: 30, marginLeft: 10, marginRight: 10, textAlign: 'center' }}>Login with phone</Text>
                <TextInput
                  style={{ marginVertical: 10, fontSize: 17, width: 300 }}
                  label={restrictCountryCode ? 'Mobile Number' : 'Mobile number with + country code'}
                  placeholder={restrictCountryCode ? "99999 99999" : "+91 99999 99999"}
                  icon={'phone'}
                  value={phoneNumber}
                  autoFocus
                  autoCompleteType="tel"
                  keyboardType="phone-pad"
                  textContentType="telephoneNumber"
                  onChangeText={(text) => this.updateTextInput(text, 'phoneNumber')}
                />
                {restrictCountryCode ? null :
                  <Text style={{ color: 'white', textAlign: 'center' }}>Include the + symbol, country code and area code if any</Text>
                }
                {this.state.showConfirmOtp ? null :
                  <div ref={(ref) => this.recaptcha = ref}></div>}

                {this.state.showRequestOtp && !this.state.showConfirmOtp ?
                  <LinearGradient
                    colors={[Colors.gradStart, Colors.gradEnd]}
                    style={{ flex: 1 }}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    style={styles.buttonContainer}
                  >
                    <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}
                      onPress={async () => {
                        // The FirebaseRecaptchaVerifierModal ref implements the
                        // FirebaseAuthApplicationVerifier interface and can be
                        // passed directly to `verifyPhoneNumber`.
                        try {
                          const phoneProvider = new firebase.auth.PhoneAuthProvider();
                          const verificationId = await phoneProvider.verifyPhoneNumber(
                            (restrictCountryCode ? defaultCountryCode + phoneNumber : phoneNumber),
                            window.recaptchaVerifier
                          );
                          this.updateTextInput(verificationId, 'verificationId');
                          console.log('Verification code has been sent to your phone.',
                            this.setState({ showConfirmOtp: true })
                          );
                        } catch (err) {
                          console.log(`Error: ${err.message}`);
                          if (err.message == 'Invalid format.') {
                            if (restrictCountryCode) {
                              alert('Please check the phone number. The number should not include country code')
                            } else {
                              alert('Please check if the phone number includes the + country code and retry')
                            }

                          }
                        }
                      }}>
                      <Text style={{ color: 'white' }}>Request OTP</Text>

                    </TouchableOpacity>
                  </LinearGradient>
                  : null
                }
                {this.state.showConfirmOtp ?
                  <View>
                    <TextInput
                      style={{ marginVertical: 10, fontSize: 17 }}
                      label={'Enter One Time Pin'}
                      placeholder="000000"
                      icon={'phone'}
                      autoFocus
                      autoCompleteType="tel"
                      keyboardType="phone-pad"
                      textContentType="telephoneNumber"
                      onChangeText={(text) => this.updateTextInput(text, 'otp')}
                    />
                    <LinearGradient
                      colors={[Colors.gradStart, Colors.gradEnd]}
                      style={{ flex: 1 }}
                      start={{ x: 0, y: 0 }}
                      end={{ x: 1, y: 0 }}
                      style={styles.buttonContainer}
                    >
                      <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}
                        onPress={async () => {
                          try {
                            const credential = firebase.auth.PhoneAuthProvider.credential(
                              verificationId,
                              otp
                            );
                            await firebase.auth().signInWithCredential(credential);
                            this.setState({ logonStatus: true })
                          } catch (err) {
                            console.log('ver code ' + otp)
                            console.log(`Error: ${err.message}`);
                          }
                        }}>
                        <Text style={{ color: 'white' }}>Confirm OTP</Text>

                      </TouchableOpacity>
                    </LinearGradient>
                    <LinearGradient
                      colors={[Colors.red, Colors.red]}
                      style={{ flex: 1 }}
                      start={{ x: 0, y: 0 }}
                      end={{ x: 1, y: 0 }}
                      style={styles.buttonContainer}
                    >
                      <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 5, paddingRight: 5 }}
                        onPress={async () => {
                          this.setState({ showConfirmOtp: false, showRequestOtp: false }, () =>
                            this._renderRecaptcha())

                        }}>
                        <Text style={{ color: 'white' }}>Cancel or Retry</Text>

                      </TouchableOpacity>
                    </LinearGradient>
                  </View>
                  : null}
              </View>
            </View>
            <PoweredBy />
          </ScrollView>
        </ImageBackground>
      )
    }

  }

  updateRef(name, ref) {
    this[name] = ref;
  }

  _Profile() {
    return (
      <View style={styles.titleContainer}>
        <View style={styles.titleIconContainer}>
          <AppIconPreview iconUrl={this.state.currentUser ? this.state.currentUser.photoURL : ""} />
        </View>

        <View style={styles.titleTextContainer}>
          <Text style={styles.nameText} numberOfLines={1}>
            {//manifest.name
            }
          </Text>

          <Text style={styles.slugText} numberOfLines={1}>
            {this.state.currentUser ? this.state.currentUser.email : ""}
          </Text>

          <Text style={styles.descriptionText}>
            {manifest.description}
          </Text>
        </View>
      </View>
    );

  }

  ShowDialog() {
    this.setState({ Dialogvisible: true });
  }
  ShowColorDialog(Col) {
    this.setState({ ColorDialogvisible: true, ColorSelector: Col });
  }

  HideColorDialog() {
    this.setState({ ColorDialogvisible: false });
  }

  HideDialog() {
    this.setState({ Dialogvisible: false });
  }

  maybeRenderUploadingOverlay = () => {
    if (this.state.uploading) {
      return (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: 'rgba(0,0,0,0.4)',
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}>
          <ActivityIndicator color="#fff" animating size="large" />
        </View>
      );
    }
  }



  _selectColor = async (Color) => {
    this.HideColorDialog();
    try {
      if (this.state.ColorSelector == 1) {
        //await AsyncStorage.setItem('gradStartAsync', Color);
        this.setState({ gradStart: Color });
      } else {
        //await AsyncStorage.setItem('gradEndAsync', Color);
        this.setState({ gradEnd: Color });
      }

      console.warn('Updated');
    } catch (error) {
      Alert('Update Failed');
    }
  };

  _toggleAddressDisable() {
    if (this.state.disabled) {
      this.setState({ disabled: false });

      /*let {current: input } = this.storeNameRef;
      input.focus();*/

    } else {

      this._updateAddress();
    }
  }

  updateTextInput = (text, field) => {
    const state = this.state
    state[field] = text;
    this.setState(state);
  }

  async _updateAddress() {
    console.warn('Profile Update')
    let fcmToken = null
    if (this.state.name.toString().trim() != '') {
      fcmToken = await AsyncStorage.getItem('fcm')

      let profile_data = {
        name: this.state.name ? this.state.name : '',
        address: this.state.address ? this.state.address : '',
        city: this.state.city ? this.state.city : '',
        stat: this.state.stat ? this.state.stat : '',
        postal: this.state.postal ? this.state.postal : '',
        phone: this.state.phone ? this.state.phone : '',
        country: this.state.country ? this.state.country : '',
        countrycode: this.phoneRef ? this.phoneRef.getCountryCode() : this.state.countrycode ? this.state.countrycode : null,
        cca: this.phoneRef ? this.phoneRef.getISOCode() : this.state.cca ? this.state.cca : null,
        loginPhone: this.state.currentUser.phoneNumber,
        loginMail: this.state.currentUser.email,
        mail: this.state.mail ? this.state.mail : '',
        sharePhone: this.state.sharePhone ? this.state.sharePhone : null,
        shareEmail: this.state.shareEmail ? this.state.shareEmail : null,
        pushToken: this.state.pushToken,
        fcmToken: fcmToken
      }
      firebase.firestore().collection('buyerProfile').doc(this.state.currentUser.uid).set(profile_data).then(() => {
        this.setState({ showProfile: false, profileStatus: true });

      });
      profile_data.uid = this.state.currentUser.uid;
      this.props.updateProfile(profile_data)
      const customer_data = firebase.firestore().collection('mystore').doc(this.props.store.store.id).collection('customers');
      customer_data.doc(this.state.currentUser.uid).set({
        Name: profile_data.name ? profile_data.name : 'Guest Customer',
        Email: profile_data.mail ? profile_data.mail : null,
        loginMail: null,
        loginPhone: profile_data.loginPhone ? profile_data.loginPhone : null,
        Image: null,
        Mobile: profile_data.loginPhone ? profile_data.loginPhone : null,
        Address1: profile_data.address ? profile_data.address : null,
        Address2: '',
        City: profile_data.city ? profile_data.city : null,
        Stat: profile_data.stat ? profile_data.stat : null,
        Country: profile_data.country ? profile_data.country : null,
        Postal: profile_data.postal ? profile_data.postal : null,
        cca: null,
        countrycode: null,
        uid: this.state.currentUser.uid
      }).then(() => {
        console.log('updated')
        alert("Profile successfully updated");
        this.props.navigation.navigate('Home')
      })

    } else {
      alert('Name is Mandatory');
    }
  }

  _welcomeMessage() {

    return (
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <Avatar
          size="small"
          rounded
          source={{ uri: this.state.currentUser ? this.state.currentUser.photoURL : "" }}
          onPress={() => console.log("Works!")}
          activeOpacity={0.7}
        />
        <Text style={{ marginLeft: 5, marginTop: 5, fontSize: 20 }}>{this.state.currentUser ? this.state.currentUser.displayName : "Not Logged In"}</Text>


      </View>
    );

  }




  AppIconPreview = ({ iconUrl }) => {
    if (!iconUrl) {
      iconUrl =
        'https://s3.amazonaws.com/exp-brand-assets/ExponentEmptyManifest_192.png';
    }

    return (
      <Image
        source={{ uri: iconUrl }}
        style={{ width: 64, height: 64 }}
        resizeMode="cover"
      />
    );
  };
}

let { manifest } = Constants;

const styles = StyleSheet.create({
  header: {
    backgroundColor: "#ad1457",
    height: 200,
    flex: 1, alignItems: 'center', justifyContent: 'center'
  },
  container: {

    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  avatar: {
    width: 130,
    height: 130,
    borderRadius: 63,
    borderWidth: 4,
    borderColor: "white",
    alignSelf: 'center',
    position: 'absolute',
  },
  avatarTouch: {
    width: 130,
    height: 130,
    borderRadius: 63,
    alignSelf: 'center',
    position: 'absolute',
    marginTop: 130
  },
  body: {
    marginTop: 40,
  },
  bodyContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  name: {
    fontSize: 28,
    color: "#696969",
    fontWeight: "600"
  },
  info: {
    fontSize: 18,
    color: Colors.gradStart,
    marginTop: 10,
    marginBottom: 10,
    alignSelf: 'center'
  },
  description: {
    fontSize: 16,
    color: "#696969",
    marginTop: 10,
    textAlign: 'center'
  },
  buttonContainer: {
    marginTop: 10,
    height: 45,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
    width: 300,
    borderRadius: 10,
    backgroundColor: Colors.gradStart,
  }, colorSelector: {
    marginLeft: 20,
    marginTop: 10,
    height: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    borderRadius: 30,
    backgroundColor: Colors.gradStart,
  }, inputContainer: {
    //borderBottomColor: '#ffffff',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 5,
    alignSelf: 'center',
    //borderBottomWidth: 1,
    width: 300,
    height: 45,
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',

    shadowColor: "#808080",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  }, inputs: {
    height: 45,
    marginLeft: 16,
    borderBottomColor: '#FFFFFF',
    color: 'grey',
    flex: 1,
  }
});


const mapStateToProps = (state) => {
  const { store, profile_data } = state
  return { store, profile_data }
};
const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateStore, updateProfile
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);