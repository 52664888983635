import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/'), 'https://storemystore-storemystore.vercel.app'],
  config: {
    screens: {
      Root: {
      },
      Order:'Order',
      NotFound: '*',
    },
  },
};
