import { FontAwesome, Ionicons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { LinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import * as Permissions from 'expo-permissions';
//import * as Updates from 'expo-updates';
import firebase from 'firebase';
import 'firebase/firestore';
import "firebase/messaging";
import React from 'react';
import { ActivityIndicator, Clipboard, Dimensions, FlatList, Image, ImageBackground, PickerIOSItem, Text, TouchableOpacity, View } from 'react-native';
import { Card, FAB } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateProfile, updateStore } from '../../actions/stores';
import Shimmer from '../../components/Shimmer';
import { CLoading, PoweredBy } from '../../components/StyledText';
import Colors from '../../constants/Colors';
import Cstyles from '../../constants/Cstyles';
import { getCurrentLocation, getDeliveryDistance, getStoreInfo } from '../../helper';

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
const numColumns = screenWidth > screenHeight ? 8 : 2;
const tileSize = (screenWidth - (numColumns * 15)) / numColumns;
const ViewTypes = {
  FULL: 0,
  HALF_LEFT: 1,
  HALF_RIGHT: 2
};

let { width } = Dimensions.get("window");
const qrSize = width * 0.6
let containerCount = 0;
let deferredPrompt = null
let processingLoadMore = false
let lastItem = null


export class itemsList extends React.Component {

  constructor(args) {
    super(args);

    this.unsubscribe = null;
    let { width } = Dimensions.get("window");
    this.state = {
      isLoading: true,
      scrWidth: ((width * 0.90) / 3),
      currency: '',
      search: '',
      resultSnapshot: null,
      selectionMode: true,
      checked: true,
      scanned: false,
      scan: false,
      gradStart: Colors.gradStart,
      gradEnd: Colors.gradEnd,
      storeData: null,
      currentCategory: 'All Products',
      currentCategoryId: 'all',
      itemsList: [],
      loadingMore: true,
      storeId: '',
      deliveryNoteToggle: false,
      showCarousel: true,
      carousel: [],
      installable: false,
      allowAppInstall: true
    };
  }




  _onItemSelected(ItemId, ItemName) {
    this.props.navigation.navigate('ItemDetailsScreen', { categoryId: this.state.currentCategoryId, ItemId, ItemName, gradStart: this.state.gradStart, gradEnd: this.state.gradEnd, StoreData: this.state.storeData });

  }


  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;

    return {
      title: params.storeName,
      headerBackground: (
        <LinearGradient
          colors={[params.gradStart ? params.gradStart : Colors.gradStart, params.gradEnd ? params.gradEnd : Colors.gradEnd]}
          style={{ flex: 1 }}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
        />
      ),
      headerRight: (
        <View style={styles.iconContainer}>

          <TouchableOpacity style={{ padding: 10, marginRight: 10 }}
            onPress={navigation.getParam('searchEnable')}>
            <Ionicons name={Platform.OS === "ios" ? "ios-search" : "md-search"} size={20} color={'white'}
            /></TouchableOpacity>
          <TouchableOpacity style={{ padding: 10, marginRight: 10 }}
            onPress={navigation.getParam('scanBarCode')}>
            <Ionicons name={Platform.OS === "ios" ? "ios-barcode" : "md-barcode"} size={20} color={'white'}
            /></TouchableOpacity>

        </View>
      ),
      headerTitleStyle: { color: 'white' },
    };


  };



  state = {
    currentUser: null,
    Dialogvisible: false,
    NewCategoryDescription: '',
    NewCategory: '',
    image: null,
    name: "",
    categoriesList: [],
    itemsList: [],
    categoryName: '',
    categoryId: '',
    scrWidth: 100,
    empty: false,
    emptySearch: false
  };

  async componentDidMount() {

    if (this.getMobileOperatingSystem() == 'iOS' && !window.navigator.standalone) {
      this.setState({ installable: true })
    }



    // Web-Disabled
    // try {
    //   const update = await Updates.checkForUpdateAsync();

    //   if (update.isAvailable) {
    //     Alert.alert(
    //       'New update available!',
    //       'A new update is being downloaded. The application may restart once the update is complete but you can continue to use the app until then.',
    //       [

    //         { text: 'Got it', onPress: () => null },
    //       ]
    //     )
    //     await Updates.fetchUpdateAsync();
    //     await Updates.reloadAsync();
    //   }
    // } catch (e) {
    //   console.warn(e)
    // }
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      if (this.state.allowAppInstall) {
        this.setState({ installable: true });
      }

      // var r = confirm("Add us to your Home screen!!");
      // if (r) {
      //   this.handleInstallClick()
      // }
    });

    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      alert('App successfully installed. You can now shop with us through the App for a better experience')
    });




    console.log('Items List');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ currentUser: user, logonStatus: true });

        const fb_fs_profile = firebase.firestore().collection('buyerProfile').doc(user.uid);
        fb_fs_profile.get().then(snapshot => {
          if (snapshot.exists) {
            let profile_data = snapshot.data()
            profile_data.uid = snapshot.id;
            this.props.updateProfile(profile_data)
          }

        })
      }
    }
    )
    const { route } = this.props;
    Linking.getInitialURL().then(async (initialUrl) => {
      if (initialUrl) {
        let { path, queryParams } = Linking.parse(initialUrl);
        let s = null;
        //queryParams.s = 'XwzbwY92pXh31vzFUwQrDIM7gB62'

        if (queryParams && queryParams.s) {
          s = queryParams.s
          this.props.navigation.setParams({ s: s })
          //this._loadStore(s)
          let storeData = await getStoreInfo(s)
          this.setState({ carousel: storeData.carousel })
          if (queryParams.o) {
            this.props.updateStore(storeData);
            this._loadStore(storeData)
            this.props.navigation.navigate('OrderDetails2', { orderId: queryParams.o, storeId: s })
          } else {
            this._loadStore(storeData)
          }

          let pushToken;
          try {
            const messaging = firebase.messaging();
            messaging
              .getToken()
              .then((currentToken) => {
                if (currentToken) {
                  console.log("FCM token> ", currentToken);
                  pushToken = currentToken;
                  AsyncStorage.getItem('fcm').then(value => {
                    if (value == null || value != currentToken) {
                      firebase.firestore().collection('fcmTokens').doc(s).collection('tokens').add({ token: currentToken, status: true })
                      AsyncStorage.setItem('fcm', currentToken)
                      console.log('Got new FCM')
                    }
                  })
                } else {
                  console.log("No Token available");
                }
              })
              .catch((error) => {
                console.log("An error ocurred while retrieving token. ", error);
              });

            messaging.onMessage((payload) => {
              console.log("Message received. ", payload);
              const { title, ...options } = payload.notification;
              navigator.serviceWorkerContainer.register("firebase-messaging-sw.js");
              function showNotification() {

                Notification.requestPermission(function (result) {
                  if (result === "granted") {
                    navigator.serviceWorker.ready.then(function (registration) {
                      registration.showNotification(payload.notification.title, {
                        body: payload.notification.body,
                        tag: payload.notification.tag,
                      });
                    });
                  }
                });
              }

              showNotification();
              navigator.serviceWorker.ready.then(
                function (serviceWorkerRegistration) {
                  var options = {
                    userVisibleOnly: true,
                    applicationServerKey: applicationServerKey
                  };
                  serviceWorkerRegistration.pushManager.subscribe(options).then(
                    function (pushSubscription) {
                      console.log('endpoint')
                      console.log(pushSubscription.endpoint);
                      // The push subscription details needed by the application
                      // server are now available, and can be sent to it using,
                      // for example, an XMLHttpRequest.
                    }, function (error) {
                      // During development it often helps to log errors to the
                      // console. In a production environment it might make sense to
                      // also report information about errors back to the
                      // application server.
                      console.log(error);
                    }
                  );
                });

            });
          } catch (e) {
            console.log(e)
          }


        } else {
          this.setState({ storeName: 'Oops! The link seems to be broken' })
          this.props.navigation.navigate('Not Found')
        }
      }
    })

    //getStoreInfo('ohfy09rr1jQsWLK1S4LGAGpJ0Y62')
    //getStoreInfo('lDn2dPGxFNVvd79jFvsPawf565b2')


    //await Permissions.askAsync(Permissions.CAMERA);

    let StoreData = route.StoreData
    StoreData = StoreData ? StoreData : this.props.store.store;
    //this.props.navigation.setParams({ storeName: StoreData.storeName });
    this.setState({ storeData: StoreData, gradStart: StoreData.gradStart ? StoreData.gradStart : this.state.gradStart, gradEnd: StoreData.gradEnd ? StoreData.gradEnd : this.state.gradEnd });
    // if (StoreData.gradStart) {
    //   this.props.navigation.setParams({
    //     gradStart: StoreData.gradStart
    //   });
    // }
    // if (StoreData.gradEnd) {
    //   this.props.navigation.setParams({
    //     gradEnd: StoreData.gradEnd
    //   });
    // }
    const categoryId = route.categoryId
    this.setState({ categoryId });
    // firebase.auth().onAuthStateChanged(user => {
    //   if (user) {
    //     this.setState({ currentUser: user });
    //     this.updateStoreViews(StoreData.id)
    //     this.fetchCategories(StoreData.id)
    //     this.fetchData(StoreData.id, this.state.currentCategoryId)
    //   } 
    // });


  }


  handleInstallClick() {
    // Hide the app provided install promotion
    this.setState({ installable: false });
    // Show the install prompt
    if (this.getMobileOperatingSystem() == 'iOS') {
      alert(`
    1. Open Share menu on Safari browser
    2. Tap on "Add to Home Screen" button`);
    } else {
      try {
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
        });
      } catch (e) {
        alert('Oops! Seems your device does not suppport our App')
      }
    }



  };
  updateStoreViews(id) {

    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear().toString();
    if (dd < 10) {
      dd = '0' + dd.toString();
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    let dt = yyyy + mm.toString() + dd.toString();

    firebase.firestore().collection('views').doc(id).collection('storeViews').doc(dt).update({ view: firebase.firestore.FieldValue.increment(1) }).catch((err) => {
      

      console.log(err)
      console.log(id)
      console.log(dt)
      if (err.message.includes("No document to update") || err.message.includes('Requested entity was not found')) {
        
        firebase.firestore().collection('views').doc(id).collection('storeViews').doc(dt).set({ view: 1 })
      }
    }
    )

    firebase.firestore().collection('views').doc(id).collection('storeViews').doc('AllTime').update({ view: firebase.firestore.FieldValue.increment(1) }).catch((err) => {
      if (err.message.includes("No document to update") || err.message.includes('Requested entity was not found')) {
        firebase.firestore().collection('views').doc(id).collection('storeViews').doc('AllTime').set({ view: 1 })
      }
    }
    )

  }

  fetchCategories(id) {
    var categoriesList = [];
    categoriesList.push({ name: 'All Products', id: 'all' })
    firebase.firestore().collection('mystore').doc(id).collection('categories').orderBy('category', 'asc').get().then((catList) => {
      catList.forEach((catList) => {
        categoriesList.push({
          name: catList.data().category,
          id: catList.id,
          categoryImageURL: catList.data().categoryImageURL
        })
      })
      this.setState({ categoriesList })
      //console.warn(categoriesList.length)
    })
  }

  fetchData(id, currentCategoryId) {
    
    this.setState({ itemsList: [] })

    if (currentCategoryId == 'all') {
      this.items = firebase.firestore().collection('mystore').doc(id).collection('items').where('Active', '==', true).orderBy('Item', 'asc').limit(25);
    } else {
      this.items = firebase.firestore().collection('mystore').doc(id).collection('items').where("Category", "==", currentCategoryId).where('Active', '==', true).orderBy('Item', 'asc').limit(25);
    }

    this.unsubscribe = this.items.get().then((snapshot) => {
      this.onCollectionUpdate(snapshot)
    })
  }

  loadMoreData() {
    console.log('loadmore')
    if (this.lastItem) {
      if (this.state.currentCategoryId == 'all') {
        this.items = firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').where('Active', '==', true).orderBy('Item', 'asc').startAfter(this.lastItem).limit(25);

      } else {
        this.items = firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').where("Category", "==", this.state.currentCategoryId).where('Active', '==', true).orderBy('Item', 'asc').startAfter(this.lastItem).limit(25);
      }

      this.unsubscribe = this.items.get().then((snapshot) => {
        this.onCollectionUpdate(snapshot)
      })
    }
  }

  onCollectionUpdate = (async (querySnapshot) => {
    let itemsList = this.state.itemsList;
    let lastItem = null;
    await Promise.all(querySnapshot.docs.map(async doc => {
      lastItem = doc;
      const { Item, ItemDes, ItemImageURL, Category, Active, MRP, SellingPrice, ItemType } = doc.data();
      if (MRP || SellingPrice) {
        itemsList.push({
          id: doc.id,
          doc, // DocumentSnapshot
          Item,
          ItemDes,
          ItemImageURL,
          Category,
          SellingPrice: doc.data().SellingPrice ? doc.data().SellingPrice : doc.data().MRP,
          DiscountPrice: doc.data().DiscountPrice ? doc.data().DiscountPrice : 0,
          MRP: doc.data().MRP ? doc.data().MRP : 0,
          CostPrice: doc.data().CostPrice ? doc.data().CostPrice : 0,
          Stock: doc.data().Stock ? doc.data().Stock : 0,
          MinStock: doc.data().MinStock ? doc.data().MinStock : 0,
          Variants: 0,
          selected: false,
          active: Active ? true : false
        });
      } else {
        const ref = firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').doc(doc.id).collection('variants');
        await ref.get().then((docVariant) => {
          if (docVariant != null && docVariant.docs.length > 0) {
            itemsList.push({
              id: doc.id,
              doc, // DocumentSnapshot
              Item,
              ItemDes,
              ItemImageURL,
              Category,
              SellingPrice: docVariant.docs[0].data().SellingPrice,
              DiscountPrice: docVariant.docs[0].data().DiscountPrice,
              MRP: docVariant.docs[0].data().MRP,
              CostPrice: docVariant.docs[0].data().CostPrice,
              Stock: docVariant.docs[0].data().Stock,
              MinStock: docVariant.docs[0].data().MinStock,
              Variants: docVariant.size,
              selected: false,
              active: Active ? true : false,
              ItemType: ItemType === undefined ? 'Other' : doc.data().ItemType,
              FoodVegNv: doc.data().FoodVegNv ? doc.data().FoodVegNv : '',
              FoodSpicyLevel: doc.data().FoodSpicyLevel ? doc.data().FoodSpicyLevel : 0,
            });
          }
        })
      }
      
    }))
    this.setState({
      itemsList,
      lastItem: lastItem ? lastItem : this.lastItem,
      loadingMore: querySnapshot.docs.length < 25 ? false : true,
      processingLoadMore: false,
      origItemsList: itemsList,
      isLoading: false,
      switching: false,
      empty: itemsList.length > 0 ? false : true
    }, () => {
      this.processingLoadMore = false
      this.lastItem = lastItem ? lastItem : this.lastItem
    });


  });

  _renderCategoriesAndCarousel(color) {
    return (<View>
      {this.state.showCarousel && this.state.carousel.length > 0 ?
        <View>
          <FlatList horizontal={true}
            showsHorizontalScrollIndicator={true}
            initialScrollIndex={0}
            data={this.state.carousel}
            showsHorizontalScrollIndicator={false}
            renderItem={({ item }) =>

              <Image source={{ uri: item }} style={{ marginRight: 15, borderRadius: 20, width: screenWidth > screenHeight ? screenHeight : screenWidth * 0.8, height: screenWidth > screenHeight ? screenHeight * 0.4 : screenWidth * 0.5 }} />
            }
          />
        </View>

        : null}

      {/* Categories */}
      {
        <View >
          <FlatList horizontal={true} style={{ marginTop: 10 }}
            showsHorizontalScrollIndicator={true}
            initialScrollIndex={0}
            data={this.state.categoriesList}
            renderItem={({ item }) =>

              <View>
                <TouchableOpacity style={{ alignItems: "center", marginLeft: 10, width: 80 }} onPress={() => this._switchCategory(item.name, item.id)}>
                  <Image source={{ uri: item.categoryImageURL }} style={{ borderRadius: 23, borderColor: item.id == this.state.currentCategoryId ? this.state.gradStart : 'grey', borderWidth: item.id == this.state.currentCategoryId ? 4 : 2, width: 50, height: 50 }} />
                  <Text style={{ marginTop: 5, color: color, textAlign: 'center' }}
                  >{item.name}</Text>
                </TouchableOpacity>
              </View>
            }
            ItemSeparatorComponent={this.renderSeparator}
          />
        </View>
      }
    </View>)
  }
  _renderHeader() {
    if (this.state.storeData.deliveryNote) {
      return (
        <TouchableOpacity style={{ paddingHorizontal: 10, flexDirection: 'row', justifyContent: 'space-around', backgroundColor: Colors.grey, borderRadius: 3 }}
          onPress={() => this.setState({ deliveryNoteToggle: !this.state.deliveryNoteToggle })}>
          <Text numberOfLines={this.state.deliveryNoteToggle ? 0 : 2} ellipsizeMode='tail' style={{ color: 'white', textAlign: 'center' }}>{this.state.storeData.deliveryNote}</Text>
        </TouchableOpacity>)
    } else {
      return null
    }

  }
  render() {
    let storeData = this.props.store.store;
    if (this.state.isLoading) {
      return (<CLoading gradStart={this.state.gradStart} gradEnd={this.state.gradEnd}></CLoading>);
    } else {
      return (
        // this._theme1(storeData)
        this._defaultTheme(storeData)
      );
    }

  }

  _defaultTheme(storeData) {

    return (
      <View style={{ flex: 1, padding: 10 }}>
        {this.state.installable ?
          <View style={{ alignItems: 'center', marginBottom: 10 }}>
            <Shimmer content={'Install App'} gradStart={this.state.gradStart}
              gradEnd={this.state.gradEnd} width={250} height={50} icon={'download'}
              onPress={() => this.handleInstallClick()}
            />
          </View> : null
        }


        <View style={{ flex: 1 }}>
          {
            this._renderHeader()
          }




          <View>
            <FlatList style={{ marginTop: 10, height: this.state.installable ? (screenHeight - 200) : (screenHeight - 150) }}
              //onScroll={() => this.setState({ showCarousel: false })}
              initialScrollIndex={0}
              numColumns={numColumns}
              onEndReached={() => {
                if (!this.processingLoadMore) {
                  this.processingLoadMore = true
                  this.loadMoreData()

                }
              }
              }
              onEndReachedThreshold={0.5}
              data={this.state.itemsList}
              keyExtractor={(item, index) => item.id}
              ListHeaderComponent={this._renderCategoriesAndCarousel('grey')}
              renderItem={({ item }) =>

                <Card elevation={5} style={{ alignItems: "center", marginRight: 10, width: tileSize, marginBottom: 10 }}>
                  <TouchableOpacity style={{ flex: 1, alignItems: 'center' }} onPress={() => this._onItemSelected(item.id, item.Item)}>
                    <Image source={{ uri: item.ItemImageURL }} style={{ width: tileSize, height: tileSize, borderRadius: 5 }} />
                    <Text style={{ marginTop: 5, color: 'grey', textAlign: 'center' }}
                    >{item.Item}</Text>
                    <View style={{ flex: 1, flexDirection: 'row', padding: 5, alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={[Cstyles.sellingPrice, { color: this.state.gradStart }]}>{item.DiscountPrice > 0 ? this.state.Currency + item.DiscountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}{item.DiscountPrice <= 0 ? this.state.Currency + item.SellingPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>
                      {item.DiscountPrice > 0 ?
                        <Text style={Cstyles.mrp}>{item.MRP > 0 && item.MRP > item.DiscountPrice ? this.state.Currency + item.MRP.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>
                        :
                        <Text style={Cstyles.mrp}>{item.MRP > 0 && item.MRP > item.SellingPrice ? this.state.Currency + item.MRP.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>
                      }
                      {item.DiscountPrice > 0 ?
                        <Text style={Cstyles.off}>{item.MRP > item.DiscountPrice ? (((item.MRP - item.DiscountPrice) / item.MRP) * 100).toFixed(0).toString() + '% off' : null}</Text>
                        : <Text style={Cstyles.off}>{item.MRP > item.SellingPrice ? (((item.MRP - item.SellingPrice) / item.MRP) * 100).toFixed(0).toString() + '% off' : null}</Text>
                      }
                    </View>
                  </TouchableOpacity>
                </Card>
              }
              ItemSeparatorComponent={this.renderSeparator}
              ListFooterComponent={() => this.renderFooter()}
            />
          </View>
          {this.state.empty ?
            <View style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}>
              {/* <Image source={require('../../assets/images/notfoundgif.gif')} style={{ alignSelf: "center" }} />
 */}
              <Text style={{ fontWeight: 'bold', fontSize: 20, color: this.state.gradEnd, alignSelf: 'center', marginTop: 40 }}>Oops! No products yet!</Text>

            </View>
            : null}

        </View>
        {storeData.sharePhone && (storeData.loginPhone || storeData.phone) ? <FAB
          style={{
            position: 'absolute',
            margin: 16,
            right: 0,
            bottom: 0
          }}
          color={'white'}
          icon="whatsapp"
          theme={{ colors: { accent: '#25D366' } }}
          onPress={() => {

            let phoneNumber = storeData.sharePhone ? (storeData.loginPhone ? storeData.loginPhone : storeData.phone ? (storeData.countrycode ? storeData.countrycode : '') + '' + storeData.phone : null) : null;
            Linking.openURL('https://wa.me/' + phoneNumber);
          }}
        /> : null}


      </View>
    )
  }

  _theme1(storeData) {

    return (
      <View style={{ flex: 1, padding: 10 }}>
        {this.gradient}
        {this.state.installable ?
          <View style={{ alignItems: 'center', marginBottom: 10 }}>
            <Shimmer content={'Install App'} gradStart={this.state.gradStart}
              gradEnd={this.state.gradEnd} width={250} height={50} icon={'download'}
              onPress={() => this.handleInstallClick()}
            />
          </View> : null
        }


        <View style={{ flex: 1 }}>
          {
            this._renderHeader()
          }




          <View>
            <FlatList style={{ marginTop: 10, height: this.state.installable ? (screenHeight - 200) : (screenHeight - 150) }}
              //onScroll={() => this.setState({ showCarousel: false })}
              initialScrollIndex={0}
              numColumns={numColumns}
              onEndReached={() => {
                if (!this.processingLoadMore) {
                  this.processingLoadMore = true
                  this.loadMoreData()
                }
              }}
              onEndReachedThreshold={0.5}
              data={this.state.itemsList}
              keyExtractor={(item, index) => item.id}
              ListHeaderComponent={this._renderCategoriesAndCarousel('white')}
              renderItem={({ item }) =>

                <Card elevation={5} style={{ alignItems: "center", marginRight: 10, width: tileSize, marginBottom: 10, backgroundColor: 'rgba(255,255,255,0.3)' }}>
                  <TouchableOpacity style={{ flex: 1, alignItems: 'center' }} onPress={() => this._onItemSelected(item.id, item.Item)}>
                    <Image source={{ uri: item.ItemImageURL }} style={{ width: tileSize, height: tileSize, borderRadius: 5 }} />
                    <Text style={{ marginTop: 5, color: 'white', textAlign: 'center' }}
                    >{item.Item}</Text>
                    <View style={{ flex: 1, flexDirection: 'row', padding: 5, alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={[Cstyles.sellingPrice, { color: this.state.gradStart }]}>{item.DiscountPrice > 0 ? this.state.Currency + item.DiscountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}{item.DiscountPrice <= 0 ? this.state.Currency + item.SellingPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>
                      {item.DiscountPrice > 0 ?
                        <Text style={Cstyles.mrp}>{item.MRP > 0 && item.MRP > item.DiscountPrice ? this.state.Currency + item.MRP.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>
                        :
                        <Text style={Cstyles.mrp}>{item.MRP > 0 && item.MRP > item.SellingPrice ? this.state.Currency + item.MRP.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>
                      }
                      {item.DiscountPrice > 0 ?
                        <Text style={Cstyles.off}>{item.MRP > item.DiscountPrice ? (((item.MRP - item.DiscountPrice) / item.MRP) * 100).toFixed(0).toString() + '% off' : null}</Text>
                        : <Text style={Cstyles.off}>{item.MRP > item.SellingPrice ? (((item.MRP - item.SellingPrice) / item.MRP) * 100).toFixed(0).toString() + '% off' : null}</Text>
                      }
                    </View>
                  </TouchableOpacity>
                </Card>
              }
              ItemSeparatorComponent={this.renderSeparator}
              ListFooterComponent={() => this.renderFooter()}
            />
          </View>
          {this.state.empty ?
            <View style={{ alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center' }}>
              {/* <Image source={require('../../assets/images/notfoundgif.gif')} style={{ alignSelf: "center" }} />
 */}
              <Text style={{ fontWeight: 'bold', fontSize: 20, color: this.state.gradEnd, alignSelf: 'center', marginTop: 40 }}>Oops! No products yet!</Text>

            </View>
            : null}

        </View>
        {storeData.sharePhone && (storeData.loginPhone || storeData.phone) ? <FAB
          style={{
            position: 'absolute',
            margin: 16,
            right: 0,
            bottom: 0
          }}
          color={'white'}
          icon="whatsapp"
          theme={{ colors: { accent: '#25D366' } }}
          onPress={() => {

            let phoneNumber = storeData.sharePhone ? (storeData.loginPhone ? storeData.loginPhone : storeData.phone ? (storeData.countrycode ? storeData.countrycode : '') + '' + storeData.phone : null) : null;
            Linking.openURL('https://wa.me/' + phoneNumber);
          }}
        /> : null}


      </View>
    )
  }
  handleAddToHomescreenClick = () => {
    alert(`
      1. Open Share menu
      2. Tap on "Add to Home Screen" button`);
  };

  renderFooter() {

    return (this.state.emptySearch ? null : ((this.state.loadingMore || this.state.switching) ? <ActivityIndicator size="large" color={this.state.gradStart} /> :
      <View style={{ alignItems: 'center', alignSelf: 'stretch', padding: 20 }}>
        <Text style={{ fontSize: 15 }}>Thats all!</Text>
        <PoweredBy color={'grey'} />
      </View>

    ))
  }

  get gradient() {
    return (
      <LinearGradient
        colors={[this.state.gradStart, this.state.gradEnd]}
        startPoint={{ x: 1, y: 0 }}
        endPoint={{ x: 0, y: 1 }}
        style={Cstyles.gradient}
      />
    );
  }

  _switchCategory(name, id) {
    if (this.state.switching) {

    } else {
      this.setState({ currentCategory: name, currentCategoryId: id, switching: true, lastItem: null }, () => this.fetchData(this.state.storeData.id, id))
    }

  }

  searchEnable() {
    this.props.navigation.navigate('SearchItemsScreen')
  }
  exit() {
    console.warn('exit')
    this.props.navigation.goBack()
  }

  async scanBarCode() {
    await Permissions.getAsync(
      Permissions.CAMERA,
    ).then((status) => {
      if (status.permissions.camera.status == 'denied') {
        alert('Unable to process as the Permissions for access to Camera was not granted');
      } else {
        this.props.navigation.navigate('SearchItemsScreen', { Scan: true })

      }
    });
  }

  _maybeRenderImage = (image, ItemType, FoodSpicyLevel, FoodVegNv) => {
    //let { image } = this.state;
    if (!image) {
      return;
    }

    return (
      <View
        style={{
          borderRadius: 3,
        }}>
        <View
          style={{
            borderRadius: 4,
            overflow: 'hidden',
          }}>
          <ImageBackground source={{ uri: image }} style={{ width: (this.state.scrWidth - 16), height: (this.state.scrWidth - 16), justifyContent: 'space-between', flexDirection: 'row' }} >
            {ItemType == 'Food' && FoodVegNv ? FoodVegNv == 'Veg' ? <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/veg.png')} />
              : FoodVegNv == 'Non-Veg' ? <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/nonveg.png')} />
                : <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/egg.png')} />
              : <Text></Text>}

            {ItemType == 'Food' && FoodSpicyLevel ?
              FoodSpicyLevel == 2 ?
                <View style={{ flexDirection: 'row' }}>
                  <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                  <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                </View> : FoodSpicyLevel == 3 ?
                  <View style={{ flexDirection: 'row' }}>
                    <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                    <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                    <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                  </View>
                  : FoodSpicyLevel == 4 ?
                    <View style={{ flexDirection: 'row' }}>
                      <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                      <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                      <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                      <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                    </View> : <Text></Text> : <Text></Text>}


          </ImageBackground>
        </View>

      </View>
    );
  }

  _loadStore(storeData) {
    let LocationRequired = false;
    if (storeData) {

      this.setState({ storeData, isLoading: false, gradStart: storeData.gradStart, gradEnd: storeData.gradEnd, Currency: storeData.Currency }, () => {
        if (storeData.restrictOrders) {
          LocationRequired = true;
          this._getLocation(storeData.location, (storeData.orderDistance ? storeData.orderDistance : 0))
        }
      })
      this.props.updateStore(storeData);
      this.props.navigation.setOptions({
        headerTitle: () => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image source={{ uri: storeData.image }} style={{ marginRight: 10, borderRadius: 23, borderColor: 'orange', borderWidth: 3, width: 40, height: 40 }} />
            <Text style={{ color: 'white', fontSize: 20 }}>{storeData.storeName}</Text>
          </View>
        ),
        headerRight: () => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {storeData.storeLinkToShare ? <TouchableOpacity style={{ padding: 10, marginRight: 10 }}
              onPress={() => {
                Clipboard.setString((storeData.storeLinkToShare))
                alert('Link copied to Clipboard. You can paste it anywhere');
              }}>
              <FontAwesome name="share-alt" size={25} color={'white'} style={{ marginLeft: 10 }} /></TouchableOpacity> : null}
          </View>

        ),
        headerStyle: {
          backgroundColor: storeData.gradStart, //Set Header color
        },
        headerTintColor: 'white'
      });
      this.updateStoreViews(storeData.id)
      this.fetchCategories(storeData.id)
      this.fetchData(storeData.id, this.state.currentCategoryId)
    } else {
      this.setState({ storeName: 'Oops! The link seems to be broken' })
      this.props.navigation.navigate('Not Found')
    }
  }

  async _getLocation(storeLocation, orderDistance) {
    let storeData = this.state.storeData

    let location = await getCurrentLocation();
    console.log('location')
    console.log(location)
    if (location) {
      console.log(storeLocation)
      console.log(location)
      console.log(orderDistance)
      storeData.serviceable = this._getServiceability(storeLocation, location, orderDistance)
      storeData.currentLocation = location;
      this.setState({ storeData })
      this.props.updateStore(storeData);
      this.setState({ location: location, allowOrders: true })
    }

  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  _getServiceability = (storeLocation, shopperLocation, orderDistance) => {

    var dis = getDeliveryDistance(storeLocation, shopperLocation)
    console.log(dis)
    return (dis <= (orderDistance * 1000))
  };
}

const styles = {
  container: {
    justifyContent: "space-around",
    alignItems: "center",
    flex: 1,
    backgroundColor: "#00a1f1"

  },
  containerGridLeft: {
    justifyContent: "space-around",
    alignItems: "center",
    flex: 1,
    backgroundColor: "#ffffff",
    marginTop: 5,
    marginLeft: 5

  },
  containerGridFull: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1,
    flexDirection: 'row',
    backgroundColor: "transparent",
    marginTop: 5,
    marginLeft: 5

  }, subtitle: {
    backgroundColor: 'transparent',
    color: 'grey',
    fontSize: 13,
    textAlign: 'left'
  },
  containerGridRight: {
    justifyContent: "space-around",
    alignItems: "center",
    flex: 1,
    backgroundColor: "#ffffff",
    marginTop: 5,
    marginLeft: 5,
    marginRight: 5
  }, qr: {
    marginTop: '10%',
    marginBottom: '20%',
    width: qrSize,
    height: qrSize,
  }, description: {
    fontSize: width * 0.05,
    marginTop: '10%',
    textAlign: 'center',
    width: '70%',
    color: 'white',
  },
  cancel: {
    fontSize: width * 0.05,
    textAlign: 'center',
    width: '70%',
    color: 'white',
    marginBottom: 25
  },
  iconContainer: {
    flexDirection: "row",
    justifyContent: 'flex-end',
    width: 320
  }
};

const mapStateToProps = (state) => {
  const { store, profile_data } = state
  return { store, profile_data }
};
const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateStore, updateProfile
  }, dispatch)
);
export default connect(mapStateToProps, mapDispatchToProps)(itemsList);