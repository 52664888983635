import { PROFILE_DATA } from '../constants';
const initialState = {
    profile_data: { name: 'xxx' }
};
const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_DATA:
            return {
                ...state,
                profile_data: action.payload
            };
        default:
            return state;
    }
}
export default profileReducer;