import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { Animated, Dimensions, Easing, StyleSheet, View, TouchableOpacity, Text } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
const styles = StyleSheet.create({
    shimmer: {
        overflow: 'hidden', borderRadius: 5, elevation: 5, // Android
    },
});

const SCREEN_WIDTH = Dimensions.get('screen').width;
const START = -1;
const END = 1;
const DURATION = 2000;
const COLORS = ['#eee', '#ddd', '#eee'];
const LOCATIONS = [0.3, 0.5, 0.7];
const ANIMATION = new Animated.Value(START);

const runAnimation = () => {
    ANIMATION.setValue(START);
    Animated.timing(ANIMATION, {
        toValue: END,
        duration: DURATION,
        easing: Easing.linear(),
        useNativeDriver: true,
    }).start(runAnimation);
};

const linear = ANIMATION.interpolate({
    inputRange: [START, END],
    outputRange: [-SCREEN_WIDTH, SCREEN_WIDTH],
});

runAnimation();


export default function Shimmer(props) {
    const [positionX, setPositionX] = useState(null);
    let viewRef = null;
    let width = props.width, height = props.height
    return (
        <TouchableOpacity
            style={[styles.shimmer, { width, height, backgroundColor: props.gradStart }]}
            ref={ref => (viewRef = ref)}
            onLayout={() => {
                if (viewRef) {
                    viewRef.measure((_x, _y, _width, _height, pageX, _pageY) => {
                        setPositionX(pageX);
                    });
                }
            }}
            onPress={props.onPress}
        >
            {positionX !== null && (
                <Animated.View
                    style={{
                        flex: 1,
                        left: -positionX,
                        transform: [{ translateX: linear }],
                    }}>
                    <LinearGradient
                        style={{ flex: 1, width: SCREEN_WIDTH }}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 1, y: 0 }}
                        locations={LOCATIONS}
                        colors={[props.gradStart, props.gradEnd, props.gradStart]}
                    >

                    </LinearGradient>
                </Animated.View>
            )}
            <View style={{ position: 'absolute', height, width, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                <Text style={{ fontSize: 20, color: 'white' }} >{props.content}</Text>
                {props.icon ?
                    <FontAwesome name={props.icon} size={20} color={'white'} style={{ marginLeft: 10 }}></FontAwesome> :
                    null
                }

            </View>

        </TouchableOpacity>
    );
}

