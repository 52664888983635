import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Alert, Animated, Dimensions, Image, Linking, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import CardSection from './CardSection';
import { LinearGradient } from 'expo-linear-gradient';
import { ActivityIndicator } from 'react-native-paper';


let error = ''
export default function CheckoutForm(props) {
    const [processing, setProcessing] = useState(0);
    const stripe = useStripe();
    const elements = useElements();
    console.log(props)
    const handleSubmit = async (event) => {
        setProcessing(true)
        error = ''
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmCardPayment(props.clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: 'Store My Store',
                },
            }
        });

        if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            console.log(result.error.message);
            error = result.error.message
            setProcessing(false)
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                // Show a success message to your customer
                // There's a risk of the customer closing the window before callback
                // execution. Set up a webhook or plugin to listen for the
                // payment_intent.succeeded event that handles any business critical
                // post-payment actions.
                console.log(result.paymentIntent)
                props.onSuccess(props.context, result.paymentIntent)
                setProcessing(false)
            }
        }
    };

    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <form onSubmit={handleSubmit}>
                <CardSection />
                {/* <button style={{}} disabled={!stripe}>Make Payment</button> */}
                <View style={{ alignItems: 'center' }}>
                    {processing ?
                        <ActivityIndicator size={'large'} color={'#1e88e5'} />
                        :
                        <LinearGradient
                            colors={['#1e88e5', '#1565c0']}
                            start={{ x: 0, y: 0 }}
                            end={{ x: 1, y: 0 }}
                            style={{
                                marginHorizontal: 20, maxWidth: 200,
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 5,
                                marginTop: 10
                            }}
                        >

                            <TouchableOpacity style={{ height: 40, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, alignSelf: 'stretch' }} onPress={handleSubmit}>
                                <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>{'Pay ' + props.amount}</Text>
                            </TouchableOpacity></LinearGradient>
                    }
                    <Text style={{ textAlign: 'center', color: 'red', marginTop: 10, padding: 10 }}>{error}</Text>

                    <Image source={require('../assets/images/poweredbyStripe.png')} style={{ resizeMode: 'contain', width: 100, height: 33, alignSelf: "center", marginTop: 5 }} />

                </View>

            </form>
        </View>
    );
}