import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Cstyles from '../constants/Cstyles';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

export default function NotFoundScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'NotFound'>) {
  return (
    <View style={styles.container}>
      <Text style={[styles.title,{textAlign:'center'}]}>Oops. The link seems to be broken. The screen doesn't exist.</Text>
      <TouchableOpacity onPress={() => Linking.openURL('mailto: support@stomremystore.com')}>
        <Text style={{textAlign:'center'}}>Click here to reach out to us on support@storemystore.com if you need assistance</Text>
      </TouchableOpacity>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});
