import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ColorSchemeName, View } from 'react-native';
import { Badge } from 'react-native-paper';
import { connect, useSelector } from 'react-redux';
import Cart from '../screens/Cart';
import itemDetails from '../screens/Inventory/itemDetails';
import itemsList from '../screens/Inventory/itemsList';
import NotFoundScreen from '../screens/NotFoundScreen';
import OrderDetails from '../screens/OrderDetails';
import Orders from '../screens/Orders';
import Payment from '../screens/Payment';
import ProfileScreen from '../screens/ProfileScreen';
import { RootStackParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import { profileReducer } from '../reducers/profileReducer'

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();
const Drawer = createDrawerNavigator();
const Tab = createBottomTabNavigator();


function HomeStack() {
  return (
    <Stack.Navigator screenOptions={{
      headerShown: true
    }}>
      <Stack.Screen name="Home" component={itemsList} />
      <Stack.Screen name="ItemDetailsScreen" component={itemDetails} options={{ title: 'Product Details' }} />
      <Stack.Screen name="OrderDetails2" component={OrderDetails} options={{ title: 'Order Details' }} />
      <Stack.Screen name="PaymentScreen2" component={Payment} options={{ title: 'Payment' }} />
    </Stack.Navigator>
  );
}

function CartStack() {
  return (
    <Stack.Navigator screenOptions={{
      headerShown: true
    }}>
      <Stack.Screen name="Cart" component={Cart} />
      <Stack.Screen name="PaymentScreen" component={Payment} options={{ title: 'Payment' }} />
      <Stack.Screen name="ItemDetailsScreen" component={itemDetails} options={{ title: 'Product Details' }} />
    </Stack.Navigator>
  );
}

function OrdersStack() {
  return (
    <Stack.Navigator screenOptions={{
      headerShown: true
    }}>
      <Stack.Screen name="Orders" component={Orders} />
      <Stack.Screen name="OrderDetails" component={OrderDetails} />
      <Stack.Screen name="PaymentScreen2" component={Payment} options={{ title: 'Payment' }} />
    </Stack.Navigator>
  );
}

function OrderOnlyStack() {
  return (
    <Stack.Navigator screenOptions={{
      headerShown: true
    }}>
      <Stack.Screen name="OrderDetails" component={OrderDetails} />
      <Stack.Screen name="PaymentScreen2" component={Payment} options={{ title: 'Payment' }} />
    </Stack.Navigator>
  );
}

const tabs = ({ profileReducer }) => {
  let profile_data = useSelector(state => state.profile_data);

  return (
    <Tab.Navigator>
      <Tab.Screen name="Home" component={HomeStack} options={{
        tabBarLabel: 'Home',
        tabBarIcon: ({ color, size }) => (
          <FontAwesome name="home" color={color} size={size} />
        ),
      }} />
      <Tab.Screen name="Cart" component={CartStack} options={{
        tabBarLabel: 'Cart',
        tabBarBadge: profile_data.profile_data.cartCount > 0 ? '' : undefined,
        tabBarIcon: ({ color, size }) => (

          <FontAwesome name="shopping-cart" color={color} size={size} />
        ),
      }} />
      <Tab.Screen name="Orders" component={OrdersStack} options={{
        tabBarLabel: 'Orders',
        tabBarIcon: ({ color, size }) => (
          <FontAwesome name="list-ul" color={color} size={size} />
        ),
      }} />
      <Tab.Screen name="Profile" component={ProfileScreen} options={{
        tabBarLabel: 'Profile',
        tabBarIcon: ({ color, size }) => (
          <FontAwesome name="user" color={color} size={size} />
        ),
      }} />
    </Tab.Navigator>
  )
}
function RootNavigator() {
  return (
    <Stack.Navigator name="Home" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Home" component={tabs} />
      <Stack.Screen name="Not Found" component={NotFoundScreen} />
      <Stack.Screen name="Order" component={OrderOnlyStack} />
    </Stack.Navigator>
  );
}

const mapStateToProps = (state) => {
  const { store, profile_data } = state
  return { store, profile_data }
};

export default connect(mapStateToProps)(Navigation);