import { FontAwesome } from '@expo/vector-icons';
import * as React from 'react';
import { ActivityIndicator, TouchableOpacity, View, Image } from 'react-native';
import { Text, TextProps } from './Themed';
import Colors from '../constants/Colors';

export function MonoText(props: TextProps) {
  return <Text {...props} style={[props.style]} />;
}

export function PoweredBy(props: ViewProps) {
  return (<TouchableOpacity onPress={() => window.open('https://www.storemystore.com/business', '_blank')} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginVertical: 5, backgroundColor: null }}>
    <Image source={require('../assets/images/icon.png')} style={{ width: 25, height: 25, marginRight: 10 }} />
    <Text style={{ color: props.color ? props.color : 'white' }}>Powered by Store My Store</Text>
  </TouchableOpacity>)
}


export function CNumericCounter(props: ViewProps) {

  let buttonSize = 18;
  let textSize = 15;
  let textColor = 'white';
  if (props.size == 'large') {
    buttonSize = 35;
    textSize = 30;
  }
  if (props.theme == 'light') {
    textColor = 'black'
  }
  return (<View {...props} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
    <TouchableOpacity onPress={props.onDecrease}>
      <FontAwesome name='minus-square' size={buttonSize} color={Colors.gradStart} />
    </TouchableOpacity>
    <Text style={{ textAlign: 'center', color: textColor, marginHorizontal: 5, fontSize: textSize }}>{props.value}</Text>
    <TouchableOpacity onPress={props.onIncrease}>
      <FontAwesome name='plus-square' size={buttonSize} color={Colors.gradEnd} />
    </TouchableOpacity >
  </View>)

}
export class CLoading extends React.Component {
  render() {
    return (<View {...this.props} style={{ flex: 1, backgroundColor: '#ffffff', justifyContent: 'center' }}>
      <ActivityIndicator size="large" color={this.props.gradEnd} />
      <Text style={{ fontFamily: 'Croisant', textAlign: 'center', fontWeight: 'bold', fontSize: 30, alignSelf: 'center', color: this.props.gradStart }}>{this.props.message ? this.props.message : 'Loading...'}</Text>
    </View>)
  }
}
