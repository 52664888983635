import {
  StyleSheet
} from 'react-native';
import Colors from './Colors';

export default {
    container: {
      flex: 1,
      backgroundColor: Colors.background,
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
    },
    sellingPrice: {
      backgroundColor: 'transparent',
      color: Colors.gradStart,
      fontSize: 15,
      fontWeight: 'bold',
    },
    mrp: {
      marginLeft: 5,
      backgroundColor: 'transparent',
      color: 'grey',
      fontSize: 12,
      fontStyle: 'italic',
      textDecorationLine: 'line-through',
      textDecorationStyle: 'solid'
    },
    off: {
      marginLeft: 5,
      backgroundColor: 'transparent',
      color: 'green',
      fontSize: 12,
      fontStyle: 'italic',
    },
    gradient: {
      ...StyleSheet.absoluteFillObject
    },
}