import { Ionicons, FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import * as Sharing from 'expo-sharing';
import firebase from 'firebase';
import { Card } from 'react-native-paper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateProfile, updateStore } from '../../actions/stores';
import React from 'react';
import {
  ActivityIndicator,
  Dimensions, Image,
  KeyboardAvoidingView, Platform, SafeAreaView, ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  Text, FlatList
} from 'react-native';
import ViewMoreText from 'react-native-view-more-text';
//import { captureRef as takeSnapshotAsync } from 'react-native-view-shot';
//import SliderEntry from '../../components/SliderEntry';
import { CLoading, CNumericCounter, PoweredBy } from '../../components/StyledText';
import Colors from '../../constants/Colors';
import Cstyles from '../../constants/Cstyles';
import { updateCartBadge } from '../../helper';

const { width } = Dimensions.get('window')
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
const qrSize = width * 0.6
const SLIDER_1_FIRST_ITEM = 1;
var that;
const { width: viewportWidth, height: viewportHeight } = Dimensions.get('window');


function wp(percentage) {
  const value = (percentage * viewportWidth) / 100;
  return Math.round(value);
}

const slideHeight = viewportHeight * 0.36;
const slideWidth = wp(75);
const itemHorizontalMargin = wp(2);
const sliderWidth = viewportWidth;
const itemWidth = slideWidth + itemHorizontalMargin * 2;
let screenShot = null;
const entryBorderRadius = 8;



export class ItemDetails extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const { params = {} } = navigation.state;
    if (params.StoreData.sharePhone) {
      return {
        title: `${navigation.state.params.ItemName}`,
        headerBackground: (
          <LinearGradient
            colors={[params.gradStart ? params.gradStart : Colors.gradStart, params.gradEnd ? params.gradEnd : Colors.gradEnd]}
            style={{ flex: 1 }}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
          />
        ),
        headerRight: (
          <View style={styles.iconContainer}>
            <TouchableOpacity style={{ padding: 10, marginRight: 10 }}
              onPress={navigation.getParam('storeInquiry')}>
              <Ionicons name={Platform.OS === "ios" ? "logo-whatsapp" : "logo-whatsapp"} size={20} color={'white'}
              /></TouchableOpacity>
            <TouchableOpacity style={{ padding: 10, marginRight: 10 }}
              onPress={navigation.getParam('shareItem')}>
              <Ionicons name={Platform.OS === "ios" ? "ios-share" : "md-share"} size={20} color={'white'}
              /></TouchableOpacity>

          </View>
        ),
        headerTitleStyle: { color: 'white' },
        headerTintColor: 'white',
      };
    } else {
      return {
        title: `${navigation.state.params.ItemName}`,
        headerBackground: (
          <LinearGradient
            colors={[params.gradStart ? params.gradStart : Colors.gradStart, params.gradEnd ? params.gradEnd : Colors.gradEnd]}
            style={{ flex: 1 }}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
          />
        ),
        headerRight: (
          <View style={styles.iconContainer}>
            <TouchableOpacity style={{ padding: 10, marginRight: 10 }}
              onPress={navigation.getParam('shareItem')}>
              <Ionicons name={Platform.OS === "ios" ? "ios-share" : "md-share"} size={20} color={'white'}
              /></TouchableOpacity>

          </View>
        ),
        headerTitleStyle: { color: '#fff', fontFamily: 'Text', },
      };
    }

  };
  state = {
    currentUser: null,
    page: 0,
    key: null,
    isLoading: true,
    name: '',
    image: null,
    errors: {},
    uploading: false,
    Dialogvisible: false,
    imgDialogvisible: false,
    imgToBeUpl: 1,
    CategoryDescription: '',
    CategoryName: '',
    CategoryId: '',
    ItemId: '',
    pageValidation: false,
    errorsExist: false,
    slider1ActiveSlide: SLIDER_1_FIRST_ITEM,
    itemName: '',
    itemDescription: '',
    origitemName: '',
    origItemCode: '',
    origitemDescription: '',
    Size: '',
    Color: '',
    Material: '',
    Packing: '',
    Other: '',
    costPrice: 0,
    sellingPrice: 0,
    mrp: 0,
    discountPrice: 0,
    shipping: 'None',
    variantsArray: [],
    VarSize: false,
    VarColor: false,
    VarMaterial: false,
    VarPacking: false,
    VarOther: false,
    selectedVariant: 0,
    uniqueSize: [],
    uniqueColor: [],
    uniqueMaterial: [],
    uniquePacking: [],
    uniqueOther: [],
    variantImages: [],
    currency: '',
    editItemName: false,
    Dialogvisible: false,
    Stock: 0,
    minStock: 0,
    origStock: 0,
    origminStock: 0,
    origcostPrice: 0,
    origsellingPrice: 0,
    origmrp: 0,
    origdiscountPrice: 0,
    updateStock: false,
    updatePrice: false,
    variantIndex: 1,
    stockUpdStatus: '',
    priceUpdStatus: '',
    active: true,
    addedToCart: false,
    addingToCart: false,
    ItemCode: '',
    scanned: false,
    scan: false,
    dismissDialogScan: false,
    Updating: false,
    Sharing: false,
    gradStart: Colors.gradStart,
    gradEnd: Colors.gradEnd
  };

  async componentDidMount() {
    console.log('Item Details');
    const { route } = this.props;
    let { categoryId, ItemId, ItemName } = route.params
    let profile_data = this.props.profile_data.profile_data;
    let StoreData = this.props.store.store;
    this.props.navigation.setOptions({
      headerStyle: {
        backgroundColor: StoreData.gradStart, //Set Header color
      },
      headerTintColor: 'white'
    })
    this.props.navigation.setOptions({ title: ItemName });

    this.setState({ CategoryId: categoryId, ItemId });


    //console.warn(StoreData.serviceable)
    this.setState({ profile_data, storeData: StoreData, gradStart: StoreData.gradStart ? StoreData.gradStart : this.state.gradStart, gradEnd: StoreData.gradEnd ? StoreData.gradEnd : this.state.gradEnd });
    //this.props.navigation.setParams({ enableWhatsapp: StoreData.sharePhone });
    that = this;
    //this.props.navigation.setParams({ shareItem: this._shareItem.bind(this) });
    //this.props.navigation.setParams({ storeInquiry: this._storeInquiry.bind(this) });
    this.onFocus = this.onFocus.bind(this);
    this.ItemNameRef = this.updateRef.bind(this, 'itemName');
    this.ItemDescriptionRef = this.updateRef.bind(this, 'itemDescription');
    this.ItemCodeRef = this.updateRef.bind(this, 'ItemCode');
    this.StockRef = this.updateRef.bind(this, 'Stock');
    this.minStockRef = this.updateRef.bind(this, 'minStock');
    this.costPriceRef = this.updateRef.bind(this, 'costPrice');
    this.sellingPriceRef = this.updateRef.bind(this, 'sellingPrice');
    this.mrpRef = this.updateRef.bind(this, 'mrp');
    this.discountPriceRef = this.updateRef.bind(this, 'discountPrice');

    this.fetchData(StoreData.id, ItemId);
    this.didFocusListener = this.props.navigation.addListener(
      'focus',
      () => {
        this.fetchData(StoreData.id, ItemId);
      },
    );

  }

  fetchData(id, ItemId) {
    this.setState({
      isLoading: true, addedToCart: false
    });
    const itemRef = firebase.firestore().collection('mystore').doc(id).collection('items').doc(ItemId);
    itemRef.get().then((doc) => {
      if (doc.exists) {
        const item_data = doc.data();
        this.setState({
          key: doc.id,
          itemName: item_data.Item,
          itemDescription: item_data.ItemDes,
          ItemCode: item_data.ItemCode,
          VarSize: item_data.Size,
          VarColor: item_data.Color,
          VarMaterial: item_data.Material,
          VarPacking: item_data.Packing,
          VarOther: item_data.Other,
          ItemType: doc.data().ItemType === undefined ? 'Other' : doc.data().ItemType,
          FoodVegNv: doc.data().FoodVegNv ? doc.data().FoodVegNv : '',
          FoodSpicyLevel: doc.data().FoodSpicyLevel ? doc.data().FoodSpicyLevel : 0,

        });
        //this.props.navigation.setParams({ ItemName: item_data.Item });
        if (item_data.MRP || item_data.SellingPrice) {
          let variantsArray = []
          if (that.props.profile_data.profile_data && that.props.profile_data.profile_data.uid) {

            firebase.firestore().collection('buyer').doc(that.props.profile_data.profile_data.uid).collection('stores').doc(id).collection('cart').doc(ItemId + '/variants/0').get()
              .then((cartVarDoc) => {
                let cartQty = 0;
                if (cartVarDoc.exists) {
                  cartQty = cartVarDoc.data().qty
                }
                let newlyAddedValue = {
                  index: 0, Size: false, Color: false, Material: false, Packing: false, Other: false, costPrice: item_data.CostPrice ? item_data.CostPrice : 0, sellingPrice: item_data.SellingPrice ? item_data.SellingPrice : item_data.MRP, mrp: item_data.MRP, discountPrice: item_data.DiscountPrice ? item_data.DiscountPrice : 0, stock: item_data.Stock ? item_data.Stock : 0, minStock: item_data.MinStock ? item_data.MinStock : 0
                  , image: item_data.ItemImageURL, image2: null, image3: null, cartQty
                };
                variantsArray.push(newlyAddedValue);
                that.setState({ variantsArray, variantImages: [{ title: "1", illustration: item_data.ItemImageURL, ref: that }] }, () => that.setCurrentVariantsByDoc(0));
              })
          } else {
            let cartQty = 0;
            let newlyAddedValue = {
              index: 0, Size: false, Color: false, Material: false, Packing: false, Other: false, costPrice: item_data.CostPrice ? item_data.CostPrice : 0, sellingPrice: item_data.SellingPrice ? item_data.SellingPrice : item_data.MRP, mrp: item_data.MRP, discountPrice: item_data.DiscountPrice ? item_data.DiscountPrice : 0, stock: item_data.Stock ? item_data.Stock : 0, minStock: item_data.MinStock ? item_data.MinStock : 0
              , image: item_data.ItemImageURL, image2: null, image3: null, cartQty
            };
            variantsArray.push(newlyAddedValue);
            that.setState({ variantsArray, variantImages: [{ title: "1", illustration: item_data.ItemImageURL, ref: that }] }, () => that.setCurrentVariantsByDoc(0));
          }

        } else {
          this._getVariants(id);

        }
      }
    }).catch(() => { alert('Something went wrong. Check your internet connection') });
  }

  _storeInquiry() {
    let StoreData = this.props.navigation.getParam('StoreData');
    let phoneNumber = StoreData.sharePhone ? (StoreData.loginPhone ? StoreData.loginPhone : StoreData.phone ? (StoreData.countrycode ? StoreData.countrycode : '') + '' + StoreData.phone : null) : null;
    Linking.openURL('whatsapp://send?phone=' + phoneNumber + '&text=' + ('I need some information about the product ' + this.state.ItemCode + ' ' + this.state.itemName).replace("'", "") + ' listed in Store My Store');

  }

  _shareItem() {
    this.setState({ Sharing: true }, this._shareNow);

  }

  _shareNow() {
    let that = this;
    setTimeout(async function () {

      //Put All Your Code Here, Which You Want To Execute After Some Delay Time.
      //   let result = await takeSnapshotAsync(that.screenShot, {
      //     format: 'jpg',
      //     result: 'tmpfile',
      //     quality: 1
      //   });
      //   that.setState({ Sharing: false, isLoading: false }, () => Sharing.shareAsync(result));

      // }, 2000);
    })
  }

  render() {


    if (this.state.isLoading) {
      return (<CLoading gradStart={this.state.gradStart} gradEnd={this.state.gradEnd}></CLoading>);
    } else {
      if (this.state.Sharing) {
        return (
          <SafeAreaView style={{ flex: 1, backgroundColor: '#ffffff', justifyContent: 'center' }}>
            <ActivityIndicator size="large" color={this.state.gradStart} />
            <Text style={{ fontWeight: 'bold', fontSize: 30, color: this.state.gradEnd, alignSelf: 'center' }}>Loading...</Text>
            <View collapsable={false} style={{ width: width, height: width + 100 }} ref={view => this.screenShot = view}>

              <LinearGradient
                colors={[this.state.gradStart, this.state.gradEnd]}
                style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
                start={{ x: 0, y: 0 }}
                end={{ x: 1, y: 0 }}
              >
                <View >
                  <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: (0.05 * width), color: 'rgba(255,255,255,0.8)' }}>{this.state.storeData.storeName ? this.state.storeData.storeName : 'Store My Store'}</Text>
                  <Image style={{ width: width, height: width, borderRadius: 5, borderWidth: 1, borderColor: 'white' }} source={{ uri: this.state.variantImages[0].illustration }} />
                  <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'flex-start', alignItems: 'center' }}>

                  </View>
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <View style={{ flex: 8, flexDirection: 'row' }}>
                      <View>
                        <Text style={[styles.title, { color: Colors.white, marginLeft: 10 }]} numberOfLines={1} ellipsizeMode='tail'>{this.state.itemName}</Text>
                        <Text style={[styles.subtitle], { color: Colors.white, textAlign: 'left', marginLeft: 10 }} numberOfLines={2} ellipsizeMode='tail'>{this.state.itemDescription}</Text>
                      </View>
                    </View>
                    <View style={{ flex: 2, flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <View style={{ marginRight: 5, alignItems: 'center' }}>
                        <Text style={{ marginLeft: 10, color: Colors.white, fontWeight: 'bold', fontSize: 20 }}>{this.state.discountPrice > 0 ? this.state.storeData.Currency + this.state.discountPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}{this.state.discountPrice <= 0 ? this.state.storeData.Currency + this.state.sellingPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null}</Text>
                        <Text style={[Cstyles.mrp, { marginLeft: 10 }]}>{parseFloat(this.state.mrp) > parseFloat(this.state.discountPrice) ? parseFloat(this.state.mrp) > parseFloat(this.state.sellingPrice) ? this.state.storeData.Currency + this.state.mrp : null : null}</Text>
                      </View>
                    </View>
                  </View>

                </View>
              </LinearGradient>

            </View>
          </SafeAreaView>
        );
      } else {

        //const carousel = this.renderCarousel();
        return (
          <SafeAreaView style={styles.safeArea}>
            <View style={styles.container}>
              {this.gradient}
              <KeyboardAvoidingView style={styles.container} behavior="padding" enabled>
                <ScrollView
                  style={styles.scrollview}
                  scrollEventThrottle={200}
                  directionalLockEnabled={true}
                  ref={(view) => {
                    this.scrollView = view;
                  }}
                >
                  {//carousel
                    this._renderImages()
                  }
                  <Card style={{ alignSelf: 'stretch', marginLeft: 10, marginRight: 10, padding: 10, alignItems: 'flex-start' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: screenWidth * 0.9 }}>
                      <Text style={[styles.title]} numberOfLines={2} ellipsizeMode='tail'>{this.state.itemName}</Text>
                    </View>
                    {/* <Text style={styles.subtitle} numberOfLines={2} ellipsizeMode='tail'>{this.state.itemDescription}</Text> */}
                    <ViewMoreText
                      numberOfLines={3}
                      textStyle={[styles.subtitle], { textAlign: 'left', marginLeft: 10 }}
                    >{this.state.itemDescription}</ViewMoreText>
                    <View style={{ flexDirection: 'row', alignSelf: 'stretch', marginLeft: 5 }}>
                      {this.state.ItemType == 'Food' ? this.state.FoodVegNv == 'Veg' ?
                        <Image style={{ width: 25, height: 25 }} source={require('../../assets/images/veg.png')} /> :
                        this.state.FoodVegNv == 'Non-Veg' ?
                          <Image style={{ width: 25, height: 25 }} source={require('../../assets/images/nonveg.png')} /> :
                          <Image style={{ width: 25, height: 25 }} source={require('../../assets/images/egg.png')} />
                        : null}
                      {this.state.ItemType == 'Food' && this.state.FoodSpicyLevel ?
                        this.state.FoodSpicyLevel == 2 ?
                          <View style={{ flexDirection: 'row', marginLeft: 10 }}>
                            <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                            <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                          </View> : this.state.FoodSpicyLevel == 3 ?
                            <View style={{ flexDirection: 'row', marginLeft: 10 }}>
                              <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                              <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                              <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                            </View>
                            : this.state.FoodSpicyLevel == 4 ?
                              <View style={{ flexDirection: 'row', marginLeft: 10 }}>
                                <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                                <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                                <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                                <Image style={{ width: 20, height: 20 }} source={require('../../assets/images/chilli.png')} />
                              </View> : null : null}
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                      <Text style={[Cstyles.sellingPrice, { color: this.state.gradStart }]}>{this.state.discountPrice > 0 ? this.state.storeData.Currency + this.state.discountPrice : null}{this.state.discountPrice <= 0 ? this.state.storeData.Currency + this.state.sellingPrice : null}</Text>
                      <Text style={[Cstyles.mrp, { marginLeft: 10 }]}>{parseFloat(this.state.mrp) > parseFloat(this.state.discountPrice) ? parseFloat(this.state.mrp) > parseFloat(this.state.sellingPrice) ? this.state.storeData.Currency + this.state.mrp : null : null}</Text>
                      {this.state.discountPrice > 0 ?
                        <Text style={Cstyles.off}>{this.state.mrp > this.state.discountPrice ? (((this.state.mrp - this.state.discountPrice) / this.state.mrp) * 100).toFixed(0).toString() + '% off' : null}</Text>
                        : <Text style={Cstyles.off}>{this.state.mrp > this.state.sellingPrice ? (((this.state.mrp - this.state.sellingPrice) / this.state.mrp) * 100).toFixed(0).toString() + '% off' : null}</Text>
                      }
                    </View>
                    {this.state.VarSize ? (<View style={{ marginTop: 10 }}><Text style={styles.variantTitle}>Select Size</Text><View style={{ flex: 1, paddingLeft: 4, paddingBottom: 4, flexDirection: 'row', flexWrap: 'wrap' }}>{this.renderVariant('Size')}</View></View>) : null}
                    {this.state.VarColor ? (<View style={{ marginTop: 10 }}><Text style={styles.variantTitle}>Select Color</Text><View style={{ flex: 1, paddingLeft: 4, paddingBottom: 4, flexDirection: 'row', flexWrap: 'wrap' }}>{this.renderVariant('Color')}</View></View>) : null}
                    {this.state.VarMaterial ? (<View style={{ marginTop: 10 }}><Text style={styles.variantTitle}>Select Material</Text><View style={{ flex: 1, paddingLeft: 4, paddingBottom: 4, flexDirection: 'row', flexWrap: 'wrap' }}>{this.renderVariant('Material')}</View></View>) : null}
                    {this.state.VarPacking ? (<View style={{ marginTop: 10 }}><Text style={styles.variantTitle}>Select Packing</Text><View style={{ flex: 1, paddingLeft: 4, paddingBottom: 4, flexDirection: 'row', flexWrap: 'wrap' }}>{this.renderVariant('Packing')}</View></View>) : null}
                    {this.state.VarOther ? (<View style={{ marginTop: 10 }}><Text style={styles.variantTitle}>Select Variant</Text><View style={{ flex: 1, paddingLeft: 4, paddingBottom: 4, flexDirection: 'row', flexWrap: 'wrap' }}>{this.renderVariant('Other')}</View></View>) : null}
                    {/* {(this.state.cartQty && this.state.cartQty > 0) ?
                      <View style={{ alignSelf: 'stretch' }}>
                        <Text style={{ textAlign: 'center', color: this.state.gradStart }}>{this.state.cartQty + ' quantity in cart'}</Text>
                      </View>
                      : null} */}

                    {this.state.storeData.takeOrder && this.state.storeData.serviceable ?
                      ((!this.state.storeData.OosOrders) && (this.state.Stock <= 0)) ?
                        <Text style={{ textAlign: 'center', color: 'red' }}>Out of Stock</Text> :
                        (this.props.profile_data.profile_data && this.props.profile_data.profile_data.uid ?
                          ((this.state.cartQty && this.state.cartQty > 0) ?
                            <CNumericCounter
                              size={'large'}
                              theme={'light'}
                              style={{ alignSelf: 'center', marginTop: 10 }}
                              value={this.state.cartQty}
                              onDecrease={() => this._addToCart(-1)}
                              onIncrease={() => this._addToCart(1)}
                            />
                            :

                            <LinearGradient
                              colors={[this.state.gradStart, this.state.gradEnd]}
                              start={{ x: 0, y: 0 }}
                              end={{ x: 1, y: 0 }}
                              style={styles.buttonContainer}
                            >
                              <TouchableOpacity style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} onPress={() => this._addToCart(1)}>
                                <Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}>Add to Cart</Text>
                                {this.state.addingToCart ? <ActivityIndicator size='small' color='white' style={{ marginLeft: 10 }} /> : null}
                              </TouchableOpacity>
                            </LinearGradient>) :
                          <LinearGradient
                            colors={[this.state.gradStart, this.state.gradEnd]}
                            start={{ x: 0, y: 0 }}
                            end={{ x: 1, y: 0 }}
                            style={styles.buttonContainer}
                          >
                            <TouchableOpacity style={{ flex: 1, alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }} onPress={() => this.props.navigation.navigate('Profile')}>
                              <Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}>Login to Continue</Text>
                            </TouchableOpacity>
                          </LinearGradient>
                        ) :
                      <Text style={{ textAlign: 'center', color: 'red' }}>{!this.state.storeData.takeOrder ? 'Contact the Store to place order' : 'This Store does not serve in your location'}</Text>
                    }



                  </Card>

                </ScrollView>
              </KeyboardAvoidingView>
              <PoweredBy />
            </View>



          </SafeAreaView>

        );
      }
    }
  }





  updateTextInput = (text, field) => {
    this.scrollView.scrollToEnd({ animated: true });
    const state = this.state
    state[field] = text;
    this.setState(state);
  }



  _addToCart(qty) {

    that = this;
    this.setState({ addingToCart: true, addedToCart: false });
    var batch = firebase.firestore().batch();
    var addCart = null;
    var addCart2 = null;
    var updStock = null;

    if (this.state.cartQty == 1 && qty < 0) {
      console.log('delete')
      addCart = firebase.firestore().collection('buyer').doc(this.props.profile_data.profile_data.uid).collection('stores').doc(this.state.storeData.id).collection('cart').doc(this.state.ItemId + '/variants/' + this.state.variantIndex.toString());
      batch.delete(addCart)
    } else if (this.state.cartQty > 0) {
      // .collection(this.state.ItemId).doc(this.state.variantIndex)
      //firebase.firestore().collection('mystore').doc(that.state.currentUser.uid).collection('cart').doc(that.state.ItemId).doc(that.state.variantIndex).update({qty:firebase.firestore.FieldValue.increment(1)})
      //.then(()=>{that.setState({addingToCart:false, addedToCart:true})});
      addCart = firebase.firestore().collection('buyer').doc(this.props.profile_data.profile_data.uid).collection('stores').doc(this.state.storeData.id).collection('cart').doc(this.state.ItemId + '/variants/' + this.state.variantIndex.toString());
      batch.update(addCart, { qty: firebase.firestore.FieldValue.increment(qty) });
    }
    else {
      //firebase.firestore().collection('mystore').doc(that.state.currentUser.uid).collection('cart').doc(that.state.ItemId).doc(that.state.variantIndex).set({qty:1})
      //.then(()=>{that.setState({addingToCart:false, addedToCart:true})});
      addCart = firebase.firestore().collection('buyer').doc(this.props.profile_data.profile_data.uid).collection('stores').doc(this.state.storeData.id).collection('cart').doc(this.state.ItemId + '/variants/' + this.state.variantIndex.toString());
      batch.set(addCart, { qty: qty });
      addCart2 = firebase.firestore().collection('buyer').doc(this.props.profile_data.profile_data.uid).collection('stores').doc(this.state.storeData.id).collection('cart').doc(this.state.ItemId);
      batch.set(addCart2, { Name: that.state.itemName });
    }
    if (this.state.variantIndex == 0) {
      updStock = firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').doc(that.state.ItemId);
    } else {
      updStock = firebase.firestore().collection('mystore').doc(this.state.storeData.id).collection('items').doc(that.state.ItemId).collection("variants").doc(that.state.variantIndex.toString());
    }

    batch.update(updStock, { Stock: firebase.firestore.FieldValue.increment(-1 * qty), StockInCart: firebase.firestore.FieldValue.increment(qty) });
    batch.commit().then(function () {
      let profile_data = that.props.profile_data.profile_data
      profile_data.cartCount = 1
      that.props.updateProfile(profile_data)
      that.setState({ addingToCart: false, addedToCart: true, Stock: that.state.Stock - (1 * qty), origStock: that.state.Stock - (1 * qty) });
      that.setState({ cartQty: that.state.cartQty + qty });
      let newArray = [...that.state.variantsArray];
      newArray[that.state.selectedVariant].cartQty = newArray[that.state.selectedVariant].cartQty + (1 * qty);
      that.setState({ variantsArray: newArray });

    });


  }

  get gradient() {
    return (
      <LinearGradient
        colors={[this.state.gradStart, this.state.gradEnd]}
        startPoint={{ x: 1, y: 0 }}
        endPoint={{ x: 0, y: 1 }}
        style={styles.gradient}
      />
    );
  }


  renderVariant(variant) {
    let thisVariantArray = [];
    let CurrentVar = '';
    let CurrentVarVal = '';
    if (this.state.variantsArray.length > 0) {
      switch (variant) {
        case 'Size':

          thisVariantArray = this.state.uniqueSize;
          CurrentVarVal = this.state.Size;
          break;
        case 'Color':
          thisVariantArray = this.state.uniqueColor;
          CurrentVarVal = this.state.Color;
          break;
        case 'Material':
          thisVariantArray = this.state.uniqueMaterial;
          CurrentVarVal = this.state.Material;
          break;
        case 'Packing':
          thisVariantArray = this.state.uniquePacking;
          CurrentVarVal = this.state.Packing;
          break;
        case 'Other':
          thisVariantArray = this.state.uniqueOther;
          CurrentVarVal = this.state.Other;
          break;
      }
    }

    return thisVariantArray.map((item, key) => {
      return (

        <TouchableOpacity key={key}
          style={{
            marginLeft: 10, marginTop: 5, padding: 5, borderRadius: 10,
            backgroundColor: (CurrentVarVal == item) ? this.state.gradEnd : 'grey'
          }}
          underlayColor='#fff' onPress={() => this.updateVariant(variant, item)}>
          <Text key={item.index} style={{ color: 'white' }}>{item}</Text>
        </TouchableOpacity>
      );
    });
  }

  updateVariant(type, value) {
    let otherVariant = null;
    let index = 0;
    let combiFound = false;

    if (type != 'Size' && this.state.VarSize) { otherVariant = 'Size' }
    if (type != 'Color' && this.state.VarColor) { otherVariant = 'Color' }
    if (type != 'Material' && this.state.VarMaterial) { otherVariant = 'Material' }
    if (type != 'Packing' && this.state.VarPacking) { otherVariant = 'Packing' }
    if (type != 'Other' && this.state.VarOther) { otherVariant = 'Other' }

    if (this.state[type] != value) {

      if (otherVariant) {
        this.state.variantsArray.map((item, key) => {

          if (item[type] == value && item[otherVariant] == this.state[otherVariant]) {
            this.setCurrentVariantsByDoc(index);
            this.setState({ selectedVariant: index, addedToCart: false });
            combiFound = true;
          }
          index++;
        });
        if (!combiFound) {
          index = 0;
          for (index = 0; index < this.state.variantsArray.length; index++) {
            if (this.state.variantsArray[index][type] == value) {
              this.setState({ [otherVariant]: this.state.variantsArray[index][otherVariant] });
              this.setCurrentVariantsByDoc(index);
              this.setState({ selectedVariant: index, addedToCart: false });
              break;
            }
          }


        }
      } else {
        this.state.variantsArray.map((item, key) => {

          if (item[type] == value) {
            this.setCurrentVariantsByDoc(index);
            this.setState({ selectedVariant: index });

          }
          index++;
        });



      }
    }


  }



  onFocus() {
    let { errors = {} } = this.state;

    for (let name in errors) {
      let ref = this[name];

      if (ref && ref.isFocused()) {
        delete errors[name];
      }
    }

    this.setState({ errors });
  }

  updateRef(name, ref) {
    this[name] = ref;
  }



  _maybeRenderUploadingOverlay = () => {
    if (this.state.uploading) {
      return (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: 'rgba(0,0,0,0.4)',
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}>
          <ActivityIndicator color="#fff" animating size="large" />
        </View>
      );
    }
  }



  _getVariants(id) {
    console.log('variants')
    const { route } = this.props;
    var promises = [];
    let variantsArray = [];
    that.setState({ isLoading: true, variantsArray: [] });
    firebase.firestore().collection('mystore').doc(id).collection('items').doc(this.state.ItemId).collection("variants").get().then(function (querySnapshot) {

      Promise.all(querySnapshot.docs.map(async doc => {
        // doc.data() is never undefined for query doc snapshots


        const variant_data = doc.data();
        promises.push(firebase.firestore().collection('buyer').doc(that.props.profile_data.profile_data.uid).collection('stores').doc(that.state.storeData.id).collection('cart').doc(that.state.ItemId + '/variants/' + doc.id.toString()).get()
          .then((cartVarDoc) => {
            let cartQty = 0;
            if (cartVarDoc.exists) {
              cartQty = cartVarDoc.data().qty
            }


            let newlyAddedValue = {
              index: doc.id, Size: variant_data.Size, Color: variant_data.Color, Material: variant_data.Material, Packing: variant_data.Packing, Other: variant_data.Other, costPrice: variant_data.CostPrice, sellingPrice: variant_data.SellingPrice, mrp: variant_data.MRP, discountPrice: variant_data.DiscountPrice, stock: variant_data.Stock, minStock: variant_data.MinStock
              , image: variant_data.Image, image2: variant_data.Image2, image3: variant_data.Image3, cartQty
            };
            variantsArray.push(newlyAddedValue);
            //that.setState({ variantsArray: [...that.state.variantsArray, newlyAddedValue ] });
            if (that.state.VarSize) {
              if (that.state.uniqueSize.indexOf(variant_data.Size) === -1) {
                that.setState({ uniqueSize: [...that.state.uniqueSize, variant_data.Size] });
              }
            }

            if (that.state.VarColor) {
              if (that.state.uniqueColor.indexOf(variant_data.Color) === -1) {
                that.setState({ uniqueColor: [...that.state.uniqueColor, variant_data.Color] });
              }
            }

            if (that.state.VarMaterial) {
              if (that.state.uniqueMaterial.indexOf(variant_data.Material) === -1) {
                that.setState({ uniqueMaterial: [...that.state.uniqueMaterial, variant_data.Material] });
              }
            }

            if (that.state.VarPacking) {
              if (that.state.uniquePacking.indexOf(variant_data.Packing) === -1) {
                that.setState({ uniquePacking: [...that.state.uniquePacking, variant_data.Packing] });
              }
            }

            if (that.state.VarOther) {
              if (that.state.uniqueOther.indexOf(variant_data.Other) === -1) {
                that.setState({ uniqueOther: [...that.state.uniqueOther, variant_data.Other] });
              }
            }


          }).catch(() => {
            console.log('No Cart Yet')
            let cartQty = 0;

            let newlyAddedValue = {
              index: doc.id, Size: variant_data.Size, Color: variant_data.Color, Material: variant_data.Material, Packing: variant_data.Packing, Other: variant_data.Other, costPrice: variant_data.CostPrice, sellingPrice: variant_data.SellingPrice, mrp: variant_data.MRP, discountPrice: variant_data.DiscountPrice, stock: variant_data.Stock, minStock: variant_data.MinStock
              , image: variant_data.Image, image2: variant_data.Image2, image3: variant_data.Image3, cartQty
            };
            variantsArray.push(newlyAddedValue);
            //that.setState({ variantsArray: [...that.state.variantsArray, newlyAddedValue ] });
            if (that.state.VarSize) {
              if (that.state.uniqueSize.indexOf(variant_data.Size) === -1) {
                that.setState({ uniqueSize: [...that.state.uniqueSize, variant_data.Size] });
              }
            }

            if (that.state.VarColor) {
              if (that.state.uniqueColor.indexOf(variant_data.Color) === -1) {
                that.setState({ uniqueColor: [...that.state.uniqueColor, variant_data.Color] });
              }
            }

            if (that.state.VarMaterial) {
              if (that.state.uniqueMaterial.indexOf(variant_data.Material) === -1) {
                that.setState({ uniqueMaterial: [...that.state.uniqueMaterial, variant_data.Material] });
              }
            }

            if (that.state.VarPacking) {
              if (that.state.uniquePacking.indexOf(variant_data.Packing) === -1) {
                that.setState({ uniquePacking: [...that.state.uniquePacking, variant_data.Packing] });
              }
            }

            if (that.state.VarOther) {
              if (that.state.uniqueOther.indexOf(variant_data.Other) === -1) {
                that.setState({ uniqueOther: [...that.state.uniqueOther, variant_data.Other] });
              }
            }
          }))



      })).then((results) => {

        Promise.all(promises).then(() => {

          that.setState({ variantsArray }, () => that.setCurrentVariantsByDoc(0));


          if (route.params.Size) {
            that.updateVariant('Size', route.params.Size);
          }
          if (route.params.Color) {
            that.updateVariant('Color', route.params.Color);
          }
          if (route.params.Material) {
            that.updateVariant('Material', route.params.Material);
          }
          if (route.params.Packing) {
            that.updateVariant('Packing', route.params.Packing);
          }
          if (route.params.Other) {
            that.updateVariant('Other', route.params.Other);
          }
        })
      });
    });
  }

  setCurrentVariantsByDoc(index) {
    let that = this;
    that.setState({ variantImages: [] });
    var varImagesTemp = [];
    that.setState({
      Size: that.state.variantsArray[index].Size,
      Color: that.state.variantsArray[index].Color,
      Material: that.state.variantsArray[index].Material,
      Packing: that.state.variantsArray[index].Packing,
      Other: that.state.variantsArray[index].Other,
      costPrice: that.state.variantsArray[index].costPrice,
      sellingPrice: that.state.variantsArray[index].sellingPrice,
      discountPrice: that.state.variantsArray[index].discountPrice,
      mrp: that.state.variantsArray[index].mrp,
      Stock: that.state.variantsArray[index].stock,
      minStock: that.state.variantsArray[index].minStock,
      origStock: that.state.variantsArray[index].stock,
      origminStock: that.state.variantsArray[index].minStock,
      origcostPrice: that.state.variantsArray[index].costPrice,
      origsellingPrice: that.state.variantsArray[index].sellingPrice,
      origmrp: that.state.variantsArray[index].mrp,
      origdiscountPrice: that.state.variantsArray[index].discountPrice,
      variantIndex: that.state.variantsArray[index].index,
      cartQty: that.state.variantsArray[index].cartQty,
    });
    varImagesTemp.push({ title: "1", illustration: that.state.variantsArray[index].image, ref: that });
    if (that.state.variantsArray[index].image2) {
      varImagesTemp.push({ title: "2", illustration: that.state.variantsArray[index].image2, ref: that });
    }
    if (that.state.variantsArray[index].image3) {
      varImagesTemp.push({ title: "3", illustration: that.state.variantsArray[index].image3, ref: that });
    }
    that.setState({ variantImages: varImagesTemp });



    that.setState({ isLoading: false });
  }

  _renderImages() {
    return (


      <FlatList
        style={{ marginTop: 5, marginBottom: 10 }}
        horizontal={true}
        data={this.state.variantImages}

        renderItem={({ item }) => (

          <TouchableOpacity style={{ marginLeft: 10, borderRadius: 10 }} onPress={() => null} >
            {screenWidth > screenHeight ?
              <Image style={{ height: screenHeight * 0.4, width: screenHeight * 0.4, borderRadius: 10 }} source={{ uri: item.illustration }} />
              :
              <Image style={{ height: screenWidth * 0.4, width: screenWidth * 0.4, borderRadius: 10 }} source={{ uri: item.illustration }} />
            }
          </TouchableOpacity>
        )}
        keyExtractor={item => item}
      />
    )
  }



}


const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: Colors.black
  },
  container: {
    flex: 1,
    backgroundColor: Colors.background1
  },
  gradient: {
    ...StyleSheet.absoluteFillObject
  },
  scrollview: {
    flex: 1
  },
  exampleContainer: {
    paddingVertical: 5
  },
  exampleContainerDark: {
    backgroundColor: Colors.black
  },
  exampleContainerLight: {
    backgroundColor: 'white'
  },
  title: {
    backgroundColor: 'transparent',
    color: 'black',
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Text'
  },
  sellingPrice: {
    backgroundColor: 'transparent',
    color: Colors.gradStart,
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'Text'
  },
  mrp: {
    marginLeft: 5,
    marginTop: 7,
    backgroundColor: 'transparent',
    color: 'grey',
    fontSize: 13,
    fontStyle: 'italic',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid'
  },
  variantTitle: {
    backgroundColor: 'transparent',
    color: 'black',
    fontWeight: 'bold',
    fontFamily: 'Text'
  },
  titleDark: {
    color: Colors.black
  },
  subtitle: {
    marginTop: 5,
    backgroundColor: 'transparent',
    color: 'grey',
    fontSize: 13,
    //fontStyle: 'italic',
    textAlign: 'left'
  },
  slider: {
    marginTop: 5,
    overflow: 'visible' // for custom animations
  },
  sliderContentContainer: {
    paddingVertical: 5 // for custom animation
  },
  paginationContainer: {
    paddingVertical: 5
  },
  paginationDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 8
  }, buttonContainer: {
    marginTop: 10,
    height: 45,
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    marginBottom: 20,
    width: 250,
    borderRadius: 30,
  }, qr: {
    marginTop: '10%',
    marginBottom: '20%',
    width: qrSize,
    height: qrSize,
  }, description: {
    fontSize: width * 0.05,
    marginTop: '10%',
    textAlign: 'center',
    width: '70%',
    color: 'white',
  },
  cancel: {
    fontSize: width * 0.05,
    textAlign: 'center',
    width: '70%',
    color: 'white',
    marginBottom: 25
  },
  off: {
    marginLeft: 5,
    marginTop: 7,
    backgroundColor: 'transparent',
    color: 'green',
    fontSize: 13,
    fontStyle: 'italic',
  },
  iconContainer: {
    flexDirection: "row",
    justifyContent: 'flex-end',
    width: 120
  }
});

const mapStateToProps = (state) => {
  const { store, profile_data } = state
  return { store, profile_data }
};
const mapDispatchToProps = dispatch => (
  bindActionCreators({
    updateStore, updateProfile
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ItemDetails);