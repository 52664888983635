import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { LinearGradient } from 'expo-linear-gradient';
import firebase from 'firebase';
import 'firebase/firestore';
import React from 'react';
import { Alert, Animated, Dimensions, Image, Linking, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
//import { WebView } from 'react-native-webview';
import { connect } from 'react-redux';
import Colors from '../constants/Colors';
import { getCashFreeCheckoutHtml, getRazorPayCheckoutHtml, getRazorPayCheckoutHtmlWeb, getStripeCheckoutHtml } from '../helper';
//import { CLoading } from '../components/StyledText';
import CheckoutForm from './CheckoutForm';
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

let containerCount = 0;


class Payment extends React.Component {

  constructor(args) {
    super(args);

    this.unsubscribe = null;
    this.stripePromise = null
    this.clientSecret = null


    let { width } = Dimensions.get("window");

    //Since component should always render once data has changed, make data provider part of the state
    this.state = {

      isLoading: true,
      loadingMessage: 'Loading..',
      scrWidth: ((width * 0.90) / 2),
      width,
      currency: '',
      showPaySuccess: false,
      PaymentWebView: false,
      webViewHtml: 'Loading...',
      PlacedAt: '0000'
    };


  }





  state = {
    currentUser: null,
    NewCategoryDescription: '',
    NewCategory: '',
    image: null,
    name: "Update Profile",
    itemsList: [1, 2],
    categoryName: '',
    categoryId: '',
    scrWidth: 100,
    animatedValue: new Animated.Value(0),
  };
  animationValue = this.state.animatedValue.interpolate(
    {
      inputRange: [0, 1],
      outputRange: [-59, 0]
    });

  _close() {
    this.props.navigation.goBack(null);
  }


  async getPaymentDetails(storeId) {
    console.warn('getPayments')
    firebase.firestore().collection('mystore').doc(storeId).collection('PayMethods').doc('PAY').get()
      .then(async (doc) => {
        if (doc.exists) {
          this.setState({
            UPI: doc.data().UPI, UPI_REF: doc.data().UPI_REF, paymentMethods: true, RazorPayIndia: doc.data().RP_Acc ? doc.data().RazorPayIndia : false, RP_Acc: doc.data().RP_Acc, cod: doc.data().cod ? doc.data().cod : (!doc.data().RazorPayIndia && !doc.data().UPI ? true : false), isLoading: false, CashFreeIndia: doc.data().CF_Vnd ? doc.data().CashFreeIndia : false, CF_Vnd: doc.data().CF_Vnd,
            Stripe: doc.data().StripeAcc ? doc.data().StripeAcc : false, StripeAcc: doc.data().StripeAcc
          });
          console.log(doc.data().StripeAcc)
          if (doc.data().StripeAcc) {
            //pk_test_51H4nXcDSLeUP7clyvUigjW4pZt0heoOTVVz8QUSpb7RJmpGQaBrQaeNHgQrZUE1ClTT7s2OVCvURXzAAuPCzvHgI00J3BHZW4c
            this.stripePromise = await loadStripe('pk_live_51H4nXcDSLeUP7clyaGd1w6guVxCLio5EBtVsIAAEhgtH1qIHAIC7RGbqbiNJ0DnuunyAf8k2GaDyEPtE7VYGXvYL00QYqWqct0', {
              stripeAccount: doc.data().StripeAcc
            });
            console.log('Pro')
            console.log(this.stripePromise)
          }

        } else {
          this.setState({ cod: true, isLoading: false })
        }
      })
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.onGetMessageFromiFrame)
  }
  async componentDidMount() {
    console.log('Payment');
    const { route } = this.props;
    let Self = this; //To avoid scope and cache

    window.removeEventListener("message", this.onGetMessageFromiFrame)

    let storeData = this.props.store.store;
    let profile_data = this.props.profile_data.profile_data;
    this.props.navigation.setOptions({
      headerStyle: {
        backgroundColor: storeData.gradStart, //Set Header color
      },
      headerTintColor: 'white'
    })
    let { payAmount, currency, orderId, s } = route.params;
    if (!orderId) {
      orderId = '0000'
    }

    this.getPaymentDetails(storeData.id)
    this.setState({
      storeData: storeData, gradStart: storeData.gradStart ? storeData.gradStart : this.state.gradStart, gradEnd: storeData.gradEnd ? storeData.gradEnd : this.state.gradEnd,
      profile_data, payAmount, currency, orderId
    });


  }



  render() {
    const { route } = this.props;
    if (this.state.isLoading) {

      return (<View style={{ flex: 1, backgroundColor: '#ffffff', justifyContent: 'center' }}>
        <ActivityIndicator />
        <Text style={{ textAlign: 'center', fontSize: 18, marginHorizontal: 5 }}>{this.state.loadingMessage}</Text>
      </View>);
    } else if (this.state.PaymentWebView) {
      return (<View style={{ flex: 1, backgroundColor: '#ffffff', justifyContent: 'center' }}>
        {/* <WebView
          ref={r => (this.webviewRef = r)}
          source={{ html: this.state.webViewHtml }}
          onMessage={this.onGetMessageFromWebView}
        /> */}
        {Platform.OS == 'web' ? this.state.gateway == 'CashFree' ?

          <div dangerouslySetInnerHTML={{ __html: this.state.webViewHtml }} />
          : this.state.gateway == 'Stripe' ?
            <Elements stripe={this.stripePromise}>
              <CheckoutForm clientSecret={this.clientSecret} onSuccess={this._onStripeSuccess} context={this} amount={this.state.storeData.Currency + this.state.payAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} />
            </Elements>

            : <iframe srcDoc={this.state.webViewHtml} width={screenWidth} height={screenHeight - 40}>
            </iframe>
          : null}

      </View>)
    } else if (this.state.showPaySuccess) {
      return (<View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff' }}>
        <Image source={require('../assets/images/completed.gif')} style={{ width: 150, height: 150, alignSelf: "center" }} />
        {route.params.source != 'OrderDetails' ?
          <View>
            <Text style={{ color: this.state.gradEnd, fontSize: 30, marginLeft: 10, marginRight: 10, textAlign: 'center' }}>Order Placed!</Text>
            <Text>You can track your order from the Orders tab below</Text>
          </View> :
          <View>
            <Text style={{ color: this.state.gradEnd, fontSize: 30, marginLeft: 10, marginRight: 10, textAlign: 'center' }}>Payment Successful!</Text>
            <Text>Redirecting to your order...</Text>
          </View>
        }
      </View>)
    }
    else {
      return (<ScrollView style={{ flex: 1 }}>
        <View>
          {this._renderPay()
          }
        </View>
      </ScrollView>)
    }
  }



  _onStripeSuccess(context, data) {
    console.log('_onStripeSuccess')
    context.setState({ PaymentWebView: false, isLoading: true, loadingMessage: 'Almost there...' });
    context._handlePaymentSuccess(data.payment_method, 'Stripe')

  }



  _renderPay() {
    const { route } = this.props;

    return (<View style={{ alignSelf: 'center', margin: 20, maxWidth:300 }}>
      <Text style={{ alignSelf: 'center', fontSize: 25, marginBottom: 20, fontWeight: 'bold' }}>Select Pay Option</Text>

      {this.state.Stripe || this.state.RazorPayIndia || this.state.CashFreeIndia || (this.state.UPI && this.state.UPI_REF) || (this.state.cod && route.params.source != 'OrderDetails') ? null :
        <Text style={{ textAlign: 'center' }}>No Payment options available for this Store. Contact Store for more information</Text>
      }
      {this.state.RazorPayIndia ? <LinearGradient
        colors={['#1e88e5', '#1565c0']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={[styles.buttonContainer2]}
      >
        <TouchableOpacity style={{ height: 40, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, alignSelf: 'stretch' }} onPress={() => this._makePayment('RazorPay')}>
          <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Pay with Cards/Netbanking</Text>
        </TouchableOpacity></LinearGradient> : null}

      {this.state.CashFreeIndia ? <LinearGradient
        colors={['#1e88e5', '#1565c0']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={[styles.buttonContainer2, { marginTop: 10 }]}
      >
        <TouchableOpacity style={{ height: 40, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, alignSelf: 'stretch' }} onPress={() => this._makePayment('CashFree')}>
          <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Pay with Cards/Netbanking</Text>
        </TouchableOpacity></LinearGradient> : null}

      {this.state.Stripe ? <LinearGradient
        colors={['#1e88e5', '#1565c0']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={[styles.buttonContainer2, { marginTop: 10 }]}
      >
        <TouchableOpacity style={{ height: 40, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, alignSelf: 'stretch' }} onPress={() => this._makePayment('Stripe')}>
          <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Pay with Cards/Netbanking (Stripe)</Text>
        </TouchableOpacity></LinearGradient> : null}

      {this.state.UPI && this.state.UPI_REF ? <LinearGradient
        colors={['#1e88e5', '#1565c0']}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={[styles.buttonContainer2, { marginTop: 10 }]}
      >
        <TouchableOpacity style={{ height: 40, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, alignSelf: 'stretch' }} onPress={() => this._makePayment('UPI')

        }>
          <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Pay with UPI</Text>
        </TouchableOpacity></LinearGradient>
        : null}
      {this.state.cod && route.params.source != 'OrderDetails' ? <LinearGradient
        colors={['#8bc34a', '#aed581']}
        style={{ flex: 1 }}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={[styles.buttonContainer2, { marginTop: 10 }]}
      >
        <TouchableOpacity style={{ height: 40, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, alignSelf: 'stretch' }} onPress={async () => {
          this._makePayment('CoD')
        }}>
          <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>Cash on Delivery</Text>
        </TouchableOpacity>
      </LinearGradient> : null}
      <LinearGradient
        colors={['grey', 'grey']}
        style={{ flex: 1 }}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={[styles.buttonContainer2, { marginTop: 20 }]}
      >
        <TouchableOpacity style={{ height: 40, alignItems: 'center', flexDirection: 'row', justifyContent: 'center', paddingLeft: 20, paddingRight: 20, alignSelf: 'stretch' }} onPress={() => this.props.navigation.goBack()}>
          <Text style={{ color: 'white', fontSize: 15, fontWeight: 'bold' }}>Go Back</Text>
        </TouchableOpacity>

      </LinearGradient>
    </View>

    )
  }

  async _stripePayment() {
    console.warn('stripe')
    this.setState({ isLoading: true, loadingMessage: 'Redirecting to Payment Gateway', gateway: 'Stripe' })
    let payReq = {
      amount: (parseFloat(this.state.payAmount) * 100),
      currency: this.state.storeData.CurrencyISO,
      storeUid: this.state.storeData.id,
      custUid: this.state.profile_data.uid ? this.state.profile_data.uid : '0',
      storeName: this.state.storeData.storeName,
    }
    // firebase.firestore().collection('stripePayment').add(payReq).then((docRef) => {
    //   console.warn(docRef.id)
    //   this.stripeUnsubscribe = firebase.firestore().collection('stripePayment').doc(docRef.id).onSnapshot(this.onStripePaymentUpdate);
    // })

    let response = await fetch("https://us-central1-vyapar-myhomestore.cloudfunctions.net/stripePaymentApi", {
      body: JSON.stringify({
        amount: (parseFloat(this.state.payAmount) * 100),
        currency: this.state.storeData.CurrencyISO,
        storeUid: this.state.storeData.id,
        custUid: this.state.profile_data.uid ? this.state.profile_data.uid : '0',
        storeName: this.state.storeData.storeName,
        sellerAccount: this.state.StripeAcc
      }),
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"

    }).catch((e) => {
      console.log('Error')
      console.log(e)
      this.setState({ isLoading: false })
      alert('Oops! Something went wrong')
    });
    let json_res = await response.json()
    console.log(json_res)
    this.clientSecret = json_res.client_secret

    let webViewHtml = getStripeCheckoutHtml(json_res.id, 'pk_test_51H4nXcDSLeUP7clyvUigjW4pZt0heoOTVVz8QUSpb7RJmpGQaBrQaeNHgQrZUE1ClTT7s2OVCvURXzAAuPCzvHgI00J3BHZW4c', this.state.StripeAcc)
    console.log(webViewHtml)
    // Monitor the message event
    //var newWindow = window.open(json_res.url)
    //this._addListener()
    this.setState({
      webViewHtml,
      PaymentWebView: true,
      isLoading: false, loadingMessage: 'Loading...'
    })
  }

  async _razorPayPayment() {
    console.warn('Razor Pay')
    this.setState({ isLoading: true, loadingMessage: 'Redirecting to Payment Gateway', gateway: 'RazorPay' })
    let payReq = {
      amount: (parseFloat(this.state.payAmount) * 100),
      currency: this.state.storeData.CurrencyISO,
      storeUid: this.state.storeData.id,
      custUid: this.state.profile_data.uid ? this.state.profile_data.uid : '0',
      account: this.state.RP_Acc
    };
    setTimeout(() => {
      //Go back after 5 seconds
      this.setState({ loadingMessage: 'This is taking longer than usual. May take upto 2 mins occasionally but be sure this is still working...' })
    }, 10000);
    // firebase.firestore().collection('razorPayPayment').add(payReq).then((docRef) => {
    //   console.warn(docRef.id)
    //   this.razorPayUnsubscribe = firebase.firestore().collection('razorPayPayment').doc(docRef.id).onSnapshot(this.onRazorPayPaymentUpdate);
    // })
    let response = await fetch("https://us-central1-vyapar-myhomestore.cloudfunctions.net/razorPayPaymentApi", {
      body: JSON.stringify({
        amount: (parseFloat(this.state.payAmount) * 100),
        currency: this.state.storeData.CurrencyISO,
        storeUid: this.state.storeData.id,
        custUid: this.state.profile_data.uid ? this.state.profile_data.uid : '0',
        account: this.state.RP_Acc
      }),
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST"

    }).catch((e) => {
      console.log('Error')
      console.log(e)
      this.setState({ isLoading: false })
      alert('Oops! Something went wrong')
    });
    let json_res = await response.json()
    console.log(json_res)


    let custInfo = {
      email: this.state.profile_data.loginMail ? this.state.profile_data.loginMail : (this.state.profile_data.mail ? this.state.profile_data.mail : ""),
      phone: this.state.profile_data.loginPhone ? this.state.profile_data.loginPhone : '',
      name: (this.state.profile_data.name && this.state.profile_data.name) != 'Guest User' ? this.state.profile_data.name : ""
    }

    let webViewHtml = '';
    if (Platform.OS == 'web') {
      webViewHtml = getRazorPayCheckoutHtmlWeb(this.state.storeData, json_res.id, custInfo, null)
    } else {
      webViewHtml = getRazorPayCheckoutHtml(this.state.storeData, json_res.id, custInfo, null)
    }
    // Monitor the message event
    this._addListener()
    this.setState({ webViewHtml, PaymentWebView: true, isLoading: false, loadingMessage: 'Loading...' })
  }


  onRazorPayPaymentUpdate = (async (querySnapshot) => {

    if (querySnapshot.data().status == 'Order') {
      let custInfo = {
        email: this.state.profile_data.loginMail ? this.state.profile_data.loginMail : (this.state.profile_data.mail ? this.state.profile_data.mail : ""),
        phone: this.state.profile_data.loginPhone ? this.state.profile_data.loginPhone : '',
        name: (this.state.profile_data.name && this.state.profile_data.name) != 'Guest User' ? this.state.profile_data.name : ""
      }
      this.razorPayUnsubscribe ? this.razorPayUnsubscribe : null;
      let webViewHtml = '';
      if (Platform.OS == 'web') {
        webViewHtml = getRazorPayCheckoutHtmlWeb(this.state.storeData, querySnapshot.data().orderId, custInfo, querySnapshot.id)
      } else {
        webViewHtml = getRazorPayCheckoutHtml(this.state.storeData, querySnapshot.data().orderId, custInfo, querySnapshot.id)
      }
      // Monitor the message event
      this._addListener()
      this.setState({ webViewHtml, PaymentWebView: true, isLoading: false, loadingMessage: 'Loading...' })
    }
  })

  _cashFreePayment() {
    console.warn('Cash Free')
    this.setState({ isLoading: true, loadingMessage: 'Redirecting to Payment Gateway', gateway: 'CashFree' })
    let payReq = {
      amount: (parseFloat(this.state.payAmount)).toString(),
      currency: this.state.storeData.CurrencyISO,
      storeUid: this.state.storeData.id,
      custUid: this.state.profile_data.uid ? this.state.profile_data.uid : '0',
      account: '',
      customerName: (this.state.profile_data.name) != 'Guest User' ? this.state.profile_data.name : "",
      customerPhone: this.state.profile_data.loginPhone ? this.state.profile_data.loginPhone : '',
      customerEmail: this.state.profile_data.loginMail ? this.state.profile_data.loginMail : (this.state.profile_data.mail ? this.state.profile_data.mail : ""),
      vendorId: '1'

    };
    setTimeout(() => {
      //Go back after 5 seconds
      this.setState({ loadingMessage: 'This is taking longer than usual. May take upto 2 mins occasionally but be sure this is still working...' })
    }, 10000);
    firebase.firestore().collection('cashFreePayment').add(payReq).then((docRef) => {
      console.warn(docRef.id)
      this.cashFreeUnsubscribe = firebase.firestore().collection('cashFreePayment').doc(docRef.id).onSnapshot(this.onCashFreePaymentUpdate);
    })
  }

  onCashFreePaymentUpdate = (async (querySnapshot) => {
    if (querySnapshot.data().status == 'Order') {
      let payReq = {
        amount: (parseFloat(this.state.payAmount)),
        currency: this.state.storeData.CurrencyISO,
        storeUid: this.state.storeData.id,
        custUid: this.state.profile_data.uid ? this.state.profile_data.uid : '0',
        account: '',
        customerName: (this.state.profile_data.name) != 'Guest User' ? this.state.profile_data.name : "",
        customerPhone: this.state.profile_data.loginPhone ? this.state.profile_data.loginPhone : '',
        customerEmail: this.state.profile_data.loginMail ? this.state.profile_data.loginMail : (this.state.profile_data.mail ? this.state.profile_data.mail : ""),

      };
      //this.cashFreeUnsubscribe ? this.cashFreeUnsubscribe : null;
      let webViewHtml = '';
      webViewHtml = getCashFreeCheckoutHtml(this.state.storeData, querySnapshot.data().signature, payReq, querySnapshot.id, querySnapshot.data().paymentSplits)
      var newWindow = window.open();
      setTimeout(() => {
        //Go back after 2 seconds
        if (newWindow) {
          newWindow.document.write(webViewHtml);
          newWindow.document.getElementById("redirectForm").submit()
        } else {
          alert('Something went wrong. Please allow popup and try again')
        }
      }, 2000);

      //newWindow.addEventListener("message", this.onGetMessageFromiFrame, false);
      // this.setState({ webViewHtml, PaymentWebView: true, isLoading: false, loadingMessage: 'Loading...' }, () => {

      // })
      this.setState({ isLoading: true, loadingMessage: 'Awaiting payment response...' })
      setTimeout(() => {
        //Go back after 5 seconds
        this.setState({ loadingMessage: 'Awaiting payment response...' })
      }, 10000);
    } else if (querySnapshot.data().status == 'Response') {
      console.log(querySnapshot.data())
      if (querySnapshot.data().txStatus == 'SUCCESS') {
        this._handlePaymentSuccess(querySnapshot.data().referenceId, 'CashFree')
      } else {
        this.PaymentFailureAlert(querySnapshot.data().txMsg)
      }
    }
  })

  onStripePaymentUpdate = (async (querySnapshot) => {
    console.log(querySnapshot.data().status)
    if (querySnapshot.data().status == 'Session') {
      this.stripeUnsubscribe ? this.stripeUnsubscribe : null;
      let webViewHtml = getStripeCheckoutHtml(querySnapshot.data().cSessionId, 'pk_test_51H4nXcDSLeUP7clyvUigjW4pZt0heoOTVVz8QUSpb7RJmpGQaBrQaeNHgQrZUE1ClTT7s2OVCvURXzAAuPCzvHgI00J3BHZW4c')
      // Monitor the message event
      this._addListener()
      this.setState({ webViewHtml, PaymentWebView: true, isLoading: false, loadingMessage: 'Loading...' })
    }
  })

  onGetMessageFromWebView = async event => {

    this.setState({ PaymentWebView: false, isLoading: true, loadingMessage: 'Almost there...' });
    console.warn(event.nativeEvent.title)
    console.log(event.nativeEvent)
    let response = null;
    if (event.nativeEvent.data && event.nativeEvent.data != 'undefined') {
      response = JSON.parse(event.nativeEvent.data)
    }

    if (response && response.cPayStatus == 'Success') {
      console.warn('Pay Ref: ' + response.cPayRef)
      this._handlePaymentSuccess(response.cPayRef, response.cPaySource)
    } else {
      this.PaymentFailureAlert('Failed')
      //this.props.navigation.state.params.onPaymentComplete('Failed', cPaySource)
    }
    //this.setState({ PaymentRef: data.razorpay_payment_id, PymntMthd: 'Online', PymntStatus: true }, () => this._placeOrder())

  }

  _addListener() {
    window.removeEventListener("message", this.onGetMessageFromiFrame)
    window.addEventListener("message", this.onGetMessageFromiFrame, false);
  }

  onGetMessageFromiFrame = async event => {
    console.log('Message received from iFrame')
    console.log(event.data)
    this.setState({ PaymentWebView: false, isLoading: true, loadingMessage: 'Almost there...' });

    let response = event.data;
    window.removeEventListener("message", this.onGetMessageFromiFrame)

    switch (this.state.gateway) {
      case 'RazorPay':
        if (response && response.razorpay_signature) {
          console.warn('Pay Ref: ' + response.razorpay_payment_id)
          this._handlePaymentSuccess(response.razorpay_payment_id, 'RazorPay')
        } else {
          this.PaymentFailureAlert(response.error.description)
        }
        break;
      case 'CashFree':
        console.log(response)
        if (response && response.txStatus == 'SUCCESS') {
          console.warn('Pay Ref: ' + response.referenceId)
          this._handlePaymentSuccess(response.referenceId, 'CashFree')
        } else {
          this.PaymentFailureAlert(response.txMsg)
        }
        break;
    }



  }

  async _handlePaymentSuccess(payRef, paySource) {
    const { route } = this.props;
    let payStatus = true;
    let loadingMessage = 'Placing your order...';
    if (paySource == 'COD' || paySource == 'UPI') {
      payStatus = false
    }

    if (paySource == 'UPI' && route.params.source == 'OrderDetails') {
      loadingMessage = 'Redirecting to payment...'
    }
    if (route.params.source == 'OrderDetails') {
      loadingMessage = 'Payment Successful. Redirecting to the order'
    }

    this.setState({ isLoading: true, loadingMessage })
    route.params.onPaymentComplete(payRef, paySource, payStatus).then((orderStatus) => {
      if (paySource == 'UPI' && route.params.source == 'OrderDetails') {
        //do nothing as order is already placed and we do not know the status of the payment with UPI
      } else {
        this.setState({ isLoading: false, showPaySuccess: true })
      }


      if (!orderStatus) {
        alert('Oops. Something went wrong while placing your order but your payment was successful. Please try placing order as cash on delivery and reach out to the store for any further support')
      } else {
        if (paySource == 'UPI') {
          //If UPI, transfer to UPI apps after placing the order
          this.upiWithoutResponse(orderStatus)
        }
      }
      setTimeout(() => {
        //Go back after 5 seconds
        this.props.navigation.goBack();
      }, 5000);
    })
  }


  _razorPayPayment_bkp() {
    {
      var options = {
        description: 'Payment for your Order on Store My Store',
        image: this.state.storeData.image ? this.state.storeData.image : 'https://firebasestorage.googleapis.com/v0/b/vyapar-myhomestore.appspot.com/o/icon.png?alt=media&token=760ef738-bd12-46c6-81b0-70f542170d1f',
        currency: 'INR',
        key: 'rzp_live_DHQz26TV9w75gT', // Live Key
        //key: 'rzp_test_Rt6uQt2BqXpGX4', // Test Key
        amount: this.state.totalPayableWithDelivery * 100,
        //amount:100,
        name: this.state.storeData.storeName,
        notes: {
          storeId: this.state.storeData.id
        },
        prefill: {
          email: this.state.profile_data.loginMail ? this.state.profile_data.loginMail : (this.state.profile_data.mail ? this.state.profile_data.mail : ""),
          contact: this.state.profile_data.loginPhone ? this.state.profile_data.loginPhone : '',
          name: this.state.profile_data.name && this.state.profile_data.name != 'Guest User' ? this.state.profile_data.name : ""
        },
        theme: { color: this.state.gradStart }
      }
      RazorpayCheckout.open(options).then((data) => {
        // handle success
        this.setState({ PaymentRef: data.razorpay_payment_id, PymntMthd: 'Online', PymntStatus: true }, () => this._placeOrder())
        //alert(`Success: ${data.razorpay_payment_id}`);
      }).catch((error) => {
        // handle failure
        this.PaymentFailureAlert();
      });
    }
  }





  get gradient() {
    return (
      <LinearGradient
        colors={[this.state.gradStart, this.state.gradEnd]}
        startPoint={{ x: 1, y: 0 }}
        endPoint={{ x: 0, y: 1 }}
        style={styles.gradient}
      />
    );
  }


  _makePayment(method) {

    switch (method) {
      case 'UPI':
        //this.openLink();
        //As Expo does not support status callback, order is placed and the payment is processed
        //Payment status has to be captured manually by the store
        if (Platform.OS == 'web') {
          if (confirm('Please be noted that the payment status will remain pending and will be marked as paid only after the Store verifies and confirms. Do you like to proceed?')) {
            this._handlePaymentSuccess('', 'UPI')
          } else {
            // Do nothing!
          }
        }
        else {
          Alert.alert(
            'UPI Payment',
            "The order will be placed and you will be redirected to UPI apps. Please be noted that the payment status will remain pending and will be marked as paid only after the Store verifies and confirms",
            [
              {
                text: 'Continue', onPress: () => {
                  this._handlePaymentSuccess('', 'UPI')
                }, style: 'cancel'
              },
              { text: 'Not now', onPress: () => null },
            ]
          );
        }

        //this.upiWithoutResponse()
        break;
      case 'RazorPay':
        this._razorPayPayment()
        break;
      case 'CashFree':
        this._cashFreePayment()
        break;
      case 'Stripe':
        this._stripePayment()
        break;
      case 'CoD':
        this._handlePaymentSuccess('', 'COD')
        break;
      default:

    }
  }


  openLink = async () => {
    let { amount } = this.state;
    let transactionRef = this.props.profile_data.profile_data.uid.toString().slice(-4) + '_' + Date.now()
    this.setState({ transactionRef })
    //let UpiUrl = "upi://pay?pa=someName@okicici&pn=dhaval&tr=kdahskjahs27575fsdfasdas&am="+amount+"&mam=null&cu=INR&url=https://MyUPIApp&refUrl=https://MyUPIApp";
    let UpiUrl = 'upi://pay?pa=' + (this.state.UPI_REF) + ("&pn=" + (this.state.storeData.storeName ? 'StoreMyStore' : 'StoreMyStore')) + ("&tr=" + transactionRef) + ("&tn=" + this.state.profile_data.name + '|' + this.state.PlacedAt.toString().slice(-4)) + "&am=" + this.state.totalPayableWithDelivery;//+ "&mc=5812&aid=uGICAgICLztbnPw&mode=02&orgid=00000";
    //UpiUrl='upi://pay?pa=9600169495@okbizaxis&am=0&pn=GoodwillEnterprises&mc=5812&aid=uGICAgICLztbnPw'+ ("&tid=0001" ) + ("&tr=" + this.state.PlacedAt.toString().slice(-4)) + ("&tn=" + 'Nandha' + '|' + this.state.PlacedAt.toString().slice(-4))
    console.warn(UpiUrl)
    let response = await UPI.openLink(UpiUrl.replace(' ', '%20')).catch((e) => {
      console.warn('Cancelled')
      this.PaymentFailureAlert('Failed')
    });
    let responseList = [];

    if (response) {
      console.warn(response);
      responseList = response.split("&");
      if (responseList[2].split("=")[1] == "SUCCESS" || responseList[3].split("=")[1] == "SUCCESS" || String(response).includes('Status=SUCCESS')) {
        console.warn('Success')
        let ApprovalCode = null;
        if (responseList[4] && responseList[4].split("=")[0] == 'ApprovalRefNo') {
          ApprovalCode = responseList[4].split("=")[1]
        } else {
          ApprovalCode = responseList[0].split("=")[1]
        }
        this.setState({ PaymentRef: ApprovalCode, PymntMthd: 'UPI', PymntStatus: true }, () => this._placeOrder())
      } else {
        console.warn('Failure')
        this.PaymentFailureAlert('Failed')
      }
    }
  }
  upiWithoutResponse = async (orderId) => {
    console.warn(this.state.UPI_REF)
    let UpiUrl = 'upi://pay?pa=' + (this.state.UPI_REF) + ("&pn=" + (this.state.storeData.storeName ? this.state.storeData.storeName : 'StoreMyStore')) + ("&tid=" + orderId) + ("&tr=" + orderId) + ("&tn=" + this.state.profile_data.name + '|' + orderId) + "&am=" + this.state.payAmount + "&cu=INR";
    Linking.openURL(UpiUrl);
  }

  PaymentFailureAlert(errorMessage) {
    this.setState({ isLoading: false, loadingMessage: 'Loading...' })
    if (Platform.OS == 'web') {
      alert(
        'Payment Failed! ' + errorMessage + ". Something went wrong and your payment failed. Please try again later. If the amount is debited from your account, please reach out to the Store for a refund");
    } else {
      Alert.alert(
        'Payment Failed',
        errorMessage + ". Something went wrong and your payment failed. Please try again later. If the amount is debited from your account, please reach out to the Store for a refund", [

        { text: 'Got It', onPress: () => null },
      ]
      );
    }

  }
}

const styles = {
  container: {
    alignItems: "stretch",
    flex: 1,

  }, sellingPrice: {
    backgroundColor: 'transparent',
    color: Colors.gradStart,
    fontSize: 25,
    fontWeight: 'bold'
  },
  mrp: {
    marginLeft: 5,
    marginTop: 7,
    backgroundColor: 'transparent',
    color: 'grey',
    fontSize: 13,
    fontStyle: 'italic',
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid'
  },
  off: {
    marginLeft: 5,
    marginTop: 7,
    backgroundColor: 'transparent',
    color: 'green',
    fontSize: 13,
    fontStyle: 'italic',
  },
  costPrice: {
    marginLeft: 5,
    marginTop: 7,
    backgroundColor: 'transparent',
    color: 'grey',
    fontSize: 13,
  }, gradient: {
    ...StyleSheet.absoluteFillObject
  },
  buttonContainer2: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },
  buttonContainer: {
    height: 45,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
    width: 300,
    borderRadius: 30,
    backgroundColor: 'transparent'
  }
};


const mapStateToProps = (state) => {
  const { store, profile_data } = state
  return { store, profile_data }
};

export default connect(mapStateToProps)(Payment);