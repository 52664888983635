import { STORE_UID } from '../constants';
const initialState = {
    store: { name: 'xxx' }
};
const storeReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_UID:
            return {
                ...state,
                store: action.payload
            };
        default:
            return state;
    }
}
export default storeReducer;