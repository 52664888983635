
import React from 'react';
import { CardElement, PaymentElement } from '@stripe/react-stripe-js';
import { Alert, Animated, Dimensions, Image, Linking, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
const { width: viewportWidth, height: viewportHeight } = Dimensions.get('window');
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "20px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};
function CardSection() {
    return (
        <View style={{ padding: 10, maxWidth: 500, width: viewportWidth, alignSelf: 'center' }}>
            <Text style={{ textAlign: 'center', fontSize: 18 }}>
                Enter Card details
            </Text>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <Text style={{ textAlign: 'center', color: 'grey', marginVertical: 10 }}>We do not store your Card information</Text>
        </View>
    );
};
export default CardSection;