import { STORE_UID, PROFILE_DATA } from '../constants';
export function updateStore(UID) {
    
    return {
        type: STORE_UID,
        payload: UID
    }
}

export function updateProfile(UID) {
    return {
        type: PROFILE_DATA,
        payload: UID
    }
}