const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export default {
  gradStart: '#ff6c50',
  gradEnd:'#ff3791',
  grey:'grey',
  green:'#8BC34A',
  red:'#f44336',
  orange:'#FF9800',
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
};
